import { AiTwotoneTool } from "react-icons/ai"

export function UnderConstruction() {
  return (
    <div
      className="w-full rounded-md shadow-lg flex items-center justify-center bg-white"
      style={{
        height: "400px"
      }}
    >
      <div
        className="space-y-5"
      >
        <div
          className="font-bold uppercase"
          style={{
            fontSize: "30px"
          }}
        >
          Under construction
        </div>
        <div className="flex justify-center">
          <AiTwotoneTool
            className="h-20 w-20" 
          />
        </div>
      </div>
    </div>
  )
}

export default function Dashboard() {
  return (
    <UnderConstruction />
  )
}