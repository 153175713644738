import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { handleResponse200 } from "../../../utils/HttpUtils";
import { notifyError, notifySuccess } from "../../../utils/Notification";
import { createInvoice, getCicilanTransaction, getDetail, getPaymentChannel } from "../Apis/Api";
import { RiAddLine, RiLoader5Fill } from "react-icons/ri";
import { BsChevronLeft } from "react-icons/bs"
import CicilanPage from "./CicilanPage"
import CreateInvoiceDialog from "./CreateInvoiceDialog";
import { useDispatch } from "react-redux";
import { setIsHomeProcess } from "../../../app/GlobalSlice";

const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
    { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}

const TransactionItemBox = ({
  idTrx,
  createdAt,
  expiredAt,
  paidAt,
  paymentChannel,
  paymentMethod,
  paymentStatus,
  biayaPg,
  totalAmount
}) => {

  return (
    <div
      className="p-2 rounded-lg border border-gray-300 space-y-2"
    >
      <div className="space-y-1">
        <div className="text-left">Id Transaksi</div>
        <div className="flex">
          <input 
            className="p-1 px-2 rounded-md bg-gray-200 w-full"
            value={idTrx}
          />
        </div>
      </div>
      <div className="space-y-1">
        <div className="text-left">Tgl Dibuat</div>
        <div className="flex">
          <input 
            className="p-1 px-2 rounded-md bg-gray-200 w-full"
            value={createdAt}
          />
        </div>
      </div>
      <div className="space-y-1">
        <div className="text-left">Tgl Exp</div>
        <div className="flex">
          <input 
            className="p-1 px-2 rounded-md bg-gray-200 w-full"
            value={expiredAt}
          />
        </div>
      </div>
      <div className="space-y-1">
        <div className="text-left">Tgl Dibayar</div>
        <div className="flex">
          <input 
            className="p-1 px-2 rounded-md bg-gray-200 w-full"
            value={paidAt ? paidAt : '-'}
          />
        </div>
      </div>
      <div className="space-y-1">
        <div className="text-left">Payment Channel</div>
        <div className="flex">
          <input 
            className="p-1 px-2 rounded-md bg-gray-200 w-full"
            value={paymentChannel}
          />
        </div>
      </div>
      <div className="space-y-1">
        <div className="text-left">Biaya Adm</div>
        <div className="flex">
          <input 
            className="p-1 px-2 rounded-md bg-gray-200 w-full"
            value={formatRupiah(biayaPg)}
          />
        </div>
      </div>
      <div className="space-y-1">
        <div className="text-left">Total Bayar</div>
        <div className="flex">
          <input 
            className="p-1 px-2 rounded-md bg-gray-200 w-full"
            value={formatRupiah(totalAmount)}
          />
        </div>
      </div>
      <div className="space-y-1">
        <div className="text-left">Status</div>
        <div className="flex">
          <input 
            className="p-1 px-2 rounded-md bg-gray-200 w-full"
            value={paymentStatus}
          />
        </div>
      </div>
    </div>
  )

}

const CicilanTransactionBox = ({
  transactions,
  onOpenCreateInvoiceDialog
}) => {

  if (transactions.length == 0) {
    return (
      <div
        className="flex justify-center py-2"
      >
        Tidak ada catatan transaksi
      </div>
    )
  }

  return (
    <div
      className="space-y-5"
    >
      <div className="flex items-center space-x-3 font-bold pl-2">
        <label>Transaksi</label>
        <div>
          <button
            className="p-1 rounded-md bg-blue-500 text-white flex items-center space-x-2 pl-2 pr-3"
            onClick={onOpenCreateInvoiceDialog}
          >
            <RiAddLine className="h-5 w-5" />
            <label>Invoice</label>
          </button>
        </div>
      </div>
      <div
        className="grid grid-cols-3 gap-3"
      >
        {transactions.map(trx => (
          <TransactionItemBox 
            key={trx.idCheckout}
            idTrx={trx.idCheckout}
            createdAt={trx.createdAt}
            expiredAt={trx.expiredAt}
            paidAt={trx.paidAt}
            paymentChannel={trx.paymentChannel}
            paymentMethod={trx.paymentMethod}
            paymentStatus={trx.paymentStatus}
            biayaPg={trx.biayaPg}
            totalAmount={trx.totalAmount}
          />
        ))}
      </div>


    </div>
  )

}

const DetailCicilanBox = ({
  idCicilanMember,
  idOrangtua,
  namaOrangtua,
  idAnak,
  namaAnak,
  idPaketBimbel,
  namaPaketBimbel,
  hargaPaketBimbel,
  idSkemaCicilan,
  namaSkemaCicilan,
  jmlFrekuensiCicilan,
  biayaPendaftaran,
  biayaPokokCicilan,
  biayaPertamaBayar,
  sisaFrekuensiCicilan,
  description,
  paymentChannel,
  paymentMethod,
  sisaCicilan,
  isLunas,
  createdAt
}) => {

  return (
    <div
      className="space-y-5"
    >
      <div
        className="p-2 border border-gray-300 rounded-lg space-y-2"
      >
        <div 
          className="text-left font-bold"
        >
          Detail Cicilan
        </div>
        <div className="grid grid-cols-3 gap-3">
          <div className="space-y-1">
            <div className="text-left">Id Cicilan</div>
            <div className="flex">
              <input 
                className="p-1 px-2 rounded-md bg-gray-200 w-full"
                value={idCicilanMember}
              />
            </div>
          </div>
          <div className="space-y-1">
            <div className="text-left">Tgl Dibuat</div>
            <div className="flex">
              <input 
                className="p-1 px-2 rounded-md bg-gray-200 w-full"
                value={createdAt}
              />
            </div>
          </div>
          <div className="space-y-1">
            <div className="text-left">Status</div>
            <div className="flex">
              <input 
                className={
                  "p-1 px-2 rounded-md w-full font-bold " +
                  (isLunas ? " bg-green-500 text-white " : " bg-red-500 text-white ")
                }
                value={isLunas ? "Lunas" : "Belum Lunas"}
              />
            </div>
          </div>
          <div className="space-y-1">
            <div className="text-left">Sisa Cicilan</div>
            <div className="flex">
              <input 
                className="p-1 px-2 rounded-md bg-gray-200 w-full"
                value={sisaCicilan}
              />
            </div>
          </div>
        </div>

      </div>
      <div
        className="grid grid-cols-3 gap-x-3"
      >
        <div
          className="p-2 border border-gray-300 rounded-lg space-y-2"
        >
          <div 
            className="text-left font-bold"
          >
            Detail Orangtua
          </div>
          <div className="flex flex-col space-y-2">
            <div className="space-y-1">
              <div className="text-left">Id Orangtua</div>
              <div className="flex">
                <input 
                  className="p-1 px-2 rounded-md bg-gray-200 w-full"
                  value={idOrangtua}
                />
              </div>
            </div>
            <div className="space-y-1">
              <div className="text-left">Nama Orangtua</div>
              <div className="flex">
                <input 
                  className="p-1 px-2 rounded-md bg-gray-200 w-full"
                  value={namaOrangtua}
                />
              </div>
            </div>
          </div>


        </div>
        <div
          className="p-2 border border-gray-300 rounded-lg space-y-2"
        >
          <div 
            className="text-left font-bold"
          >
            Detail Anak
          </div>
          <div className="flex flex-col space-y-2">
            <div className="space-y-1">
              <div className="text-left">Id Anak</div>
              <div className="flex">
                <input 
                  className="p-1 px-2 rounded-md bg-gray-200 w-full"
                  value={idAnak}
                />
              </div>
            </div>
            <div className="space-y-1">
              <div className="text-left">Nama Anak</div>
              <div className="flex">
                <input 
                  className="p-1 px-2 rounded-md bg-gray-200 w-full"
                  value={namaAnak}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="p-2 border border-gray-300 rounded-lg space-y-2"
        >
          <div 
            className="text-left font-bold"
          >
            Detail Paket Bimbel
          </div>
          <div className="flex flex-col space-y-2">
            <div className="space-y-1">
              <div className="text-left">Id Paket Bimbel</div>
              <div className="flex">
                <input 
                  className="p-1 px-2 rounded-md bg-gray-200 w-full"
                  value={idPaketBimbel}
                />
              </div>
            </div>
            <div className="space-y-1">
              <div className="text-left">Nama Paket Bimbel</div>
              <div className="flex">
                <input 
                  className="p-1 px-2 rounded-md bg-gray-200 w-full"
                  value={namaPaketBimbel}
                />
              </div>
            </div>
            <div className="space-y-1">
              <div className="text-left">Harga</div>
              <div className="flex">
                <input 
                  className="p-1 px-2 rounded-md bg-gray-200 w-full"
                  value={formatRupiah(hargaPaketBimbel)}
                />
              </div>
            </div>
            
          </div>

        </div>
      </div>
      <div
        className="p-2 border border-gray-300 rounded-lg space-y-2"
      >
        <div 
          className="text-left font-bold"
        >
          Detail Skema Cicilan
        </div>
        <div className="grid grid-cols-3 gap-3">
          <div className="space-y-1">
            <div className="text-left">Id Skema Cicilan</div>
            <div className="flex">
              <input 
                className="p-1 px-2 rounded-md bg-gray-200 w-full"
                value={idSkemaCicilan}
              />
            </div>
          </div>
          <div className="space-y-1">
            <div className="text-left">Skema Cicilan</div>
            <div className="flex">
              <input 
                className="p-1 px-2 rounded-md bg-gray-200 w-full"
                value={namaSkemaCicilan}
              />
            </div>
          </div>
          <div className="space-y-1">
            <div className="text-left">Jml Frekuensi Cicilan</div>
            <div className="flex">
              <input 
                className="p-1 px-2 rounded-md bg-gray-200 w-full"
                value={jmlFrekuensiCicilan}
              />
            </div>
          </div>
          <div className="space-y-1">
            <div className="text-left">Biaya Pendaftaran</div>
            <div className="flex">
              <input 
                className="p-1 px-2 rounded-md bg-gray-200 w-full"
                value={formatRupiah(biayaPendaftaran)}
              />
            </div>
          </div>
          <div className="space-y-1">
            <div className="text-left">Biaya Pokok Cicilan</div>
            <div className="flex">
              <input 
                className="p-1 px-2 rounded-md bg-gray-200 w-full"
                value={formatRupiah(biayaPokokCicilan)}
              />
            </div>
          </div>
          <div className="space-y-1">
            <div className="text-left">Biaya Pertama Bayar</div>
            <div className="flex">
              <input 
                className="p-1 px-2 rounded-md bg-gray-200 w-full"
                value={formatRupiah(biayaPertamaBayar)}
              />
            </div>
          </div>
        </div>        

      </div>
    </div>
  )

}

const CicilanDetailPage = () => {

  const { id } = useParams()

  const dispatch = useDispatch()
  const history = useHistory()

  const [isGettingData, setIsGettingData] = useState(false)
  const [isGettingTransactionData, setIsGettingTransactionData] = useState(false)

  const [cicilanDetail, setCicilanDetail] = useState(null)
  const [cicilanTransactions, setCicilanTransaction] = useState([])
  const [payGroupChans, setPayGroupChans] = useState([])

  const [isCreateInvoiceDialogOpen, setIsCreateInvoiceDialogOpen] = useState(false)
  const [isRefetchTransactions, setIsRefetchTransactions] = useState(true)

  useEffect(() => {
    setIsGettingData(true)

    getDetail(id)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setCicilanDetail(payload)
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => {
        notifyError('Critical Error. Please Contact Developer.')
      })
      .finally(() => setIsGettingData(false))

      getPaymentChannel()
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
              setPayGroupChans(payload)
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .catch(error => {
          notifyError("Fetching Payment Channel Failed.")
        })

  }, [])

  useEffect(() => {
    if (!isRefetchTransactions) return;

    setIsGettingTransactionData(true)

    getCicilanTransaction(id)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
              setCicilanTransaction(payload)
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .catch(error => {
          notifyError('Critical Error. Please Contact Developer.')
        })
        .finally(() => {
          setIsGettingTransactionData(false)
          setIsRefetchTransactions(false)
        })

  }, [isRefetchTransactions])

  function onCreateInvoice(
    idCicilanMember, 
    paymentChannel, 
    paymentMethod,
    reduceCicilanTotal
  ) {

    dispatch(setIsHomeProcess(true))

    const payload = {
      idCicilan: idCicilanMember,
      paymentChannel: paymentChannel,
      paymentMethod: paymentMethod,
      reduceCicilanTotal: reduceCicilanTotal
    }

    createInvoice(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            notifySuccess("Berhasil kirim tagihan")

            setIsCreateInvoiceDialogOpen(false)
            setIsRefetchTransactions(true)
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => {
        notifyError("Critical Error. Please contact developer.")
      }) 
      .finally(() => dispatch(setIsHomeProcess(false)))   

  }

  return (
    <>
    <CreateInvoiceDialog
      isShow={isCreateInvoiceDialogOpen}
      cicilanDetail={cicilanDetail}
      payChans={payGroupChans}
      onClose={() => setIsCreateInvoiceDialogOpen(false)}
      onCreate={onCreateInvoice}
    />
    <div
      className="w-full space-y-5"
    >
      <div
        className="p-3 bg-white rounded-lg space-y-3"
      >
        <div className="py-2 flex">
          <button 
            className="flex items-center space-x-2 py-2 rounded-lg border px-3 pr-5"
            onClick={() => history.push("/home/cicilan")}
          >
            <BsChevronLeft 
              className="w-5 h-5"
            />
            <label className="cursor-pointer">Kembali</label>
          </button>
        </div>
        {
          isGettingData ?
            (<div
              className="flex items-center justify-center text-blue-600 bg-white"
              style={{ minHeight: "50px" }}
            >
              <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
            </div>)
            :
            cicilanDetail ? 
              (<DetailCicilanBox 
                idCicilanMember={cicilanDetail.idCicilanMember}
                idOrangtua={cicilanDetail.idOrangtua}
                namaOrangtua={cicilanDetail.namaOrangtua}
                idAnak={cicilanDetail.idAnak}
                namaAnak={cicilanDetail.namaAnak}
                idPaketBimbel={cicilanDetail.idPaketBimbel}
                namaPaketBimbel={cicilanDetail.namaPaketBimbel}
                hargaPaketBimbel={cicilanDetail.hargaPaketBimbel}
                idSkemaCicilan={cicilanDetail.idSkemaCicilan}
                namaSkemaCicilan={cicilanDetail.namaSkemaCicilan}
                jmlFrekuensiCicilan={cicilanDetail.jmlFrekuensiCicilan}
                biayaPendaftaran={cicilanDetail.biayaPendaftaran}
                biayaPokokCicilan={cicilanDetail.biayaPokokCicilan}
                biayaPertamaBayar={cicilanDetail.biayaPertamaBayar}
                sisaFrekuensiCicilan={cicilanDetail.sisaFrekuensiCicilan}
                description={cicilanDetail.description}
                paymentChannel={cicilanDetail.paymentChannel}
                paymentMethod={cicilanDetail.paymentMethod}
                sisaCicilan={cicilanDetail.sisaCicilan}
                isLunas={cicilanDetail.isLunas}
                createdAt={cicilanDetail.createdAt}
              />)
              :
              null     
        }
      </div>
      <div
        className="p-3 bg-white rounded-lg"
      >
        {
          isGettingTransactionData ?
            (<div
              className="flex items-center justify-center text-blue-600 bg-white"
              style={{ minHeight: "50px" }}
            >
              <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
            </div>)
            :
            (<CicilanTransactionBox 
                onOpenCreateInvoiceDialog={() => {
                  window.scrollTo(0, 0)
                  setIsCreateInvoiceDialogOpen(true)
                }}
                transactions={cicilanTransactions}
            />)     
        }
      </div>
    </div>
    </>
  )

}

export default CicilanDetailPage;