import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { setHomeActivePage } from "../../../app/GlobalSlice"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { registerLocale } from "react-datepicker";
import id from 'date-fns/locale/id'
import { GoCalendar } from "react-icons/go"
import { initModel, setData, setDateParam, setTransactionSummary } from "../Models/Model";
import { setIsLoadData } from "../../Member/Models/Model";
import { getData, getDataSummary, getProgramIdLabel } from "../Apis/Api";
import { handleResponse200 } from "../../../utils/HttpUtils";
import { notifyError } from "../../../utils/Notification";
import { RiFileExcel2Fill, RiLoader5Fill } from "react-icons/ri";
import { format } from "date-fns"
import Select from "react-select"

registerLocale(id)

const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
    { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}

function ItemBox({
  no,
  idTransaction,
  namaPaketBimbel,
  namaAnak,
  namaMember,
  status,
  createdAt,
  expiredAt,
  paidAt,
  finalPrice,
  paymentChannel
}) {
  const rowClassName = "flex px-2 py-2 items-center bg-white border-b border-gray-200 "

  const paymentStatusStyle = (status) => {
    if (status === 'PAID') {
      return " rounded-full bg-green-500 text-white py-1 px-8 "
    }
    else if (status === 'REFUND') {
      return " rounded-full bg-red-500 text-white py-1 px-8 "
    }
    else if (status === 'EXPIRED') {
      return " rounded-full bg-red-500 text-white py-1 px-8 "
    }
    else {
      return " rounded-full bg-blue-500 text-white py-1 px-8 "
    }
  }

  return (
    <div
      className="w-full flex shadow-lg divide-x divide-gray-300"
      style={{
        fontSize: "14px",
        fontFamily: 'Nunito'
      }}
    >
      <div 
        className={rowClassName} 
        style={{ 
          width: "5%",
          minWidth: "50px"
        }}
      >
        {no}
      </div>
      <div 
        className={rowClassName} 
        style={{ 
          width: "10%",
          minWidth: "150px"
        }}
      >
        {idTransaction}
      </div>
      <div 
        className={rowClassName} 
        style={{ 
          width: "20%",
          minWidth: "250px"
        }}
      >
        {namaPaketBimbel}
      </div>
      <div 
        className={rowClassName} 
        style={{ 
          width: "20%",
          minWidth: "250px"
        }}
      >
        {namaAnak}
      </div>
      <div 
        className={rowClassName} 
        style={{ 
          width: "20%",
          minWidth: "250px"
        }}
      >
        {namaMember}
      </div>
      <div 
        className={rowClassName + ' justify-center '} 
        style={{ 
          width: "10%",
          minWidth: "150px"
        }}
      >
        <div className={paymentStatusStyle(status)}>
          {status}
        </div>
      </div>
      <div 
        className={rowClassName + ' justify-center '} 
        style={{ 
          width: "10%",
          minWidth: "150px"
        }}
      >
        {format(new Date(createdAt), "dd/MM/yyyy HH:mm")}
      </div>
      <div 
        className={rowClassName + ' justify-center '} 
        style={{ 
          width: "10%",
          minWidth: "150px"
        }}
      >
        {format(new Date(expiredAt), "dd/MM/yyyy HH:mm")}
      </div>
      <div 
        className={rowClassName + ' justify-center '} 
        style={{ 
          width: "10%",
          minWidth: "150px"
        }}
      >
        {paidAt ? format(new Date(paidAt), "dd/MM/yyyy HH:mm") : '-'}
      </div>
      <div 
        className={rowClassName + ' justify-end '} 
        style={{ 
          width: "10%",
          minWidth: "150px"
        }}
      >
        {formatRupiah(finalPrice)}
      </div>
      <div 
        className={rowClassName + ' justify-center '} 
        style={{ 
          width: "10%",
          minWidth: "200px"
        }}
      >
        {paymentChannel}
      </div>
    </div>
  )
} 

function HeaderTable() {
  return (
    <div
      className="w-full bg-white flex items-center shadow-lg divide-x divide-gray-300 font-bold"
      style={{
        fontSize: "15px",
        fontFamily: 'Nunito'
      }}
    >
      <div 
        className="flex px-2 py-2 group-hover:text-white items-center justify-center bg-gray-100 border-b" 
        style={{ 
          width: "5%",
          minWidth: "50px" 
        }}
      >
        No
      </div>
      <div
        className="px-2 py-2 text-left flex items-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{
          width: "10%",
          minWidth: "150px"
        }}
      >
        Kode
      </div>
      <div
        className="px-2 py-2 text-left flex items-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{
          width: "20%",
          minWidth: "250px"
        }}
      >
        Paket Bimbel
      </div>
      <div
        className="px-2 py-2 text-left flex items-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{
          width: "20%",
          minWidth: "250px"
        }}
      >
        Anak
      </div>
      <div
        className="px-2 py-2 text-left flex items-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{
          width: "20%",
          minWidth: "250px"
        }}
      >
        Member
      </div>
      <div
        className="flex px-2 py-2 items-center justify-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{ 
          width: "10%",
          minWidth: "150px" 
        }}
      >
        Status
      </div>
      <div
        className="flex px-2 py-2 items-center justify-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{ 
          width: "10%",
          minWidth: "150px" 
        }}
      >
        Tgl dibuat
      </div>
      <div
        className="flex px-2 py-2 items-center justify-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{ 
          width: "10%",
          minWidth: "150px" 
        }}
      >
        Tgl Expired
      </div>
      <div
        className="flex px-2 py-2 items-center justify-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{ 
          width: "10%",
          minWidth: "150px" 
        }}
      >
        Tgl Bayar
      </div>
      <div
        className="flex px-2 py-2 items-center justify-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{ 
          width: "10%",
          minWidth: "150px" 
        }}
      >
        Total Bayar
      </div>
      <div
        className="flex px-2 py-2 items-center justify-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{ 
          width: "10%",
          minWidth: "200px" 
        }}
      >
        Payment Channel
      </div>
    </div>
  )
}

function DataTable({
  isLoadData,
  datas,
  rootModel,
  setRootModel
}) {

  const history = useHistory()

  return (
    <div className="space-y-3">
      <div className="overflow-x-auto bg-white">
        <HeaderTable />
        {
          isLoadData ?
            (<div
              className="flex items-center justify-center text-blue-600 bg-white"
              style={{ minHeight: "50px" }}
            >
              <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
            </div>)
            :
            datas.length > 0 ?
              (<div>
                {datas.map((d, idx) => (
                  <ItemBox
                    no={idx + 1}
                    idTransaction={d.idCheckout}
                    namaPaketBimbel={d.namaPaketBimbel}
                    namaAnak={d.namaAnak}
                    namaMember={d.namaMember}
                    status={d.paymentStatus}
                    createdAt={d.createdAt}
                    expiredAt={d.expiredAt}
                    finalPrice={d.totalAmount - d.biayaPg}
                    paymentChannel={d.paymentChannel}
                    paidAt={d.paidAt}
                  />
                ))}
              </div>)
              :
              (<div className="py-2 bg-white">
                Tidak ada data
              </div>)
        }
      </div>
    </div>
  )

}

const ParamBox = ({
  onParamChanged
}) => {

  const date = new Date()
  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1))
  const [untilDate, setUntilDate] = useState(date)

  const [startDateExport, setStartDateExport] = useState(new Date(date.getFullYear(), date.getMonth(), 1))
  const [untilDateExport, setUntilDateExport] = useState(date)

  const [programOpts, setProgramOpts] = useState({
    selectedOpt: { label: "Semua", value: null },
    data: [],
    isLoad: false
  })

  useEffect(() => {

    onParamChanged(toIsoDate(startDate), toIsoDate(untilDate), programOpts.selectedOpt.value)

  }, [startDate, untilDate, programOpts.selectedOpt])

  function toIsoDate(date) {
    return date.getFullYear() + '-' + padZero(date.getMonth() + 1) + '-' + padZero(date.getDate().toString())
  }

  function padZero(n) {
    return String(n).padStart(2, '0')
  }

  function onSelectProgramOpts() {
    setProgramOpts({ ...programOpts, isLoad: true })

    getProgramIdLabel()
      .then(response => {
        if (response.success) {
          const data = [{ label: "Semua", value: null }].concat(response.payload.map(d => ({ label: d.label, value: d.id })))
          setProgramOpts({ ...programOpts, data: data, isLoad: false })
        }
        else {
          setProgramOpts({ ...programOpts, isLoad: false })
        }
      })
      .catch(error => {
        setProgramOpts({ ...programOpts, isLoad: false })
        if (error.response != undefined && error.response != null) {
          if (error.response.status == 401) {
            notifyError("Forbidden")
          }
          else {
            notifyError("Server Error")
          }
        }
      })
  }

  return (
    <div>
      <div
        className="flex items-center space-x-2 px-5"
        style={{ height: "50px" }}
      >
        <div
          className="font-bold w-20 text-left"
          style={{
            fontFamily: 'Nunito'
          }}
        >
          Periode
        </div>
        <div className="flex items-center">
          <div className="h-8 flex items-center px-1 border-t border-l border-b border-gray-400">
            <GoCalendar />
          </div>
          <DatePicker 
            locale={id}
            className="border border-gray-400 p-1 w-28 h-8" 
            selected={startDate} 
            onChange={(date) => setStartDate(date)} 
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div>-</div>
        <div className="flex items-center">
          <div className="h-8 flex items-center px-1 border-t border-l border-b border-gray-400">
            <GoCalendar />
          </div>
          <DatePicker
            className="border border-gray-400 p-1 w-28 h-8" 
            selected={untilDate} 
            onChange={(date) => setUntilDate(date)} 
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </div>
      <div
        className="flex items-center space-x-2 px-5"
        style={{ height: "50px" }}
      >
        <div
          className="font-bold w-20 text-left"
          style={{
            fontFamily: 'Nunito'
          }}
        >
          Program
        </div>
        <div className="flex items-center">
          <Select
            value={programOpts.selectedOpt}
            options={programOpts.data}
            isLoading={programOpts.isLoad}
            menuPlacement="auto"
            className="w-60 border border-gray-200 rounded-md text-left"
            onFocus={onSelectProgramOpts}
            onChange={(value) => {
              setProgramOpts({ ...programOpts, selectedOpt: value })
            }}
          />
        </div>
      </div>
      <div
        className="p-3 border px-5"
      >
        <div
          className="flex items-center space-x-2"
          style={{ height: "50px" }}
        >
          <div
            className="font-bold w-24 text-left"
            style={{
              fontFamily: 'Nunito'
            }}
          >
            Periode
          </div>
          <div className="flex items-center">
            <div className="h-8 flex items-center px-1 border-t border-l border-b border-gray-400">
              <GoCalendar />
            </div>
            <DatePicker 
              locale={id}
              className="border border-gray-400 p-1 w-28 h-8" 
              selected={startDateExport} 
              onChange={(date) => setStartDateExport(date)} 
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div>-</div>
          <div className="flex items-center">
            <div className="h-8 flex items-center px-1 border-t border-l border-b border-gray-400">
              <GoCalendar />
            </div>
            <DatePicker
              className="border border-gray-400 p-1 w-28 h-8" 
              selected={untilDateExport} 
              onChange={(date) => setUntilDateExport(date)} 
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="px-2">
            <button 
              className="bg-green-600 text-white p-1 px-5 flex items-center"
              onClick={() => {
                window.open('https://bimbelalc.id/bimbel-alc-exporter/?from='+toIsoDate(startDateExport)+'&until='+toIsoDate(untilDateExport), '_blank');
              }}
            >
              <RiFileExcel2Fill className="h-5 w-5 mr-2"/>
              Excel
            </button>
          </div>
        </div> 
      </div>

    </div>
  )
}

const CountedBox = ({
  totalCreated,
  totalPaid,
  totalExpired
}) => {
  return (
    <div
      className="flex space-x-5 p-2 "
    >
      <div
        className="space-y-2"
      >
        <div className="flex justify-center">
          <div
            className="flex items-center justify-center rounded-full border-4 border-blue-500 font-bold"
            style={{
              width: "70px",
              height: "70px",
              fontSize: "22px"
            }}
          >
            {totalCreated}
          </div>
        </div>
        <div
          className="flex justify-center"
        >
          <label
            className="py-1 px-5 rounded-full bg-blue-500 text-white"
            style={{
              fontSize: '14px'
            }}
          >
            Transaksi
          </label>
        </div>
      </div>
      <div
        className="space-y-2"
      >
        <div className="flex justify-center">
          <div
            className="flex items-center justify-center rounded-full border-4 border-green-500 font-bold"
            style={{
              width: "70px",
              height: "70px",
              fontSize: "22px"
            }}
          >
            {totalPaid}
          </div>
        </div>
        <div
          className="flex justify-center"
        >
          <label
            className="py-1 px-5 rounded-full bg-green-500 text-white"
            style={{
              fontSize: '14px'
            }}
          >
            Dibayar
          </label>
        </div>
      </div>
      <div
        className="space-y-2"
      >
        <div className="flex justify-center">
          <div
            className="flex items-center justify-center rounded-full border-4 border-red-500 font-bold"
            style={{
              width: "70px",
              height: "70px",
              fontSize: "22px"
            }}
          >
            {totalExpired}
          </div>
        </div>
        <div
          className="flex justify-center"
        >
          <label
            className="py-1 px-5 rounded-full bg-red-500 text-white"
            style={{
              fontSize: '14px'
            }}
          >
            Expired
          </label>
        </div>
      </div>
    </div>
  )
}

const FilterBox = ({
  onPaymentStatusChanged
}) => {
  const statuses = [
    {
      value : null,
      label : 'ALL'
    },
    {
      value : 'UNPAID',
      label : 'UNPAID'
    },
    {
      value : 'PAID',
      label : 'PAID'
    },
    {
      value : 'EXPIRED',
      label : 'EXPIRED'
    }
  ] 

  const [selectedStatus, setSelectedStatus] = useState({ value: null, label: 'All' })

  useEffect(() => {
    onPaymentStatusChanged(selectedStatus)
  }, [selectedStatus])

  return (
    <div>
      <div className="flex items-center space-x-2" style={{ fontFamily: 'Nunito' }}>
        <div>
          <Select
            value={selectedStatus}
            options={statuses}
            menuPlacement="auto"
            className="w-40 border border-gray-200 rounded-md"
            onChange={(value) => {
              setSelectedStatus(value)
            }}
          />
        </div>
      </div>
    </div>
  )

}

export default function TransactionPage() {
  const dispatch = useDispatch()
  const history = useHistory()

  const [model, setModel] = useState(initModel)

  const [countedData, setCountedData] = useState({
    created: 0,
    unpaid: 0,
    paid: 0,
    expired: 0
  })

  const [paymentStatusFilter, setPaymentStatusFilter] = useState(null)

  useEffect(() => {

    dispatch(setHomeActivePage('transaction'))

  }, [])

  useEffect(() => {
    if (!model.dataProperties.fromDate || !model.dataProperties.untilDate) return

    onGetData(model, setModel, history)
    onGetDataSummary(model, setModel, history)
  }, [model.dataProperties])
  
  useEffect(() => {
    if (model.dataList.length === 0) {
      return setCountedData({
        created: 0,
        unpaid: 0,
        paid: 0,
        expired: 0
      })
    }

    let current = new Date()

    setCountedData({
      created: model.dataList.length,
      unpaid: model.dataList.filter(t => t.paidAt === null).length,
      paid: model.dataList.filter(t => t.paidAt).length,
      expired: model.dataList.filter(t => t.paidAt === null && current > new Date(t.expiredAt)).length
    })

  }, [model.dataList])

  function filterBasedStatus(dataList, status) {
    if (status === 'PAID') {
      return dataList.filter(t => t.paidAt)
    }
    else if (status === 'UNPAID') {
      return dataList.filter(t => t.paidAt === null) 
    }
    else if (status === 'EXPIRED') {
      let current = new Date()
      return dataList.filter(t => t.paidAt === null && current > new Date(t.expiredAt))
    }
    else {
      return dataList
    }
  }

  function countTotalWajibBayar(dataList) {
    var total = 0
    dataList.forEach(t => {
      total += (t.totalAmount - t.biayaPg)
    })

    return total
  }

  function countTotalPenerimaan(dataList) {
    var total = 0
    dataList.filter(t => t.paidAt).forEach(t => {
      total += (t.totalAmount - t.biayaPg)
    })

    return total
  }

  return (
    <div className="space-y-3 w-full">
      <div className="bg-white rounded-lg shadow-lg ">
        <ParamBox 
          onParamChanged={(fromDateStr, untilDataStr, programId) => setModel(setDateParam(model, fromDateStr, untilDataStr, programId))}
        />
        <div className="grid grid-cols-3 p-3 space-x-3">
          <div
            className="flex justify-center border border-gray-300 rounded-lg"
          >
            <CountedBox 
              // totalCreated={countedData.created}
              // totalPaid={countedData.paid}
              // totalExpired={countedData.expired}
              totalCreated={model.transactionSummary ? model.transactionSummary.transactionCount : '-1'}
              totalPaid={model.transactionSummary ? model.transactionSummary.paidTransactionCount : '-1'}
              totalExpired={model.transactionSummary ? model.transactionSummary.expiredTransactionCount : '-1'}
            />
          </div>
          <div className="px-3 py-1 border border-gray-300 rounded-lg" style={{ fontFamily: 'Nunito' }}>
            <div className="flex space-x-2">
              <div>Total wajib bayar</div>
              <div>:</div>
              <div>
                {/* {formatRupiah(countTotalWajibBayar(model.dataList))} */}
                {
                  model.transactionSummary ? 
                    formatRupiah(model.transactionSummary.totalWajibBayar) 
                    :
                    'Processing...'
                }
              </div>
            </div>
            <div className="flex space-x-2">
              <div>Total Penerimaan</div>
              <div>:</div>
              <div>
                {/* {formatRupiah(countTotalPenerimaan(model.dataList))} */}
                {
                  model.transactionSummary ? 
                    formatRupiah(model.transactionSummary.totalPenerimaan) 
                    :
                    'Processing...'
                }
              </div>
            </div>
          </div>
          <FilterBox 
            onPaymentStatusChanged={valLabel => setPaymentStatusFilter(valLabel.value)}
          />
        </div>
      </div>
      <div>
        <DataTable 
          isLoadData={false}
          datas={filterBasedStatus(model.dataList, paymentStatusFilter)}
        />
      </div>
    </div>
  )
}

function onGetData(rootModel, setRootModel, history) {
  const payload = {
    search: rootModel.searchHeader.searchText,
    offset: rootModel.dataProperties.offset,
    limit: rootModel.dataProperties.limit,
    orderBy: rootModel.dataProperties.ord,
    fromDate: rootModel.dataProperties.fromDate,
    untilDate: rootModel.dataProperties.untilDate,
    program: rootModel.dataProperties.programId
  }

  // setRootModel(setIsLoadData(rootModel, true))

  getData(payload)
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM => 
            (latestM.dataProperties.ord === rootModel.dataProperties.ord &&
              latestM.dataProperties.fromDate === rootModel.dataProperties.fromDate &&
              latestM.dataProperties.untilDate === rootModel.dataProperties.untilDate &&
              latestM.dataProperties.program === rootModel.dataProperties.program
            ) ?
              setData(latestM, payload)
              :
              latestM
          )
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          history.push("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => {
      // setRootModel(latestM => setIsLoadData(rootModel, false))
    })

}

function onGetDataSummary(rootModel, setRootModel, history) {
  const payload = {
    search: rootModel.searchHeader.searchText,
    offset: rootModel.dataProperties.offset,
    limit: rootModel.dataProperties.limit,
    orderBy: rootModel.dataProperties.ord,
    fromDate: rootModel.dataProperties.fromDate,
    untilDate: rootModel.dataProperties.untilDate,
    program: rootModel.dataProperties.programId
  }

  // setRootModel(setIsLoadData(rootModel, true))

  getDataSummary(payload)
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM => 
            (latestM.dataProperties.ord === rootModel.dataProperties.ord &&
              latestM.dataProperties.fromDate === rootModel.dataProperties.fromDate &&
              latestM.dataProperties.untilDate === rootModel.dataProperties.untilDate &&
              latestM.dataProperties.program === rootModel.dataProperties.program
            ) ?
              setTransactionSummary(latestM, payload)
              :
              latestM
          )
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          history.push("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => {
      // setRootModel(latestM => setIsLoadData(rootModel, false))
    })

}