import axios from "axios"

export const getDataSummary = async () => {
  const finalUrl = window._HOST + "admin/member/data-summary"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/member/data-table"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getIdLabel = async (payload) => {
  const finalUrl =
    window._HOST + "admin/member/id-label"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const getDetail = async (id) => {
  const finalUrl =
    window._HOST + "admin/member/detail/" + id
  
  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const createData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/member"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/member"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const setMailVerification = async (idOrangtua) => {
  const finalUrl =
    window._HOST + "admin/member-mail-verification/" + idOrangtua

  const response =
    await axios.put(finalUrl, { dummy: true }, { withCredentials: true })

  return response.data
}

export const resetPassword = async (idOrangtua) => {
  const finalUrl =
    window._HOST + "admin/member-reset-password/"+idOrangtua

  const response =
    await axios.put(finalUrl, {}, { withCredentials: true })

  return response.data
}

export const updateDataActivation = async (payload) => {
  const finalUrl =
    window._HOST + "admin/member-activation"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const deleteData = async (kodeData) => {
  const finalUrl =
    window._HOST + "admin/member/" + kodeData

  const response =
    await axios.delete(finalUrl, { withCredentials: true })

  return response.data
}

export const getProvinsi = async () => {
  const finalUrl =
      window._HOST + "public/provinsi" 

  const response =
      await axios.get(finalUrl)

  return response.data
}

export const getKabupaten = async (idProvinsi) => {
  const finalUrl =
      window._HOST + "public/kabupaten" + (idProvinsi ? "?id-provinsi="+idProvinsi : "" ) 

  const response =
      await axios.get(finalUrl)

  return response.data
}

export const getKecamatan = async (idKabupaten) => {
  const finalUrl =
      window._HOST + "public/kecamatan" + (idKabupaten ? "?id-kabupaten="+idKabupaten : "" ) 

  const response =
      await axios.get(finalUrl)

  return response.data
}

export const getKelasIdLabel = async () => {
  const finalUrl =
      window._HOST + "member/kelas/id-label"

  const response =
      await axios.get(finalUrl)

  return response.data
}

export const getSekolahIdLabel = async () => {
  const finalUrl =
      window._HOST + "member/sekolah/id-label"

  const response =
      await axios.get(finalUrl)

  return response.data
}

export const getProgramIdLabel = async (idKelas) => {
  const finalUrl =
      window._HOST + "admin/program/id-label?id-kelas=" + idKelas

  const response =
      await axios.get(finalUrl)

  return response.data
}