import { Transition } from "@headlessui/react"

import {
  RiAddLine,
  RiArrowLeftLine,
  RiCloseFill,
  RiEdit2Line,
  RiEye2Fill,
  RiEyeCloseFill,
  RiEyeFill,
  RiFilePaperLine,
  RiLoader5Fill,
  RiSearchLine
} from "react-icons/ri";
import { MdSort } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BsChevronLeft, BsChevronRight, BsFillGridFill, BsTrash } from "react-icons/bs"

import { format } from "date-fns"
import FormPage from "./FormPage";
import DeleteDataDialog from "./DeleteDataDialog";
import { useEffect, useState } from "react";
import {
  closeDeleteDialog,
  closeFormDialog,
  dataPropertOrdToStr,
  goToNextPage,
  goToPrevPage,
  hasNextPage,
  hasPrevPage,
  initModel,
  openAddFormPage,
  openDeleteDialog,
  openDetailFormPage,
  openEditFormPage,
  resetSearchText,
  setData,
  setDataSummary,
  setFinishRefetchData,
  setFinishRefetchDataSummary,
  setIsLoadData,
  setSearchText,
  setSortFromNewestData,
  setSortFromOldestData,
  setStartRefetchData,
  setStartRefetchDataSummary
} from "../Models/Model";
import {
  createData,
  deleteData,
  getData,
  getDataSummary,
  updateData,
  updateDataActivation
} from "../Apis/Api";
import { handleResponse200 } from "../../../utils/HttpUtils"
import { useDispatch } from "react-redux";
import { setHomeActivePage, setIsHomeProcess } from "../../../app/GlobalSlice";
import { notifyError } from "../../../utils/Notification";
import { useHistory } from "react-router";

function DataTableFooter({
    pageNum,
    showCount,
    sortMode,
    searchText,
    hasNextPage,
    hasPrevPage,
    onGoToNextPage,
    onGoToPrevPage
  }) {
    return (
      <div
        className="bg-white py-1 px-3 shadow-lg flex items-center justify-between rounded-md"
        style={{
          fontSize: "14px",
          height: "45px"
        }}
      >
        <div className="flex space-x-2">
          <label>
            Hal. {pageNum},
          </label>
          <label>
            Tampil: {showCount},
          </label>
          <label>
            Mode pengurutan:  {sortMode},
          </label>
          <label>
            Pencarian: {searchText && searchText !== '' ? searchText : '-'}
          </label>
        </div>
        <div className="flex">
          {hasPrevPage && (
            <button
              className=" p-2 rounded-lg hover:bg-gray-200"
              title={"Ke Halaman sebelumnya"}
              onClick={onGoToPrevPage}
            >
              <BsChevronLeft className="h-5 w-5" />
            </button>
          )}
          {hasNextPage && (
            <button
              className=" p-2 rounded-lg hover:bg-gray-200"
              title={"Ke Halaman selanjutnya"}
              onClick={onGoToNextPage}
            >
              <BsChevronRight className="h-5 w-5" />
            </button>
          )}
        </div>
      </div>
    )
  }
  
  function ItemBox({
    no,
    idDiskon,
    namaDiskon,
    nominal,
    jenis,
    createdAt,
    updatedAt,
    isActive,
    onSetNonActive,
    onSetActive,
    onDelete,
    onViewDetail,
    onEdit,
  }) {
  
    return (
      <div
        className="py-1 w-full bg-white group hover:bg-blue-500 flex shadow-lg divide-x divide-gray-300 border-b border-gray-200"
        style={{
          fontSize: "14px"
        }}
      >
        <div 
          className="flex px-2 group-hover:text-white items-center " 
          style={{ 
            width: "4%",
            minWidth: "50px"
          }}
        >
          {no}
        </div>
        <div
          className="px-2 text-left flex items-center group-hover:text-white"
          style={{
            width: "12%",
            minWidth: "150px"
          }}
        >
          {idDiskon}
        </div>
        <div
          className="px-2 text-left flex items-center group-hover:text-white"
          style={{
            width: "39%",
            minWidth: "200px"
          }}
        >
          {namaDiskon}
        </div>
        <div 
          className="flex px-2 items-center justify-center group-hover:text-white" 
          style={{ 
            width: "10%",
            minWidth: "130px"
          }}
        >
          {nominal}
        </div>
        <div 
          className="flex px-2 items-center justify-center group-hover:text-white" 
          style={{ 
            width: "10%",
            minWidth: "130px"
          }}
        >
          {jenis}
        </div>
        <div
          className="flex px-2 items-center justify-center group-hover:text-white"
          style={{ 
            width: "10%",
            minWidth: "130px" 
          }}
        >
          <label
            className={
              "rounded-md px-3 text-white " +
              (isActive ? " bg-green-500 " : " bg-red-500 ")
            }
          >
            {isActive ? 'Aktif' : 'Tdk Aktif'}
          </label>
        </div>
        <div 
          className="px-3 flex justify-center" 
          style={{ 
            width: "15%",
            minWidth: "195px" 
          }}
        >
          <button
            className="p-2 rounded-lg hover:bg-black text-black group-hover:text-white"
            onClick={() => {
              isActive ?
                onSetNonActive()
                :
                onSetActive()
            }}
          >
            {
              isActive ?
                <AiOutlineEyeInvisible className="h-5 w-5" />
                :
                <AiOutlineEye className="h-5 w-5" />
            }
          </button>
          <button
            className="p-2 rounded-lg hover:bg-black text-black group-hover:text-white"
            onClick={onViewDetail}
          >
            <RiFilePaperLine className="h-5 w-5" />
          </button>
          <button
            className="p-2 rounded-lg hover:bg-black text-black group-hover:text-white"
            onClick={onEdit}
          >
            <RiEdit2Line className="h-5 w-5" />
          </button>
          <button
            className="p-2 rounded-lg hover:bg-black text-black group-hover:text-white"
            onClick={onDelete}
          >
            <BsTrash className="h-5 w-5" />
          </button>
        </div>
      </div>
    )
  }
  
  function HeaderTable() {
    return (
      <div
        className="w-full bg-white flex items-center shadow-lg divide-x divide-gray-300 border-b bg-gray-100 font-bold"
        style={{
          fontSize: "15px"
        }}
      >
        <div 
          className="flex px-2 py-2 group-hover:text-white items-center " 
          style={{ 
            width: "4%",
            minWidth: "50px" 
          }}
        >
          No
        </div>
        <div
          className="px-2 text-left flex items-center group-hover:text-white"
          style={{
            width: "12%",
            minWidth: "150px"
          }}
        >
          Kode
        </div>
        <div
          className="px-2 text-left flex items-center group-hover:text-white"
          style={{
            width: "39%",
            minWidth: "200px"
          }}
        >
          Label
        </div>
        <div 
          className="flex px-2 items-center justify-center group-hover:text-white" 
          style={{ 
            width: "10%",
            minWidth: "130px"
          }}
        >
          Nominal
        </div>
        <div 
          className="flex px-2 items-center justify-center group-hover:text-white" 
          style={{ 
            width: "10%",
            minWidth: "130px" 
          }}
        >
          Jenis
        </div>
        <div
          className="flex px-2 items-center justify-center group-hover:text-white"
          style={{ 
            width: "10%",
            minWidth: "130px" 
          }}
        >
          Status
        </div>
        <div 
          className="px-3 flex justify-center" 
          style={{ 
            width: "15%",
            minWidth: "195px" 
          }
        }>
          #
        </div>
      </div>
    )
  }
  
  function DataTable({
    isLoadData,
    datas,
    rootModel,
    setRootModel
  }) {
  
    const history = useHistory()
  
    return (
      <div className="space-y-3">
        <div className="overflow-x-auto bg-white">
          <HeaderTable />
          {
            isLoadData ?
              (<div
                className="flex items-center justify-center text-blue-600 bg-white"
                style={{ minHeight: "50px" }}
              >
                <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
              </div>)
              :
              datas.length > 0 ?
                (<div>
                  {datas.map((d, idx) => (
                    <ItemBox
                      no={idx + 1}
                      idDiskon={d.id}
                      namaDiskon={d.label}
                      nominal={d.nominal}
                      jenis={d.discountType === 'discount_by_percentage' ? 'Persentase' : 'Potongan Harga'}
                      createdAt={d.createdAt}
                      updatedAt={d.updatedAt}
                      isActive={d.isActive}
                      onSetActive={() => onSetActivation(d.id, true, rootModel, setRootModel)}
                      onSetNonActive={() => onSetActivation(d.id, false, rootModel, setRootModel)}
                      onViewDetail={() => {
                        setRootModel(openDetailFormPage(rootModel, d))
                        history.push("/home/discount/form/view/"+d.id)
                      }}
                      onEdit={() => {
                        setRootModel(openEditFormPage(rootModel, d))
                        history.push("/home/discount/form/edit/"+d.id)
                      }}
                      onDelete={() => setRootModel(openDeleteDialog(rootModel, d))}
                    />
                  ))}
                </div>)
                :
                (<div className="py-2 bg-white">
                  Tidak ada data
                </div>)
          }
        </div>
        <DataTableFooter
          pageNum={(rootModel.dataProperties.offset / rootModel.dataProperties.limit) + 1}
          showCount={rootModel.dataList.length}
          sortMode={dataPropertOrdToStr(rootModel)}
          searchText={rootModel.searchHeader.searchText}
          hasNextPage={hasNextPage(rootModel)}
          hasPrevPage={hasPrevPage(rootModel)}
          onGoToPrevPage={() => setRootModel(goToPrevPage(rootModel))}
          onGoToNextPage={() => setRootModel(goToNextPage(rootModel))}
        />
      </div>
    )
  }

  function SummaryBox({
    rootModel,
    setRootModel,
    totalData,
    totalDataAktif,
    latestInputDateTime,
    onOpenAddForm
  }) {
  
    const [headerModel, setHeaderModel] = useState({
      selectedMenu: null, //search, filter, sort,
    })
  
    const openFilterMenu = (m) => ({ ...m, selectedMenu: m.selectedMenu === 'filter' ? null : 'filter' })
    const openSortMenu = (m) => ({ ...m, selectedMenu: m.selectedMenu === 'sort' ? null : 'sort' })
    const openSearchMenu = (m) => ({ ...m, selectedMenu: m.selectedMenu === 'search' ? null : 'search' })
    const closeMenu = (m) => ({ ...m, selectedMenu: null })
  
    return (
      <>
        <Transition
          show={headerModel.selectedMenu === 'search'}
        >
          <div
            className="bg-white rounded-lg shadow-lg flex items-center"
            style={{ height: "50px" }}
          >
            <div className="p-2">
              <button
                className="h-full flex items-center p-2 rounded-lg hover:bg-blue-500 hover:text-white"
                onClick={() => setHeaderModel(closeMenu(headerModel))}
              >
                <RiArrowLeftLine className="h-6 w-6" />
              </button>
            </div>
            <div className="flex-1 h-full py-2 text-gray-700">
              <input
                autoFocus
                className="w-full h-full outline-none italic"
                onChange={e => setRootModel(setSearchText(rootModel, e.target.value))}
                value={rootModel.searchHeader.searchText}
                placeholder={"Cari kode Mapel atau nama Mapel"}
              />
            </div>
            <div className="p-2">
              <button
                className="h-full flex items-center p-2 rounded-lg hover:bg-blue-500 hover:text-white"
                onClick={() => setRootModel(resetSearchText(rootModel))}
              >
                <RiCloseFill className="h-6 w-6" />
              </button>
            </div>
          </div>
        </Transition>
        <Transition
          show={headerModel.selectedMenu !== 'search'}
        >
          <div className="">
            <div
              className="flex items-center justify-between bg-gray-200 p-3 py-7 rounded-sm"
              style={{ height: "50px" }}
            >
              <div>
                <div
                  className="text-left font-bold"
                  style={{
                    fontSize: "18px",
                    fontFamily: "Nunito"
                  }}
                >
                  Data Diskon
              </div>
              </div>
              <div
                className="flex space-x-2"
              >
                <div className="flex items-center">
                  <button
                    className={
                      "rounded-md p-2 bg-white hover:text-blue-700 text-black "
                    }
                    onClick={() => setHeaderModel(openSearchMenu(headerModel))}
                  >
                    <RiSearchLine className="h-5 w-5" />
                  </button>
                </div>
                <div className="flex items-center relative">
                  <button
                    className={
                      "rounded-md p-2 bg-white hover:bg-blue-700 hover:text-white text-black "
                    }
                    onClick={() => setHeaderModel(openSortMenu(headerModel))}
                  >
                    <MdSort className="h-5 w-5" />
                  </button>
                  <Transition
                    show={headerModel.selectedMenu === 'sort'}
                    enter="duration-350"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div
                      className="absolute top-10"
                    >
                      <div
                        className="bg-white z-10 rounded-lg shadow-lg text-left"
                        style={{
                          width: "200px",
                          marginLeft: "-200px"
                        }}
                      >
                        <div>
                          <button
                            className={
                              "w-full p-2 hover:bg-blue-300 text-left rounded-lg " +
                              (rootModel.dataProperties.ord === 'newestData' ? ' bg-blue-500 text-white ' : '')
                            }
                            onClick={() => {
                              setHeaderModel(closeMenu(headerModel))
                              setRootModel(setSortFromNewestData(rootModel))
                            }}
                          >
                            Dari terbaru
                        </button>
                        </div>
                        <div>
                          <button
                            className={
                              "w-full p-2 hover:bg-blue-300 text-left rounded-lg " +
                              (rootModel.dataProperties.ord === 'oldestData' ? ' bg-blue-500 text-white ' : '')
                            }
                            onClick={() => {
                              setHeaderModel(closeMenu(headerModel))
                              setRootModel(setSortFromOldestData(rootModel))
                            }}
                          >
                            Dari terlama
                        </button>
                        </div>
                      </div>
                    </div>
                  </Transition>
                </div>
                <button
                  className={
                    "rounded-md p-2 bg-white hover:bg-blue-700 hover:text-white text-black "
                  }
                  onClick={onOpenAddForm}
                >
                  <RiAddLine className="h-5 w-5" />
                </button>
              </div>
            </div>
            <div
              className="flex px-5 py-3 space-x-12 bg-white rounded-bl-lg rounded-br-lg"
              style={{
                fontSize: "14px"
              }}
            >
              <div className="text-left">
                <div>
                  Total Data 
              </div>
                <div>
                  {totalData}
                </div>
              </div>
              <div className="text-left">
                <div>
                  Total Data Aktif
              </div>
                <div>
                  {totalDataAktif}
                </div>
              </div>
              <div className="text-left">
                <div>
                  Tanggal terakhir input
              </div>
                <div>
                  {latestInputDateTime ? format(new Date(latestInputDateTime), "dd/MM/yyyy") : 'tidak ada data'}
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </>
    )
  }

  export default function DiskonPage() {
    const dispatch = useDispatch()
    const history = useHistory()
  
    const [rootModel, setRootModel] = useState(initModel)
  
    useEffect(() => {
      onGetDataSummary(rootModel, setRootModel, history)
      dispatch(setHomeActivePage('discount'))
    }, [])
  
    useEffect(() => {
      onGetData(rootModel, setRootModel, history)
    }, [rootModel.searchHeader.searchText, rootModel.dataProperties])
  
    useEffect(() => {
      if (rootModel.refetchData) {
        onGetData(rootModel, setRootModel, history)
      }
    }, [rootModel.refetchData])
  
    useEffect(() => {
      if (rootModel.refetchDataSummary) {
        onGetDataSummary(rootModel, setRootModel, history)
      }
    }, [rootModel.refetchDataSummary])
  
    function onDelete(idData) {
      dispatch(setIsHomeProcess(true))
  
      deleteData(idData)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
              setRootModel(setStartRefetchData(closeDeleteDialog(rootModel)))
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .catch(error => {})
        .finally(() => { dispatch(setIsHomeProcess(false)) })
    }
  
    return (
      <div className="space-y-3 w-full">
        <DeleteDataDialog
          isShow={rootModel.activeSubPage === 'delete'}
          selectedData={rootModel.deleteDialog.selectedData}
          onClose={() => setRootModel(closeDeleteDialog(rootModel))}
          onDelete={onDelete}
        />
        <SummaryBox
          rootModel={rootModel}
          setRootModel={setRootModel}
          totalData={rootModel.dataSummary.totalData}
          totalDataAktif={rootModel.dataSummary.totalDataAktif}
          latestInputDateTime={rootModel.dataSummary.latestInputDateTime}
          onOpenAddForm={() => {
            setRootModel(openAddFormPage(rootModel))
            history.push("/home/discount/form")
          }}
        />
        <DataTable
          isLoadData={rootModel.isLoadData}
          rootModel={rootModel}
          setRootModel={setRootModel}
          datas={rootModel.dataList}
        />
      </div>
    )
  }
  
  function onGetData(rootModel, setRootModel, history) {
    const payload = {
      search: rootModel.searchHeader.searchText,
      offset: rootModel.dataProperties.offset,
      limit: rootModel.dataProperties.limit,
      orderBy: rootModel.dataProperties.ord
    }
  
    setRootModel(setIsLoadData(rootModel, true))
  
    getData(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setRootModel(latestM => setData(latestM, payload))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => {
        setRootModel(latestM => setIsLoadData(setFinishRefetchData(latestM), false))
      })
  
  }
  
  function onGetDataSummary(rootModel, setRootModel, history) {
    getDataSummary()
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setRootModel(latestM => setDataSummary(latestM, payload.totalData, payload.totalDataAktif, payload.latestInput))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => {
        setRootModel(latestM => setFinishRefetchDataSummary(latestM))
      })
  }
  
  function onDeleteData(rootModel, setRootModel, history) {
    const kodeData = rootModel.deleteDialog.selectedData.id
  
    setRootModel(latestM => ({
      ...rootModel,
      deleteDialog: {
        ...rootModel.deleteDialog,
        isDeleting: true
      }
    }))
  
    deleteData(kodeData)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setRootModel(latestM =>
              setStartRefetchData(
                setStartRefetchDataSummary(
                  closeDeleteDialog(latestM)
                )
              )
            )
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => {
        setRootModel(latestM => ({
          ...latestM,
          deleteDialog: {
            ...latestM.deleteDialog,
            isDeleting: false
          }
        }))
      })
  }
  
  function onSetActivation(kodeData, isActive, rootModel, setRootModel, history) {
    const payload = {
      id: kodeData,
      isActive: isActive
    }
  
    setRootModel(setIsLoadData(rootModel, true))
  
    updateDataActivation(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setRootModel(latestM =>
              setStartRefetchData(
                setStartRefetchDataSummary(
                  latestM
                )
              )
            )
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => {
        setRootModel(latestM => setIsLoadData(latestM, false))
      })
  }