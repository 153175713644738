import { useEffect, useState } from "react"
import { RiArrowLeftLine, RiLoader4Fill, RiLoader4Line } from "react-icons/ri"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import { handleResponse200 } from "../../../utils/HttpUtils"
import { notifyError } from "../../../utils/Notification"
import { createData, getDetail, updateData } from "../Apis/Api"

export default function FormPage() {
  const history = useHistory()

  const { screenSize } = useSelector(state => state.global)

  const [model, setModel] = useState({
    form: {
      idSekolah: null,
      namaSekolah: ''
    },
    isProcess: false
  })

  const { mode, id } = useParams()

  useEffect(() => {
    if (!mode) return;

    setModel({...model, isProcess: true})
    getDataDetail(id)

  }, [])

  function getDataDetail(idData) {
    getDetail(idData)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setModel(latestM => ({ 
              ...latestM, 
              form: { 
                idSekolah: payload.idSekolah,
                namaSekolah: payload.namaSekolah,
              } 
            }))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))

  }

  function saveData() {
    setModel({ ...model, isProcess: true })

    const payload = {
      idSekolah: model.form.idSekolah,
      namaSekolah: model.form.namaSekolah
    }

    if (mode) {
      updateData(payload)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
              history.push("/home/sekolah")
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))

    }
    else {
      createData(payload)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
              history.push("/home/sekolah")
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))
    }
  }

  return (
    <div
      className="p-2 bg-white w-full shadow-xl rounded-md divide-y divide-gray-300 relative"
    >
      { model.isProcess && (
        <>
          <div
            className="absolute bg-black z-10 w-full h-full top-0 left-0 opacity-70"
          >
          </div>
          <div
            className="absolute z-20 top-0 left-0 w-full h-full flex items-center justify-center text-blue-500 "
          >
            <RiLoader4Fill className="h-12 w-12 animate-spin"/>
          </div>
        </>
      )}
      
      <div
        className="flex items-center justify-between p-2"
      >
        <div>
          <button
            onClick={() => history.push("/home/sekolah")}
          >
            <RiArrowLeftLine className="h-6 w-6"/>
          </button>
        </div>
        <div
          className="font-bold uppercase"
          style={{
            fontSize: "15px"
          }}
        >
          {
            mode ? 
              mode == 'edit' ? "update data" : "lihat data"
            :
              "Tambah data"
          }
        </div>
      </div>
      <div
        className="pt-4 pb-2 px-2 text-left space-y-3 bg-gray-100"
      >
        { mode && (
          <div
            className="space-y-2"
          >
            <div
              className="font-bold"
            >
              ID Sekolah
            </div>
            <div>
              <input
                className="w-full p-2 rounded-sm border border-gray-400"
                value={model.form.idSekolah}
                readOnly
              />
            </div>
          </div>  
        )}
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
          >
            Nama Sekolah
          </div>
          <div>
            <input
              className="w-full p-2 rounded-sm border border-gray-400"
              value={model.form.namaSekolah}
              onChange={e => setModel({ ...model, form: { ...model.form, namaSekolah: e.target.value } })}
            />
          </div>
        </div>
      </div>
      { (!mode || mode != 'view') && (
        <div
          className="pt-4 pb-2 px-2 flex items-center space-x-2"
        >
          <button
            className="px-5 py-2 bg-blue-600 text-white font-bold rounded-md"
            onClick={saveData}
          >
            Simpan
          </button>
        </div>
      )}

    </div>
  )
}