import axios from "axios"

export const getDataSummary = async () => {
  const finalUrl = window._HOST + "admin/skema-cicilan/data-summary"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/skema-cicilan/data-table"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getDataDetail = async (idSkemaCicilan) => {
  const finalUrl =
    window._HOST + "admin/skema-cicilan/detail/" + idSkemaCicilan

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const createData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/skema-cicilan"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/skema-cicilan"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const deleteData = async (kodeData) => {
  const finalUrl =
    window._HOST + "admin/skema-cicilan/" + kodeData

  const response =
    await axios.delete(finalUrl, { withCredentials: true })

  return response.data
}
