import { Dialog, Transition } from "@headlessui/react"
import { useDispatch, useSelector } from "react-redux"
import { VscChromeClose } from "react-icons/vsc"
import { useState } from "react"
import { IoMdLock, IoMdEye, IoMdEyeOff } from 'react-icons/io'
import Select from "react-select"
import { getDataExamPhaseSubgroupIdLabel } from "../Apis/Api"
import { handleResponse200 } from "../../../utils/HttpUtils"
import { notifyError } from "../../../utils/Notification"
import { useHistory } from "react-router-dom"

export default function AddExamPhaseDialog({
  idGrup,
  isShow,
  onClose,
  onAdd
}) {

  const history = useHistory()

  const { screenSize } = useSelector(state => state.global)

  const [model, setModel] = useState({
    form: {
      namaTahapUjian: '',
      durasiTahapUjian: 60,
      subgrupOpts: {
        selectedOpt: null,
        data: [],
        isLoad: false
      }
    }
  })

  function onSelectSubgrup() {
    setModel({ 
      ...model, 
      form: {
        ...model.form,
        subgrupOpts: { ...model.form.subgrupOpts, isLoad: true } }
      })

    getDataExamPhaseSubgroupIdLabel(idGrup)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setModel(latestM => ({ 
              ...latestM, 
              form: {
                ...latestM.form,
                subgrupOpts: { ...latestM.form.subgrupOpts, data: payload.map(d => ({ label: d.label, value: d.id })) } }
              }))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => {
        setModel(latestM => ({ 
          ...latestM, 
          form: {
            ...latestM.form,
            subgrupOpts: { ...latestM.form.subgrupOpts, isLoad: false } }
          }))
      })
  }

  return (
    <Transition
      appear
      show={isShow}
    >
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto"
        style={{
          zIndex: "90"
        }}
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center flex justify-center items-center">
          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-70 bg-black" />
          </Transition.Child>

          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={
                "absolute " +
                (screenSize.width > 500 ? " left-1/2 top-20 " : " left-0 top-0 ")
              }
            >
              <div
                className="py-1 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
                style={{
                  position: "absolute",
                  left: screenSize.width > 500 ? "-230px" : "0",
                  width: screenSize.width > 500 ? "460px" : screenSize.width + "px"
                }}
              >
                <Dialog.Title
                  as="h3"
                  className="px-2 text-lg flex items-center justify-between font-medium leading-6 text-gray-900 border-b border-gray-200"
                >
                  <button
                    className="p-2 hover:text-red-500 outline-none"
                    onClick={onClose}
                  >
                    <VscChromeClose className="h-6 w-6" />
                  </button>
                </Dialog.Title>
                <div
                  className="mt-4 mb-2 px-5 flex flex-col space-y-3 overflow-auto space-y-5"
                >
                  <div
                    className="space-y-3"
                  >
                    <div
                      className="space-y-2"
                    >
                      <div
                        className="font-bold"
                      >
                        Subgrup tahap ujian
                      </div>
                      <div>
                        <Select
                          value={model.form.subgrupOpts.selectedOpt}
                          options={model.form.subgrupOpts.data}
                          isLoading={model.form.subgrupOpts.isLoad}
                          onFocus={onSelectSubgrup}
                          menuPlacement="auto"
                          className="w-full border border-gray-200 rounded-md text-left"
                          onChange={(value) => {
                            setModel({
                              ...model,
                              form: {
                                ...model.form,
                                subgrupOpts: {
                                  ...model.form.subgrupOpts,
                                  selectedOpt: value
                                }
                              }
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="space-y-2"
                    >
                      <div
                        className="font-bold"
                      >
                        Nama Tahap Ujian
                      </div>
                      <div>
                        <input
                          className="w-full p-2 rounded-sm border border-gray-400"
                          value={model.form.namaTahapUjian}
                          onChange={e => setModel({ ...model, form: { ...model.form, namaTahapUjian: e.target.value } })}
                        />
                      </div>
                    </div>
                    <div
                      className="space-y-2"
                    >
                      <div
                        className="font-bold"
                      >
                        Durasi Ujian (dalam satuan menit)
                      </div>
                      <div>
                        <input
                          type={"number"}
                          className="w-full p-2 rounded-sm border border-gray-400"
                          value={model.form.durasiTahapUjian}
                          onChange={e => setModel({ ...model, form: { ...model.form, durasiTahapUjian: e.target.value } })}
                        />
                      </div>
                    </div>

                  </div>

                  <div className="py-2 flex justify-center space-x-5">
                    <button
                      className="rounded-full py-1 px-8 bg-red-500 hover:bg-red-700 text-white"
                      onClick={onClose}
                    >
                      Batal
                    </button>
                    <button
                      className="rounded-full py-1 px-5 bg-blue-500 hover:bg-blue-700 text-white"
                      onClick={() => {
                        if (model.form.namaTahapUjian === '') {
                          return notifyError("Nama tahap ujian tidak boleh kosong")
                        }
                        if (model.form.subgrupOpts.selectedOpt === null) {
                          return notifyError("Pilih salah satu subgrup tahap ujian")
                        }
                        onAdd(
                          model.form.namaTahapUjian, 
                          model.form.durasiTahapUjian, 
                          model.form.subgrupOpts.selectedOpt.value
                        ) 
                      }}
                    >
                      Simpan
                    </button>
                  </div>

                </div>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )

}