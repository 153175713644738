  import { Transition } from "@headlessui/react"

import {
  RiAddLine,
  RiArrowLeftLine,
  RiCloseFill,
  RiEdit2Line,
  RiEye2Fill,
  RiEyeCloseFill,
  RiEyeFill,
  RiFilePaperLine,
  RiLoader5Fill,
  RiSearchLine
} from "react-icons/ri";
import { MdSort } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BsChevronLeft, BsChevronRight, BsFillGridFill, BsTrash } from "react-icons/bs"

import { format } from "date-fns"
import FormPage from "./FormPage";
import DeleteDataDialog from "./DeleteDataDialog";
import { useEffect, useState } from "react";
import {
  closeDeleteDialog,
  closeFormDialog,
  dataPropertOrdToStr,
  goToNextPage,
  goToPrevPage,
  hasNextPage,
  hasPrevPage,
  initModel,
  openAddFormPage,
  openDeleteDialog,
  openDetailFormPage,
  openEditFormPage,
  resetSearchText,
  setAsalSekolahParam,
  setData,
  setDataSummary,
  setFinishRefetchData,
  setFinishRefetchDataSummary,
  setIsLoadData,
  setKelasParam,
  setProgramParam,
  setSearchText,
  setSortFromNewestData,
  setSortFromOldestData,
  setStartRefetchData,
  setStartRefetchDataSummary
} from "../Models/Model";
import {
  createData,
  deleteData,
  getData,
  getDataSummary,
  getSekolahIdLabel,
  updateData,
  updateDataActivation
} from "../Apis/Api";
import { handleResponse200 } from "../../../utils/HttpUtils"
import { useDispatch } from "react-redux";
import { setHomeActivePage } from "../../../app/GlobalSlice";
import { notifyError } from "../../../utils/Notification";
import { useHistory } from "react-router";
import { getDataKelas } from "../../Program/Apis/Api";
import { getProgramIdLabel } from "../../PaketBimbel/Apis/Api";
import Select from "react-select"

function DataTableFooter({
  pageNum,
  showCount,
  sortMode,
  searchText,
  hasNextPage,
  hasPrevPage,
  onGoToNextPage,
  onGoToPrevPage
}) {
  return (
    <div
      className="bg-white py-1 px-3 shadow-lg flex items-center justify-between rounded-md"
      style={{
        fontSize: "14px",
        height: "45px"
      }}
    >
      <div className="flex space-x-2">
        <label>
          Hal. {pageNum},
        </label>
        <label>
          Tampil: {showCount},
        </label>
        <label>
          Mode pengurutan:  {sortMode},
        </label>
        <label>
          Pencarian: {searchText && searchText !== '' ? searchText : '-'}
        </label>
      </div>
      <div className="flex">
        {hasPrevPage && (
          <button
            className=" p-2 rounded-lg hover:bg-gray-200"
            title={"Ke Halaman sebelumnya"}
            onClick={onGoToPrevPage}
          >
            <BsChevronLeft className="h-5 w-5" />
          </button>
        )}
        {hasNextPage && (
          <button
            className=" p-2 rounded-lg hover:bg-gray-200"
            title={"Ke Halaman selanjutnya"}
            onClick={onGoToNextPage}
          >
            <BsChevronRight className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  )
}

function ItemBox({
  no,
  idAnak,
  nama,
  createdAt,
  program,
  sekolah,
  isActive,
  onSetNonActive,
  onSetActive,
  onDelete,
  onViewDetail,
  onEdit,
  onManagePaketBimbel
}) {

  return (
    <div
      className="py-1 w-full bg-white group hover:bg-blue-500 flex shadow-lg divide-x divide-gray-300 border-b border-gray-200"
      style={{
        fontSize: "14px"
      }}
    >
      <div 
        className="flex px-2 group-hover:text-white items-center " 
        style={{ 
          width: "4%",
          minWidth: "50px"
        }}
      >
        {no}
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "12%",
          minWidth: "150px"
        }}
      >
        {idAnak}
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "29%",
          minWidth: "200px"
        }}
      >
        {nama}
      </div>
      <div 
        className="flex px-2 items-center justify-center group-hover:text-white" 
        style={{ 
          width: "10%",
          minWidth: "130px"
        }}
      >
        {format(new Date(createdAt), "dd/MM/yyyy")}
      </div>
      <div 
        className="flex px-2 items-center justify-center group-hover:text-white" 
        style={{ 
          width: "20%",
          minWidth: "200px"
        }}
      >
        {program}
      </div>
      <div 
        className="flex px-2 items-center justify-center group-hover:text-white" 
        style={{ 
          width: "20%",
          minWidth: "200px"
        }}
      >
        {sekolah}
      </div>
      <div
        className="flex px-2 items-center justify-center group-hover:text-white"
        style={{ 
          width: "10%",
          minWidth: "130px" 
        }}
      >
        <label
          className={
            "rounded-md px-3 text-white " +
            (isActive ? " bg-green-500 " : " bg-red-500 ")
          }
        >
          {isActive ? 'Aktif' : 'Tdk Aktif'}
        </label>
      </div>
      <div 
        className="px-3 flex justify-center" 
        style={{ 
          width: "15%",
          minWidth: "195px" 
        }}
      >
        <button
          className="p-2 rounded-lg hover:bg-black text-black group-hover:text-white"
          onClick={onManagePaketBimbel}
        >
          <BsFillGridFill className="h-5 w-5" />
        </button>
        <button
          className="p-2 rounded-lg hover:bg-black text-black group-hover:text-white"
          onClick={() => {
            isActive ?
              onSetNonActive()
              :
              onSetActive()
          }}
        >
          {
            isActive ?
              <AiOutlineEyeInvisible className="h-5 w-5" />
              :
              <AiOutlineEye className="h-5 w-5" />
          }
        </button>
        <button
          className="p-2 rounded-lg hover:bg-black text-black group-hover:text-white"
          onClick={onViewDetail}
        >
          <RiFilePaperLine className="h-5 w-5" />
        </button>
        <button
          className="p-2 rounded-lg hover:bg-black text-black group-hover:text-white"
          onClick={onEdit}
        >
          <RiEdit2Line className="h-5 w-5" />
        </button>
        <button
          className="p-2 rounded-lg hover:bg-black text-black group-hover:text-white"
          onClick={onDelete}
        >
          <BsTrash className="h-5 w-5" />
        </button>
      </div>
    </div>
  )
}

function HeaderTable() {
  return (
    <div
      className="w-full bg-white flex items-center shadow-lg divide-x divide-gray-300 border-b bg-gray-100 font-bold"
      style={{
        fontSize: "15px"
      }}
    >
      <div 
        className="flex px-2 py-2 group-hover:text-white items-center " 
        style={{ 
          width: "4%",
          minWidth: "50px" 
        }}
      >
        No
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "12%",
          minWidth: "150px"
        }}
      >
        Kode
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "29%",
          minWidth: "200px"
        }}
      >
        Nama Anak
      </div>
      <div 
        className="flex px-2 items-center justify-center group-hover:text-white" 
        style={{ 
          width: "10%",
          minWidth: "130px"
        }}
      >
        Tgl input
      </div>
      <div 
        className="flex px-2 items-center justify-center group-hover:text-white" 
        style={{ 
          width: "20%",
          minWidth: "200px" 
        }}
      >
        Program
      </div>
      <div 
        className="flex px-2 items-center justify-center group-hover:text-white" 
        style={{ 
          width: "20%",
          minWidth: "200px" 
        }}
      >
        Asal Sekolah
      </div>
      <div
        className="flex px-2 items-center justify-center group-hover:text-white"
        style={{ 
          width: "10%",
          minWidth: "130px" 
        }}
      >
        Status
      </div>
      <div 
        className="px-3 flex justify-center" 
        style={{ 
          width: "15%",
          minWidth: "195px" 
        }
      }>
        #
      </div>
    </div>
  )
}

function DataTable({
  isLoadData,
  datas,
  rootModel,
  setRootModel
}) {

  const history = useHistory()

  return (
    <div className="space-y-3">
      <div className="overflow-x-auto bg-white">
        <HeaderTable />
        {
          isLoadData ?
            (<div
              className="flex items-center justify-center text-blue-600 bg-white"
              style={{ minHeight: "50px" }}
            >
              <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
            </div>)
            :
            datas.length > 0 ?
              (<div>
                {datas.map((d, idx) => (
                  <ItemBox
                    no={idx + 1}
                    idAnak={d.idAnak}
                    nama={d.nama}
                    createdAt={d.createdAt}
                    program={d.program.label}
                    sekolah={d.sekolah ? d.sekolah.label : d.sekolahLainnya}
                    isActive={d.isActive}
                    onSetActive={() => onSetActivation(d.idAnak, true, rootModel, setRootModel)}
                    onSetNonActive={() => onSetActivation(d.idAnak, false, rootModel, setRootModel)}
                    onViewDetail={() => {
                      setRootModel(openDetailFormPage(rootModel, d))
                      history.push("/home/anak/form/view/"+d.idAnak)
                    }}
                    onEdit={() => {
                      setRootModel(openEditFormPage(rootModel, d))
                      history.push("/home/anak/form/edit/"+d.idAnak)
                    }}
                    onDelete={() => setRootModel(openDeleteDialog(rootModel, d))}
                    onManagePaketBimbel={() => history.push("/home/anak/manage-paket-bimbel/"+d.idAnak)}
                  />
                ))}
              </div>)
              :
              (<div className="py-2 bg-white">
                Tidak ada data
              </div>)
        }
      </div>
      <DataTableFooter
        pageNum={(rootModel.dataProperties.offset / rootModel.dataProperties.limit) + 1}
        showCount={rootModel.dataList.length}
        sortMode={dataPropertOrdToStr(rootModel)}
        searchText={rootModel.searchHeader.searchText}
        hasNextPage={hasNextPage(rootModel)}
        hasPrevPage={hasPrevPage(rootModel)}
        onGoToPrevPage={() => setRootModel(goToPrevPage(rootModel))}
        onGoToNextPage={() => setRootModel(goToNextPage(rootModel))}
      />
    </div>
  )
}

function SummaryBox({
  rootModel,
  setRootModel,
  totalData,
  totalDataAktif,
  latestInputDateTime,
  onOpenAddForm
}) {

  const [headerModel, setHeaderModel] = useState({
    selectedMenu: null, //search, filter, sort,
  })

  const openFilterMenu = (m) => ({ ...m, selectedMenu: m.selectedMenu === 'filter' ? null : 'filter' })
  const openSortMenu = (m) => ({ ...m, selectedMenu: m.selectedMenu === 'sort' ? null : 'sort' })
  const openSearchMenu = (m) => ({ ...m, selectedMenu: m.selectedMenu === 'search' ? null : 'search' })
  const closeMenu = (m) => ({ ...m, selectedMenu: null })

  return (
    <>
      <Transition
        show={headerModel.selectedMenu === 'search'}
      >
        <div
          className="bg-white rounded-lg shadow-lg flex items-center"
          style={{ height: "50px" }}
        >
          <div className="p-2">
            <button
              className="h-full flex items-center p-2 rounded-lg hover:bg-blue-500 hover:text-white"
              onClick={() => setHeaderModel(closeMenu(headerModel))}
            >
              <RiArrowLeftLine className="h-6 w-6" />
            </button>
          </div>
          <div className="flex-1 h-full py-2 text-gray-700">
            <input
              autoFocus
              className="w-full h-full outline-none italic"
              onChange={e => setRootModel(setSearchText(rootModel, e.target.value))}
              value={rootModel.searchHeader.searchText}
              placeholder={"Cari kode Mapel atau nama Mapel"}
            />
          </div>
          <div className="p-2">
            <button
              className="h-full flex items-center p-2 rounded-lg hover:bg-blue-500 hover:text-white"
              onClick={() => setRootModel(resetSearchText(rootModel))}
            >
              <RiCloseFill className="h-6 w-6" />
            </button>
          </div>
        </div>
      </Transition>
      <Transition
        show={headerModel.selectedMenu !== 'search'}
      >
        <div className="">
          <div
            className="flex items-center justify-between bg-gray-200 p-3 py-7 rounded-sm"
            style={{ height: "50px" }}
          >
            <div>
              <div
                className="text-left font-bold"
                style={{
                  fontSize: "18px",
                  fontFamily: "Nunito"
                }}
              >
                Data Anak
            </div>
            </div>
            <div
              className="flex space-x-2"
            >
              <div className="flex items-center">
                <button
                  className={
                    "rounded-md p-2 bg-white hover:text-blue-700 text-black "
                  }
                  onClick={() => setHeaderModel(openSearchMenu(headerModel))}
                >
                  <RiSearchLine className="h-5 w-5" />
                </button>
              </div>
              <div className="flex items-center relative">
                <button
                  className={
                    "rounded-md p-2 bg-white hover:bg-blue-700 hover:text-white text-black "
                  }
                  onClick={() => setHeaderModel(openSortMenu(headerModel))}
                >
                  <MdSort className="h-5 w-5" />
                </button>
                <Transition
                  show={headerModel.selectedMenu === 'sort'}
                  enter="duration-350"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div
                    className="absolute top-10"
                  >
                    <div
                      className="bg-white z-10 rounded-lg shadow-lg text-left"
                      style={{
                        width: "200px",
                        marginLeft: "-200px"
                      }}
                    >
                      <div>
                        <button
                          className={
                            "w-full p-2 hover:bg-blue-300 text-left rounded-lg " +
                            (rootModel.dataProperties.ord === 'newestData' ? ' bg-blue-500 text-white ' : '')
                          }
                          onClick={() => {
                            setHeaderModel(closeMenu(headerModel))
                            setRootModel(setSortFromNewestData(rootModel))
                          }}
                        >
                          Dari terbaru
                      </button>
                      </div>
                      <div>
                        <button
                          className={
                            "w-full p-2 hover:bg-blue-300 text-left rounded-lg " +
                            (rootModel.dataProperties.ord === 'oldestData' ? ' bg-blue-500 text-white ' : '')
                          }
                          onClick={() => {
                            setHeaderModel(closeMenu(headerModel))
                            setRootModel(setSortFromOldestData(rootModel))
                          }}
                        >
                          Dari terlama
                      </button>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
              <button
                className={
                  "rounded-md p-2 bg-white hover:bg-blue-700 hover:text-white text-black "
                }
                onClick={onOpenAddForm}
              >
                <RiAddLine className="h-5 w-5" />
              </button>
            </div>
          </div>
          <div
            className="flex px-5 py-3 space-x-12 bg-white rounded-bl-lg rounded-br-lg"
            style={{
              fontSize: "14px"
            }}
          >
            <div className="text-left">
              <div>
                Total Data Anak
            </div>
              <div>
                {totalData}
              </div>
            </div>
            <div className="text-left">
              <div>
                Total Data Anak Aktif
            </div>
              <div>
                {totalDataAktif}
              </div>
            </div>
            <div className="text-left">
              <div>
                Tanggal terakhir input
            </div>
              <div>
                {latestInputDateTime ? format(new Date(latestInputDateTime), "dd/MM/yyyy") : 'tidak ada data'}
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  )
}

const FilterBox = ({
  onParamChanged
}) => {
  
  const [kelasOpts, setKelasOpts] = useState({
    selectedOpt: { label: 'Semua Kelas', value: null },
    data: [],
    isLoad: false
  })

  const [programOpts, setProgramOpts] = useState({
    selectedOpt: { label: 'Semua Program', value: null },
    data: [],
    isLoad: false
  })

  const [sekolahOpts, setSekolahOpts] = useState({
    selectedOpt: { label: 'Semua Sekolah', value: null },
    data: [],
    isLoad: false
  })

  function onSelectKelasOpts() {
    setKelasOpts({ ...kelasOpts, isLoad: true })

    getDataKelas()
      .then(response => {
        if (response.success) {
          const data = [{ label: 'Semua Kelas', value: null }].concat(response.payload.map(d => ({ label: d.label, value: d.id })))
          setKelasOpts({ ...kelasOpts, data: data, isLoad: false, rawData: response.payload })
        }
        else {
          setKelasOpts({ ...kelasOpts, isLoad: false })
        }
      })
      .catch(error => {
        setKelasOpts({ ...kelasOpts, isLoad: false })
        if (error.response != undefined && error.response != null) {
          if (error.response.status == 401) {
            notifyError("Forbidden")
          }
          else {
            notifyError("Server Error")
          }
        }
      })
  }

  function onSelectProgramOpts() {
    setProgramOpts({ ...programOpts, isLoad: true })
  
    getProgramIdLabel()
      .then(response => {
        if (response.success) {
          const data = [{ label: 'Semua Program', value: null }].concat(response.payload.map(d => ({ label: d.label, value: d.id })))
          setProgramOpts({ ...programOpts, data: data, isLoad: false })
        }
        else {
          setProgramOpts({ ...programOpts, isLoad: false })
        }
      })
      .catch(error => {
        setProgramOpts({ ...programOpts, isLoad: false })
        if (error.response != undefined && error.response != null) {
          if (error.response.status == 401) {
            notifyError("Forbidden")
          }
          else {
            notifyError("Server Error")
          }
        }
      })
  }

  function onSelectSekolahOpts() {
    setSekolahOpts({ ...sekolahOpts, isLoad: true })

    getSekolahIdLabel()
      .then(response => {
        if (response.success) {
          const data = [{ label: 'Semua Sekolah', value: null }].concat(response.payload.map(d => ({ label: d.label, value: d.id })))
          setSekolahOpts({ ...sekolahOpts, data: data, isLoad: false })
        }
        else {
          setSekolahOpts({ ...sekolahOpts, isLoad: false })
        }
      })
      .catch(error => {
        setSekolahOpts({ ...sekolahOpts, isLoad: false })
        if (error.response != undefined && error.response != null) {
          if (error.response.status == 401) {
            notifyError("Forbidden")
          }
          else {
            notifyError("Server Error")
          }
        }
      })

  }

  return (
    <div className="bg-white rounded-lg shadow-lg p-2 flex items-center space-x-2">
      <div>
        <Select
          value={kelasOpts.selectedOpt}
          options={kelasOpts.data}
          isLoading={kelasOpts.isLoad}
          menuPlacement="auto"
          className="w-40 border border-gray-200 rounded-md"
          onFocus={onSelectKelasOpts}
          onChange={(value) => {
            setKelasOpts({ ...kelasOpts, selectedOpt: value })
          }}
        />
      </div>
      <div>
        <Select
          value={programOpts.selectedOpt}
          options={programOpts.data}
          isLoading={programOpts.isLoad}
          menuPlacement="auto"
          className="w-60 border border-gray-200 rounded-md text-left"
          onFocus={onSelectProgramOpts}
          onChange={(value) => {
            setProgramOpts({ ...programOpts, selectedOpt: value })
          }}
        />
      </div>
      <div>
        <Select
          value={sekolahOpts.selectedOpt}
          options={sekolahOpts.data}
          isLoading={sekolahOpts.isLoad}
          menuPlacement="auto"
          className="w-60 border border-gray-200 rounded-md text-left"
          onFocus={onSelectSekolahOpts}
          onChange={(value) => {
            setSekolahOpts({ ...sekolahOpts, selectedOpt: value })
          }}
        />
      </div>
      <div>
        <button
          className="rounded-md bg-blue-500 py-2 px-8 text-white"
          onClick={() => onParamChanged(kelasOpts.selectedOpt.value, programOpts.selectedOpt.value, sekolahOpts.selectedOpt.value)}
        >
          Aplikasikan
        </button>
      </div>
    </div>
  )

}

export default function AnakPage() {
  const dispatch = useDispatch()
  const history = useHistory()

  const [rootModel, setRootModel] = useState(initModel)

  useEffect(() => {
    onGetDataSummary(rootModel, setRootModel, history)
    dispatch(setHomeActivePage('paket_bimbel'))
  }, [])

  useEffect(() => {
    console.log(rootModel.dataProperties)
    onGetData(rootModel, setRootModel, history)
  }, [rootModel.searchHeader.searchText, rootModel.dataProperties])

  useEffect(() => {
    if (rootModel.refetchData) {
      onGetData(rootModel, setRootModel, history)
    }
  }, [rootModel.refetchData])

  useEffect(() => {
    if (rootModel.refetchDataSummary) {
      onGetDataSummary(rootModel, setRootModel, history)
    }
  }, [rootModel.refetchDataSummary])

  useEffect(() => { console.log(rootModel) }, [rootModel])

  return (
    <div className="space-y-3 w-full">
      <SummaryBox
        rootModel={rootModel}
        setRootModel={setRootModel}
        totalData={rootModel.dataSummary.totalData}
        totalDataAktif={rootModel.dataSummary.totalDataAktif}
        latestInputDateTime={rootModel.dataSummary.latestInputDateTime}
        onOpenAddForm={() => {
          setRootModel(openAddFormPage(rootModel))
          history.push("/home/Anak/form")
        }}
      />
      <FilterBox 
        onParamChanged={(kelas, program, asalSekolah) => {
          setRootModel(
            setKelasParam(
              setProgramParam(
                setAsalSekolahParam(rootModel, asalSekolah),
                program
              ), 
              kelas
            )
          )
        }}
      />
      <DataTable
        isLoadData={rootModel.isLoadData}
        rootModel={rootModel}
        setRootModel={setRootModel}
        datas={rootModel.dataList}
      />
    </div>
  )
}

function onSaveData(nama, rootModel, setRootModel, history) {

  setRootModel({
    ...rootModel,
    form: {
      ...rootModel.form,
      isSaving: true
    }
  })

  if (rootModel.form.mode === 'add') {
    const payload = {
      nama: nama
    }

    createData(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setRootModel(latestM =>
              setStartRefetchData(
                setStartRefetchDataSummary(
                  closeFormDialog(latestM)
                )
              )
            )
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => {
        setRootModel(latestM => ({
          ...latestM,
          form: {
            ...latestM.form,
            isSaving: false
          }
        }))
      })
  }
  else if (rootModel.form.mode === 'edit') {
    const payload = {
      idAnak: rootModel.form.selectedData.idAnak,
      nama: nama
    }

    updateData(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setRootModel(latestM =>
              setStartRefetchData(
                setStartRefetchDataSummary(
                  closeFormDialog(latestM)
                )
              )
            )
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => {
        setRootModel(latestM => ({
          ...latestM,
          form: {
            ...latestM.form,
            isSaving: false
          }
        }))
      })
  }
}

function onGetData(rootModel, setRootModel, history) {
  console.log('from on get data ', rootModel.dataProperties)
  const payload = {
    search: rootModel.searchHeader.searchText,
    offset: rootModel.dataProperties.offset,
    limit: rootModel.dataProperties.limit,
    orderBy: rootModel.dataProperties.ord,
    kelas: rootModel.dataProperties.kelas,
    program: rootModel.dataProperties.program,
    asalSekolah: rootModel.dataProperties.sekolah
  }

  setRootModel(setIsLoadData(rootModel, true))

  getData(payload)
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM => setData(latestM, payload))
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          history.push("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => {
      setRootModel(latestM => setIsLoadData(setFinishRefetchData(latestM), false))
    })

}

function onGetDataSummary(rootModel, setRootModel, history) {
  getDataSummary()
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM => setDataSummary(latestM, payload.totalData, payload.totalDataAktif, payload.latestInput))
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          history.push("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => {
      setRootModel(latestM => setFinishRefetchDataSummary(latestM))
    })
}

function onDeleteData(rootModel, setRootModel, history) {
  const kodeData = rootModel.deleteDialog.selectedData.idAnak

  setRootModel(latestM => ({
    ...rootModel,
    deleteDialog: {
      ...rootModel.deleteDialog,
      isDeleting: true
    }
  }))

  deleteData(kodeData)
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM =>
            setStartRefetchData(
              setStartRefetchDataSummary(
                closeDeleteDialog(latestM)
              )
            )
          )
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          history.push("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => {
      setRootModel(latestM => ({
        ...latestM,
        deleteDialog: {
          ...latestM.deleteDialog,
          isDeleting: false
        }
      }))
    })
}

function onSetActivation(kodeData, isActive, rootModel, setRootModel, history) {
  const payload = {
    idAnak: kodeData,
    isActive: isActive
  }

  setRootModel(setIsLoadData(rootModel, true))

  updateDataActivation(payload)
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM =>
            setStartRefetchData(
              setStartRefetchDataSummary(
                latestM
              )
            )
          )
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          history.push("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => {
      setRootModel(latestM => setIsLoadData(latestM, false))
    })
}