export const initModel = {
  dataProperties: {
    ord: 'newestData', //newestData, oldestData,
    offset: 0,
    limit: 1000,
    fromDate: null,
    untilDate: null,
    programId: null
  },
  searchHeader: {
    searchText: null
  },
  dataList: [],
  programs: []
}

export const setData = (model, data) => ({
  ...model,
  dataList: data
})

export const setPrograms = (model, data) => ({
  ...model,
  programs: data
})

export const setSearchText = (model, text) => ({ ...model, searchHeader: { ...model.searchHeader, searchText: text } })
export const resetSearchText = (model) => ({ ...model, searchHeader: { ...model.searchHeader, searchText: '' } })

export const setSortFromNewestData = (model) => ({ ...model, dataProperties: { ...model.dataProperties, ord: 'newestData', offset: 0 } })
export const setSortFromOldestData = (model) => ({ ...model, dataProperties: { ...model.dataProperties, ord: 'oldestData', offset: 0 } })

export const setSelectedProgram = (model, idProgram) => ({
  ...model,
  dataProperties: {
    ...model.dataProperties,
    program: idProgram
  }
})

export const setDateParam = (model, fromDateStr, untilDateStr, programId) => ({ ...model, dataProperties: { ...model.dataProperties, fromDate: fromDateStr, untilDate: untilDateStr, programId: programId } })

export const dataPropertOrdToStr = (model) => {
  if (model.dataProperties.ord === 'newestData') {
    return 'Dari data terbaru'
  }
  if (model.dataProperties.ord === 'oldestData') {
    return 'Dari data terlama'
  }

  return 'Undefined Order'

}

export const hasNextPage = (model) => {
  return (model.dataProperties.offset + model.dataProperties.limit) < model.dataSummary.totalData
}
export const hasPrevPage = (model) => {
  return model.dataProperties.offset > 0
}
export const goToNextPage = (model) => {
  if ((model.dataProperties.offset + model.dataProperties.limit) <= model.dataSummary.totalData) {
    return {
      ...model,
      dataProperties: {
        ...model.dataProperties,
        offset: model.dataProperties.offset + model.dataProperties.limit
      }
    }
  }
  return model
}
export const goToPrevPage = (model) => {
  if ((model.dataProperties.offset - model.dataProperties.limit) >= 0) {
    return {
      ...model,
      dataProperties: {
        ...model.dataProperties,
        offset: model.dataProperties.offset - model.dataProperties.limit
      }
    }
  }
  return model
}

export const resetOffset = (model) => ({ ...model, dataProperties: { ...model.dataProperties, offset: 0 } })