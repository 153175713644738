import axios from "axios"

export const getDataKelas = async () => {
  const finalUrl = window._HOST + "admin/kelas/id-label"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getDataSummary = async () => {
  const finalUrl = window._HOST + "admin/program/data-summary"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/program/data-table"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getDetail = async (id) => {
  const finalUrl =
    window._HOST + "admin/program/detail/" + id
  
  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const createData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/program"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/program"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateDataActivation = async (payload) => {
  const finalUrl =
    window._HOST + "admin/program-activation"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const deleteData = async (kodeData) => {
  const finalUrl =
    window._HOST + "admin/program/" + kodeData

  const response =
    await axios.delete(finalUrl, { withCredentials: true })

  return response.data
}