import { useEffect, useState } from "react"
import { RiArrowLeftLine, RiLoader4Fill, RiLoader4Line } from "react-icons/ri"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import { handleResponse200 } from "../../../utils/HttpUtils"
import { notifyError } from "../../../utils/Notification"
import { createData, getDataDetail, getDetail, updateData } from "../Apis/Api"
import { GrCheckbox } from "react-icons/gr"
import { AiOutlineCheck } from "react-icons/ai"

const formatRupiah = (angka) => {
  angka = angka.toString()

  var number_string = angka.replace(/[^,\d]/g, '').toString(),
  split   		      = number_string.split(','),
  sisa     		      = split[0].length % 3,
  rupiah     		    = split[0].substr(0, sisa),
  ribuan     		    = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if(ribuan){
    let separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }

  rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
  return rupiah
}

const unFormatRupiah = (angkaStr) => {
  return parseInt(angkaStr.replaceAll('.', '')) 
}

export default function FormPage() {
  const { id } = useParams()

  const history = useHistory()

  const { screenSize } = useSelector(state => state.global)

  const [form, setForm] = useState({
    idSkemaCicilan: null,
    namaSkemaCicilan: '',
    description: '',
    jmlFrekuensiCicilan: 0,
    biayaPendaftaran: 0,
    biayaPokokCicilan: 0,
    biayaPertamaBayar: 0,
    sisaFrekuensiCicilan: 0,
    isFirstPaymentReduceCicilan: false
  })

  const [isProcess, setIsProcess] = useState(false)

  useEffect(() => {
    if (!id) return;
    
    setIsProcess(true)

    getDataDetail(id)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            if (!payload) return;

            setForm({
              ...form,
              idSkemaCicilan: payload.idSkemaCicilan,
              namaSkemaCicilan: payload.namaSkemaCicilan,
              description: payload.description,
              jmlFrekuensiCicilan: payload.jmlFrekuensiCicilan,
              biayaPendaftaran: payload.biayaPendaftaran,
              biayaPokokCicilan: payload.biayaPokokCicilan,
              biayaPertamaBayar: payload.biayaPertamaBayar,
              sisaFrekuensiCicilan: payload.sisaFrekuensiCicilan,
              isFirstPaymentReduceCicilan: payload.isFirstPaymentReduceCicilan
            })
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => {})
      .finally(() => setIsProcess(false))

  }, [id])
  
  function saveData() {
    setIsProcess(true)

    const payload = {
      idSkemaCicilan: form.idSkemaCicilan,
      namaSkemaCicilan: form.namaSkemaCicilan,
      description: form.description,
      jmlFrekuensiCicilan: parseInt(form.jmlFrekuensiCicilan),
      biayaPendaftaran: parseInt(form.biayaPendaftaran),
      biayaPokokCicilan: parseInt(form.biayaPokokCicilan),
      biayaPertamaBayar: parseInt(form.biayaPertamaBayar),
      sisaFrekuensiCicilan: parseInt(form.sisaFrekuensiCicilan),
      isFirstPaymentReduceCicilan: form.isFirstPaymentReduceCicilan
    }

    if (form.idSkemaCicilan) {
      updateData(payload)
          .then(res => {
            handleResponse200({
              httpResponse: res,
              onSuccess: payload => {
                history.push("/home/skema-cicilan")
              },
              onRecovFailure: errors => errors.forEach(err => {
                notifyError(err)
              }),
              onUnAuth: error => {
                notifyError('Forbidden. Please Login')
                history.push("/login")
              },
              onTechnicalError: errors => errors.forEach(err => {
                notifyError(err)
              })
            })
          })
          .catch(err => {
            notifyError('Critical Error. Please Contact Developer.')
          })
          .finally(() => setIsProcess(false))
    }
    else {
      createData(payload)
          .then(res => {
            handleResponse200({
              httpResponse: res,
              onSuccess: payload => {
                history.push("/home/skema-cicilan")
              },
              onRecovFailure: errors => errors.forEach(err => {
                notifyError(err)
              }),
              onUnAuth: error => {
                notifyError('Forbidden. Please Login')
                history.push("/login")
              },
              onTechnicalError: errors => errors.forEach(err => {
                notifyError(err)
              })
            })
          })
          .catch(err => {
            notifyError('Critical Error. Please Contact Developer.')
          })
          .finally(() => setIsProcess(false))
    }
  }

  return (
    <div
      className="p-2 bg-white w-full shadow-xl rounded-md divide-y divide-gray-300 relative"
    >
      { isProcess && (
        <>
          <div
            className="absolute bg-black z-10 w-full h-full top-0 left-0 opacity-70"
          >
          </div>
          <div
            className="absolute z-20 top-0 left-0 w-full h-full flex items-center justify-center text-blue-500 "
          >
            <RiLoader4Fill className="h-12 w-12 animate-spin"/>
          </div>
        </>
      )}
      
      <div
        className="flex items-center justify-between p-2"
      >
        <div>
          <button
            onClick={() => history.push("/home/skema-cicilan")}
          >
            <RiArrowLeftLine className="h-6 w-6"/>
          </button>
        </div>
        <div
          className="font-bold uppercase"
          style={{
            fontSize: "15px"
          }}
        >
          Tambah Data
        </div>
      </div>
      <div
        className="pt-4 pb-2 px-2 text-left space-y-3 bg-gray-100"
      >
        {form.idSkemaCicilan && (
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
          >
            ID Skema
          </div>
          <div>
            <input
              className="w-full p-2 rounded-sm border border-gray-400"
              value={form.idSkemaCicilan}
              readOnly
            />
          </div>
        </div>  
        )}
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
          >
            Nama Skema
          </div>
          <div>
            <input
              className="w-full p-2 rounded-sm border border-gray-400"
              value={form.namaSkemaCicilan}
              onChange={e => setForm({ ...form, namaSkemaCicilan: e.target.value })}
            />
          </div>
        </div>
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
          >
            Deskripsi
          </div>
          <div>
            <textarea 
              className="w-full p-2 rounded-sm border border-gray-400"
              value={form.description}
              onChange={e => setForm({ ...form, description: e.target.value })}
              rows={5}
            />
          </div>
        </div>
        <div className="flex space-x-2">
          <div
            className="space-y-2"
          >
            <div
              className="font-bold"
            >
              Jumlah Cicilan
            </div>
            <div>
              <input
                className="w-full p-2 rounded-sm border border-gray-400"
                value={form.jmlFrekuensiCicilan}
                onChange={e => setForm({ ...form, jmlFrekuensiCicilan: e.target.value })}
              />
            </div>
          </div>
          <div
            className="space-y-2"
          >
            <div
              className="font-bold"
            >
              Biaya Pendaftaran
            </div>
            <div>
              <input
                className="w-full p-2 rounded-sm border border-gray-400"
                value={formatRupiah(form.biayaPendaftaran)}
                onChange={e => setForm({ ...form, biayaPendaftaran: unFormatRupiah(e.target.value) })}
              />
            </div>
          </div>
        </div>
        <div className="flex space-x-2">
          <div
            className="space-y-2"
          >
            <div
              className="font-bold"
            >
              Biaya Pokok Cicilan
            </div>
            <div>
              <input
                className="w-full p-2 rounded-sm border border-gray-400"
                value={formatRupiah(form.biayaPokokCicilan)}
                onChange={e => setForm({ ...form, biayaPokokCicilan: unFormatRupiah(e.target.value)})}
              />
            </div>
          </div>
          <div
            className="space-y-2"
          >
            <div
              className="font-bold"
            >
              Biaya Pertama Bayar
            </div>
            <div>
              <input
                className="w-full p-2 rounded-sm border border-gray-400"
                value={formatRupiah(form.biayaPertamaBayar)}
                onChange={e => setForm({ ...form, biayaPertamaBayar: unFormatRupiah(e.target.value)})}
              />
            </div>
          </div>
        </div>
        <div className="flex space-x-2">
          <div
            className="space-y-2"
          >
            <div
              className="font-bold"
            >
              Sisa Jumlah Cicilan
            </div>
            <div>
              <input
                className="w-full p-2 rounded-sm border border-gray-400"
                value={form.sisaFrekuensiCicilan}
                onChange={e => setForm({ ...form, sisaFrekuensiCicilan: e.target.value })}
              />
            </div>
          </div>
        </div>
        <div className="flex space-x-2 pt-5 pb-3">
          <div
            className="flex items-center space-x-3 font-bold"
          >
            <button
              onClick={() => setForm({ 
                ...form, 
                isFirstPaymentReduceCicilan: !form.isFirstPaymentReduceCicilan 
              })}
            >
              {
                  form.isFirstPaymentReduceCicilan ?
                    <AiOutlineCheck className="bg-green-500 text-white h-5 w-5"/> 
                    :
                    <GrCheckbox className="h-5 w-5"/> 
              }
            </button>
            <label>Pembayaran Pertama Mengurangi Cicilan</label>
          </div>
        </div>
        
      </div>
      <div
        className="pt-4 pb-2 px-2 flex items-center space-x-2"
      >
        <button
          className="px-5 py-2 bg-blue-600 text-white font-bold rounded-md"
          onClick={saveData}
        >
          Simpan
        </button>
      </div>

    </div>
  )


}