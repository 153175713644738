import { Transition } from "@headlessui/react"

import {
  RiLoader5Fill
} from "react-icons/ri";
import { ImArrowDown, ImArrowUp } from "react-icons/im"
import { format } from "date-fns"
import { useEffect, useState } from "react";
import {
  addDataExamPhase,
  addDataExamPhaseSubgroup,
  deleteDataExamPhase, 
  deleteDataExamPhaseSubgroup, 
  getDataExamPhase,
  getDataExamPhaseSubgroup,
  setExamPhaseSubgroupPriority,
  updateDataSubgroup,
} from "../Apis/Api";
import { handleResponse200 } from "../../../utils/HttpUtils"
import { useDispatch } from "react-redux";
import { setIsHomeProcess } from "../../../app/GlobalSlice";
import { notifyError } from "../../../utils/Notification";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import AddExamPhaseDialog from "./AddExamPhaseDialog";
import ExamSubGroupFormDialog from "./ExamSubGroupFormDialog";

function ItemBox({
  no,
  isLast,
  idSubgrup,
  namaSubgrup,
  durasiTahapUjian,
  createdAt,
  onEdit,
  onRemove,
  onGoUp,
  onGoDown
}) {

  return (
    <div
      className="py-1 w-full bg-white hover:bg-blue-100 flex shadow-lg divide-x divide-gray-300 border-b border-gray-200"
      style={{
        fontSize: "14px"
      }}
    >
      <div 
        className="flex px-2 group-hover:text-white items-center " 
        style={{ 
          width: "4%",
          minWidth: "50px"
        }}
      >
        {no}
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "59%",
          minWidth: "200px"
        }}
      >
        {namaSubgrup}
      </div>
      <div 
        className="px-3 flex justify-center space-x-2" 
        style={{ 
          width: "37%",
          minWidth: "195px" 
        }}
      >
        <button
          className={
            "py-1 px-5 rounded-sm text-white  hover:shadow-lg " +
            (no === 1 ? " bg-gray-300 " : " bg-blue-500 hover:bg-blue-900 ")
          }
          title="Naikan urutan"
          onClick={onGoUp}
          disabled={no === 1}
        >
          <ImArrowUp />
        </button>
        <button
          className={
            "py-1 px-5 rounded-sm text-white hover:shadow-lg "+
            (isLast ? " bg-gray-300 " : " bg-red-500 hover:bg-red-900 ")
          }
          title="Turunkan prioritas"
          onClick={onGoDown}
          disabled={isLast}
        >
          <ImArrowDown />
        </button>
        <button
          className="p-1 px-3 rounded-lg bg-blue-500 text-white hover:bg-black text-black group-hover:text-white"
          onClick={onEdit}
        >
          Update
        </button>
        <button
          className="p-1 px-3 rounded-lg bg-blue-500 text-white hover:bg-black text-black group-hover:text-white"
          onClick={onRemove}
        >
          Hapus
        </button>
      </div>
    </div>
  )
}

function HeaderTable() {
  return (
    <div
      className="w-full bg-white flex items-center shadow-lg divide-x divide-gray-300 border-b bg-gray-100 font-bold"
      style={{
        fontSize: "15px"
      }}
    >
      <div 
        className="flex px-2 py-2 group-hover:text-white items-center " 
        style={{ 
          width: "4%",
          minWidth: "50px" 
        }}
      >
        No
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "59%",
          minWidth: "200px"
        }}
      >
        Subgrup Tahap Ujian
      </div>
      <div 
        className="px-3 flex justify-center" 
        style={{ 
          width: "37%",
          minWidth: "195px" 
        }
      }>
        #
      </div>
    </div>
  )
}

function DataTable({
  isLoadData,
  datas,
  rootModel,
  setRootModel,
  idGrupTahapUjian
}) {

  const history = useHistory()
  const dispatch = useDispatch()

  return (
    <div className="space-y-3">
      <div className="overflow-x-auto bg-white">
        <HeaderTable />
        {
          isLoadData ?
            (<div
              className="flex items-center justify-center text-blue-600 bg-white"
              style={{ minHeight: "50px" }}
            >
              <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
            </div>)
            :
            datas.length > 0 ?
              (<div>
                {datas.map((d, idx) => (
                  <ItemBox
                    no={idx + 1}
                    isLast={idx === (datas.length - 1)}
                    idSubgrup={d.idExamPhaseSubGroup}
                    namaSubgrup={d.namaExamPhaseSubGroup}
                    createdAt={d.createdAt}
                    onEdit={() => setRootModel({ ...rootModel, addDialog: { ...rootModel.addDialog, isOpen: true, selectedData: d }  })}
                    onRemove={() => onRemoveData(rootModel, setRootModel, history, d.idExamPhaseSubGroup)}
                    onGoUp={() => 
                      onSetPriority(
                        rootModel, 
                        setRootModel, 
                        history, 
                        d.idExamPhaseSubGroup, 
                        (idx + 1), 
                        (idx + 1) - 1,
                        () => dispatch(setIsHomeProcess(true)),
                        () => dispatch(setIsHomeProcess(false))
                      )
                    }
                    onGoDown={() => 
                      onSetPriority(
                        rootModel, 
                        setRootModel, 
                        history, 
                        d.idExamPhaseSubGroup, 
                        (idx + 1), 
                        (idx + 1) + 1,
                        () => dispatch(setIsHomeProcess(true)),
                        () => dispatch(setIsHomeProcess(false))
                      )
                    }
                  />
                ))}
              </div>)
              :
              (<div className="py-2 bg-white">
                Tidak ada data
              </div>)
        }
      </div>
    </div>
  )
}

export default function AddedExamPhaseSubgroupTable() {

  const dispatch = useDispatch()
  const history = useHistory()

  const { id } = useParams()

  const [rootModel, setRootModel] = useState({
    isLoad: false,
    data: [],
    refetchData: true,
    addDialog: {
      isOpen: false,
      selectedData: null
    }
  })

  useEffect(() => {
    // dispatch(setHomeActivePage('paket_bimbel'))
  }, [])

  useEffect(() => {
    if (rootModel.refetchData) {
      onGetData(rootModel, setRootModel, history, id)
    }
  }, [rootModel.refetchData])

  return (
    <div className="space-y-2 w-full pb-3">
      { rootModel.addDialog.isOpen && (
        <ExamSubGroupFormDialog 
          isShow={rootModel.addDialog.isOpen}
          onClose={() => setRootModel({ ...rootModel, addDialog: { ...rootModel.addDialog, isOpen: false, selectedData: null }  })}
          isEdit={rootModel.addDialog.selectedData !== null}
          selectedData={rootModel.addDialog.selectedData}
          onSave={(idSubgroup, namaSubgroup) => {
            onAddData(
              rootModel, 
              setRootModel, 
              history, 
              idSubgroup,
              namaSubgroup, 
              id,
              () => dispatch(setIsHomeProcess(true)),
              () => dispatch(setIsHomeProcess(false))
            )
          }}
        />
      )}
      <div
        className="flex justify-end p-2 pr-3"
      >
        <button
          className="rounded-md bg-blue-500 text-white hover:bg-blue-700 hover:shadow-lg p-2 px-4"
          onClick={() => setRootModel({ ...rootModel, addDialog: { ...rootModel.addDialog, isOpen: true }  })}
        >
          Tambah data
        </button>
      </div>
      <DataTable
        isLoadData={rootModel.isLoad}
        rootModel={rootModel}
        setRootModel={setRootModel}
        datas={rootModel.data}
        idGrupTahapUjian={id}
      />
    </div>
  )
}

function onGetData(rootModel, setRootModel, history, idGrupTahapUjian) {
  setRootModel({ ...rootModel, isLoad: true })

  getDataExamPhaseSubgroup(idGrupTahapUjian)
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM => ({ ...latestM, data: payload }))
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => {})
    .finally(() => setRootModel(latestM => ({ ...latestM, isLoad: false, refetchData: false })))

}

function onAddData(
  rootModel, 
  setRootModel, 
  history,
  idSubgroup,
  namaSubgroup,
  idGrupTahapUjian,
  onLoadScreen,
  onRemoveLoadScreen 
) {

  onLoadScreen()

  if (idSubgroup) {
    const payload = {
      idPhaseExamSubGroup : idSubgroup,
      namaPhaseExamSubGroup : namaSubgroup
    }

    updateDataSubgroup(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setRootModel(latestM => ({ ...latestM, refetchData: true, addDialog: { ...latestM.addDialog, isOpen: false } }))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => {})
      .finally(() => onRemoveLoadScreen())
  }
  else {
    const payload = {
      idPhaseExamSubGroup : idSubgroup,
      namaExamPhaseSubGroup : namaSubgroup,
      idGrupTahapUjian : idGrupTahapUjian
    }

    addDataExamPhaseSubgroup(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setRootModel(latestM => ({ ...latestM, refetchData: true, addDialog: { ...latestM.addDialog, isOpen: false } }))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => {})
      .finally(() => onRemoveLoadScreen())

  }

}

function onSetPriority(
  rootModel, 
  setRootModel, 
  history,
  idSubgroup,
  fromIndex,
  toIndex,
  onLoadScreen,
  onRemoveLoadScreen 
) {
  
  onLoadScreen()

  const payload = {
    idExamPhaseSubGroup : idSubgroup,
    from : fromIndex,
    into : toIndex 
  }

  setExamPhaseSubgroupPriority(payload)
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM => ({ ...latestM, refetchData: true }))
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => {})
    .finally(onRemoveLoadScreen())

}

function onRemoveData(
  rootModel, 
  setRootModel, 
  history, 
  idSubgrup
) {
  setRootModel({ ...rootModel, isLoad: true })

  deleteDataExamPhaseSubgroup(idSubgrup)
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM => ({ ...latestM, refetchData: true }))
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => {})
    .finally(() => setRootModel(latestM => ({ ...latestM, isLoad: false })))

}