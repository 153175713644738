import { useEffect, useState } from "react"
import { RiArrowLeftLine, RiLoader4Fill, RiLoader4Line } from "react-icons/ri"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import { handleResponse200 } from "../../../utils/HttpUtils"
import { notifyError } from "../../../utils/Notification"
import { createData, getDetail, getGrupTahapUjian, updateData } from "../Apis/Api"
import { GrCheckbox, GrCheckboxSelected } from "react-icons/gr"
import Select from "react-select"

export default function FormPage() {
  const history = useHistory()

  const { screenSize } = useSelector(state => state.global)

  const [model, setModel] = useState({
    form: {
      idPaketSoal: '',
      namaPaketSoal: '',
      description: '',
      durasiUjian: 60,
      isMultipleTest: false,
      idGrupTahapUjian: null
    },
    isProcess: false
  })

  const [examGroupOpts, setExamGroupOpts] = useState({
    selectedOpt: {
      label: null,
      value: null
    },
    data: [],
    isLoad: false
  })

  useEffect(() => {
    setModel({ ...model, form: { ...model.form, durasiUjian: 60 } })
    setExamGroupOpts({ ...examGroupOpts, selectedOpt: { label: null, value: null } })
  }, [model.form.isMultipleTest])

  function onSelectExamPhaseGroup() {

    setExamGroupOpts({ ...examGroupOpts, isLoad: true })
    
    getGrupTahapUjian()
      .then(response => {
        if (response.success) {
          const data = response.payload.map(d => ({ label: d.label, value: d.id }))
          setExamGroupOpts({ ...examGroupOpts, data: data, isLoad: false })
        }
        else {
          setExamGroupOpts({ ...examGroupOpts, isLoad: false })
        }
      })
      .catch(error => {
        setExamGroupOpts({ ...examGroupOpts, isLoad: false })
        if (error.response != undefined && error.response != null) {
          if (error.response.status == 401) {
            notifyError('Request Forbidden. Silahkan login')
            history.push("/login")
          }
          else {
            notifyError('Server Error')
          }
        }
      })
  }

  const { mode, id } = useParams()

  useEffect(() => {
    if (!mode) return;

    setModel({...model, isProcess: true})
    getDataDetail(id)

  }, [])

  function getDataDetail(idData) {
    getDetail(idData)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setModel(latestM => ({ 
              ...latestM, 
              form: { 
                idPaketSoal: payload.idPaketSoal,
                namaPaketSoal: payload.namaPaketSoal,
                description: payload.description,
                isMultipleTest: payload.isMultipleTest,
                durasiUjian: payload.isMultipleTest ? 60 : payload.durasiUjian
              } 
            }))

            if (payload.isMultipleTest) {
              setExamGroupOpts({ ...examGroupOpts, selectedOpt: { ...examGroupOpts.selectedOpt, value: payload.idGrupTahapUjian, label: payload.namaGrupTahapUjian } })
            }
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))

  }

  function saveData() {
    setModel({ ...model, isProcess: true })

    const payload = {
      idPaketSoal: model.form.idPaketSoal,
      namaPaketSoal: model.form.namaPaketSoal,
      description: model.form.description,
      durasiUjian: model.form.isMultipleTest ? null : parseInt(model.form.durasiUjian),
      isMultipleTest: model.form.isMultipleTest,
      idGrupTahapUjian: model.form.isMultipleTest ? examGroupOpts.selectedOpt.value : null
    }

    if (mode) {
      updateData(payload)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
              history.push("/home/paket-soal")
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))

    }
    else {
      createData(payload)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
              history.push("/home/paket-soal")
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))
    }
  }


  return (
    <div
      className="p-2 bg-white w-full shadow-xl rounded-md divide-y divide-gray-300 relative"
    >
      { model.isProcess && (
        <>
          <div
            className="absolute bg-black z-10 w-full h-full top-0 left-0 opacity-70"
          >
          </div>
          <div
            className="absolute z-20 top-0 left-0 w-full h-full flex items-center justify-center text-blue-500 "
          >
            <RiLoader4Fill className="h-12 w-12 animate-spin"/>
          </div>
        </>
      )}
      
      <div
        className="flex items-center justify-between p-2"
      >
        <div>
          <button
            onClick={() => history.push("/home/paket-soal")}
          >
            <RiArrowLeftLine className="h-6 w-6"/>
          </button>
        </div>
        <div
          className="font-bold uppercase"
          style={{
            fontSize: "15px"
          }}
        >
          {
            mode ? 
              mode == 'edit' ? "update data" : "lihat data"
            :
              "Tambah data"
          }
        </div>
      </div>
      <div
        className="pt-4 pb-2 px-2 text-left space-y-3 bg-gray-100"
      >
        { mode && (
          <div
            className="space-y-2"
          >
            <div
              className="font-bold"
            >
              ID Paket Soal
            </div>
            <div>
              <input
                className="w-full p-2 rounded-sm border border-gray-400"
                value={model.form.idPaketSoal}
                readOnly
              />
            </div>
          </div>  
        )}
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
          >
            Nama Paket Soal
          </div>
          <div>
            <input
              className="w-full p-2 rounded-sm border border-gray-400"
              value={model.form.namaPaketSoal}
              onChange={e => setModel({ ...model, form: { ...model.form, namaPaketSoal: e.target.value } })}
              readOnly={mode && mode === 'view'}
            />
          </div>
        </div>
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
          >
            Deskripsi Paket Soal
          </div>
          <div>
            <textarea
              className="w-full p-2 rounded-sm border border-gray-400"
              value={model.form.description}
              onChange={e => setModel({ ...model, form: { ...model.form, description: e.target.value } })}
              rows={7}
              readOnly={mode && mode === 'view'}
            />
          </div>
        </div>
        <div
          className="space-y-1"
        >
          <div
            className="flex items-center space-x-4"
          >
            <button
              onClick={() => setModel({ ...model, form: { ...model.form, isMultipleTest: !model.form.isMultipleTest } })}
              disabled={mode && mode === 'view'}
            >
              { model.form.isMultipleTest ?
                (<GrCheckbox className="h-5 w-5"/>)
                :
                (<GrCheckboxSelected className="h-5 w-5 bg-green-100" />) 
              }
            </button>
            <label>
              Single Test
            </label>
          </div>
          <div
            className="flex flex items-center space-x-4"
          >
            <button
              onClick={() => setModel({ ...model, form: { ...model.form, isMultipleTest: !model.form.isMultipleTest } })}
              disabled={mode && mode === 'view'}
            >
              { model.form.isMultipleTest ?
                (<GrCheckboxSelected className="h-5 w-5 bg-green-100" />) 
                :
                (<GrCheckbox className="h-5 w-5"/>)
              }
            </button>
            <label>
              Multiple Test
            </label>
          </div>
        </div>
        {
          model.form.isMultipleTest ?
            (
              <div
                className="space-y-2"
              >
                <div
                  className="font-bold"
                >
                  Grup Tahap Ujian
                </div>
                <div>
                  <Select
                    value={examGroupOpts.selectedOpt}
                    options={examGroupOpts.data}
                    isLoading={examGroupOpts.isLoad}
                    menuPlacement="auto"
                    className="w-full border border-gray-200 rounded-md"
                    onFocus={onSelectExamPhaseGroup}
                    onChange={(value) => {
                      setExamGroupOpts({ ...examGroupOpts, selectedOpt: value })
                    }}
                  />
                </div>
              </div>
            )
            :
            (
              <div
                className="space-y-2"
              >
                <div
                  className="font-bold"
                >
                  Durasi Ujian (dalam satuan menit)
                </div>
                <div>
                  <input
                    type={"number"}
                    className="w-full p-2 rounded-sm border border-gray-400"
                    value={model.form.durasiUjian}
                    onChange={e => setModel({ ...model, form: { ...model.form, durasiUjian: e.target.value } })}
                  />
                </div>
              </div>
            )
        }
      </div>
      { (!mode || mode != 'view') && (
        <div
          className="pt-4 pb-2 px-2 flex items-center space-x-2"
        >
          <button
            className="px-5 py-2 bg-blue-600 text-white font-bold rounded-md"
            onClick={saveData}
          >
            Simpan
          </button>
        </div>
      )}

    </div>
  )
}