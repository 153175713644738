import { RiLoader5Fill } from "react-icons/ri"

import { useDispatch, useSelector } from "react-redux"
import { Route } from "react-router-dom"
import { Switch } from "react-router-dom"
import { useHistory } from "react-router-dom"
import Sidebar from "../../../shared/Sidebar"
import Topbar from "../../../shared/Topbar"

import PaketBimbelPage from "../../PaketBimbel/Pages/PaketBimbelPage"
import PaketBimbelFormPage from "../../PaketBimbel/Pages/FormPage"
import ManagePaketSoalPage from "../../PaketBimbel/Pages/ManagePaketSoalPage"

import PaketSoalPage from "../../PaketSoal/Pages/PaketSoalPage"
import PaketSoalFormPage from "../../PaketSoal/Pages/FormPage"
import ExamQuestionForm from "../../PaketSoal/Pages/ExamQuestionForm"

import MemberPage from "../../Member/Pages/MemberPage"
import MemberFormPage from "../../Member/Pages/FormPage"

import AnakPage from "../../Anak/Pages/AnakPage"
import AnakFormPage from "../../Anak/Pages/FormPage"
import ManagePaketBimbelPage from "../../Anak/Pages/ManagePaketBimbelPage"

import AdminPage from "../../Admin/Pages/AdminPage"
import AdminFormPage from "../../Admin/Pages/FormPage"
import ManageAdminAccess from "../../Admin/Pages/ManageAdminAccess"

import ProgramPage from "../../Program/Pages/ProgramPage"
import ProgramFormPage from "../../Program/Pages/FormPage"

import ExamPhasePage from "../../ExamPhase/Pages/ExamPhasePage"
import ExamFormPage from "../../ExamPhase/Pages/FormPage"

import DiskonPage from "../../Diskon/Pages/DiskonPage"
import DiscountFormPage from "../../Diskon/Pages/FormPage"

import KelasFormPage from "../../Kelas/Pages/FormPage"
import KelasPage from "../../Kelas/Pages/KelasPage"

import SekolahFormPage from "../../Sekolah/Pages/FormPage"
import SekolahPage from "../../Sekolah/Pages/KelasPage"

import SkemaCicilanFormPage from "../../SkemaCicilan/Pages/FormPage"
import SkemaCicilanPage from "../../SkemaCicilan/Pages/SkemaCicilanPage"

import Dashboard from "../../Dashboard/Pages/Dashboard"
import { UnderConstruction } from "../../Dashboard/Pages/Dashboard"
import ChangePasswordDialog from "../../../shared/ChangePasswordDialog"
import { closeChangePasswordDialog, setIsHomeProcess } from "../../../app/GlobalSlice"
import { changePassword } from "../Apis/Api"
import { handleResponse200 } from "../../../utils/HttpUtils"
import { notifyError, notifySuccess } from "../../../utils/Notification"
import ManageExamPhasePage from "../../ExamPhase/Pages/ManageExamPhasePage"
import SelectExamPhasePage from "../../PaketSoal/Pages/SelectExamPhasePage"
import TransactionPage from "../../Transaction/Pages/TransactionPage"
import UjianPage from "../../Ujian/Pages/UjianPage"
import UjianDetailPage from "../../Ujian/Pages/UjianDetailPage"
import CicilanPage from "../../Cicilan/Pages/CicilanPage"
import CicilanDetailPage from "../../Cicilan/Pages/CicilanDetailPage"


export default function HomePage() {
  const dispatch  = useDispatch()
  const history   = useHistory()

  const sidebarWidth = 250

  const {
    screenSize,
    isHomeProcess,
    isShowSidebar,
    changePasswordDialog
  } = useSelector(state => state.global)

  function onChangePassword(oldPassword, newPassword) {
    const payload = {
      oldPassword: oldPassword,
      newPassword: newPassword
    }

    dispatch(setIsHomeProcess(true))

    changePassword(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: _ => {
            dispatch(closeChangePasswordDialog())
            notifySuccess("Berhasil ubah password")
          },
          onRecovFailure: errors => {
            errors.forEach(err => {
              notifyError(err)
            });
          },
          onUnAuth: () => {
            console.log("Token not found")
          },
          onTechnicalError: () => { }
        })
      })
      .catch(error => {})
      .finally(() => dispatch(setIsHomeProcess(false)))
  }

  return (
    <>
      { isHomeProcess && (
        <>
          <div
            className="absolute top-0 left-0 h-full w-full bg-black opacity-50"
            style={{
              zIndex: 998
            }}
          >
          </div>
          <div
            className="absolute h-full w-full flex items-center justify-center text-blue-700"
            style={{
              zIndex: 999
            }}
          >
            <RiLoader5Fill className={"h-16 w-16 animate-spin"} />
          </div>
        </>
      )}
      <div
        className="w-full"
      >
        {changePasswordDialog.isOpen && (
          <ChangePasswordDialog
            isShow={true}
            onClose={() => dispatch(closeChangePasswordDialog())}
            onChangePassword={onChangePassword}
        />
        )}

        <Topbar />
        { isShowSidebar && <Sidebar />}
        <div
          className="w-full pb-10 flex px-5"
          style={{
            paddingTop: "10px",
            paddingLeft: isShowSidebar ? (sidebarWidth + 20) +"px" : "1.25rem"
            // width: (screenSize.width - sidebarWidth) + "px"
          }}
        >
          <Switch>
            <Route path={"/home/grup-tahap-ujian/kelola-tahap-ujian/:id"}>
              <ManageExamPhasePage />
            </Route>
            <Route path={"/home/grup-tahap-ujian/form/:mode/:id"}>
              <ExamFormPage />
            </Route>
            <Route path={"/home/grup-tahap-ujian/form"}>
              <ExamFormPage />
            </Route>
            <Route path={"/home/grup-tahap-ujian"}>
              <ExamPhasePage />
            </Route>

            <Route path={"/home/admin/access/:id"}>
              <ManageAdminAccess />
            </Route>
            <Route path={"/home/admin/form/:mode/:id"}>
              <AdminFormPage />
            </Route>
            <Route path={"/home/admin/form"}>
              <AdminFormPage />
            </Route>
            <Route path={"/home/admin"}>
              <AdminPage />
            </Route>
            
            <Route path={"/home/anak/manage-paket-bimbel/:id"}>
              <ManagePaketBimbelPage />
            </Route>
            <Route path={"/home/anak/form/:mode/:id"}>
              <AnakFormPage />
            </Route>
            <Route path={"/home/anak/form"}>
              <AnakFormPage />
            </Route>
            <Route path={"/home/anak"}>
              <AnakPage />
            </Route>


            <Route path={"/home/skema-cicilan/form/:id"}>
              <SkemaCicilanFormPage />
            </Route>
            <Route path={"/home/skema-cicilan/form"}>
              <SkemaCicilanFormPage />
            </Route>
            <Route path={"/home/skema-cicilan"}>
              <SkemaCicilanPage />
            </Route>

            <Route path={"/home/discount/form/:mode/:id"}>
              <DiscountFormPage />
            </Route>
            <Route path={"/home/discount/form"}>
              <DiscountFormPage />
            </Route>
            <Route path={"/home/discount"}>
              <DiskonPage />
            </Route>

            <Route path={"/home/orangtua/form/:mode/:id"}>
              <MemberFormPage />
            </Route>
            <Route path={"/home/orangtua/form"}>
              <MemberFormPage />
            </Route>
            <Route path={"/home/orangtua"}>
              <MemberPage />
            </Route>

            <Route path={"/home/paket-soal/exam-phase/:id"}>
              <SelectExamPhasePage />
            </Route>
            <Route path={"/home/paket-soal/manage-soal/:id/:idTahapUjian"}>
              <ExamQuestionForm />
            </Route>
            <Route path={"/home/paket-soal/manage-soal/:id"}>
              <ExamQuestionForm />
            </Route>
            <Route path={"/home/paket-soal/form/:mode/:id"}>
              <PaketSoalFormPage />
            </Route>
            <Route path={"/home/paket-soal/form"}>
              <PaketSoalFormPage />
            </Route>
            <Route path={"/home/paket-soal"}>
              <PaketSoalPage />
            </Route>

            <Route path={"/home/kelas/form/:mode/:id"}>
              <KelasFormPage />
            </Route>
            <Route path={"/home/kelas/form"}>
              <KelasFormPage />
            </Route>
            <Route path={"/home/kelas"}>
              <KelasPage />
            </Route>

            <Route path={"/home/sekolah/form/:mode/:id"}>
              <SekolahFormPage />
            </Route>
            <Route path={"/home/sekolah/form"}>
              <SekolahFormPage />
            </Route>
            <Route path={"/home/sekolah"}>
              <SekolahPage />
            </Route>

            <Route path={"/home/program/form/:mode/:id"}>
              <ProgramFormPage />
            </Route>
            <Route path={"/home/program/form"}>
              <ProgramFormPage />
            </Route>
            <Route path={"/home/program"}>
              <ProgramPage />
            </Route>

            <Route path={"/home/paket-bimbel/manage-paket-soal/:id"}>
              <ManagePaketSoalPage />
            </Route>
            <Route path={"/home/paket-bimbel/form/:mode/:id"}>
              <PaketBimbelFormPage />
            </Route>
            <Route path={"/home/paket-bimbel/form"}>
              <PaketBimbelFormPage />
            </Route>
            <Route path={"/home/paket-bimbel"}>
              <PaketBimbelPage />
            </Route>
            
            <Route path={"/home/cicilan/detail/:id"}>
              <CicilanDetailPage />
            </Route>
            <Route path={"/home/cicilan"}>
              <CicilanPage />
            </Route>

            <Route path={"/home/transaksi"}>
              <TransactionPage />
            </Route>

            <Route path={"/home/ujian"}>
              <UjianPage />
            </Route>
            <Route path={"/home/ujian-detail/:idUjian"}>
              <UjianDetailPage />
            </Route>

            <Route path={"/home/fitur"}>
              <UnderConstruction />
            </Route>

            <Route path={"/home"}>
              <UnderConstruction />
            </Route>
          </Switch>
        </div>

      </div>
    </>
  )
}