import axios from "axios"

export const changePassword = async (payload) => {
  const finalUrl =
    window._HOST + "admin/change-password"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}