import axios from "axios"

export const getDataSummary = async () => {
  const finalUrl = window._HOST + "admin/admin/data-summary"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/admin/data-table"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getIdLabel = async (payload) => {
  const finalUrl =
    window._HOST + "admin/admin/id-label"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const getDetail = async (id) => {
  const finalUrl =
    window._HOST + "admin/admin/detail/" + id
  
  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const createData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/admin-registration"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/admin"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateDataActivation = async (payload) => {
  const finalUrl =
    window._HOST + "admin/admin-activation"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const deleteData = async (kodeData) => {
  const finalUrl =
    window._HOST + "admin/admin/" + kodeData

  const response =
    await axios.delete(finalUrl, { withCredentials: true })

  return response.data
}

export const getAdminAccess = async (kodeData) => {
  const finalUrl =
    window._HOST + "admin/admin-access/" + kodeData

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const addAdminAccess = async (payload) => {
  const finalUrl =
    window._HOST + "admin/admin-access/"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}