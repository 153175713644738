import './App.css'

import LoginPage from "./pages/Login/Pages/LoginPage"
import HomePage from "./pages/Home/Pages/HomePage"

import { Toaster } from 'react-hot-toast'
import {
  Switch,
  Route,
  useHistory,
  useLocation
} from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { RiLoader5Fill } from 'react-icons/ri'
import React, { useEffect, useState } from 'react'
import { setScreenSize, isLoggedIn, resetAdmin, setAdmin } from './app/GlobalSlice'
import { getSessionProfile } from './Apis/AdminApi'
import { handleResponse200 } from './utils/HttpUtils'
import { notifyError } from './utils/Notification'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function Latex(props) {
  let node = React.createRef();
  useEffect(() => {
    renderMath();
  });
  const renderMath = () => {
    window.MathJax.Hub.Queue([
      "Typeset",
      window.MathJax.Hub,
      node.current
    ]);
  }
  return (
    <div ref={node}>
      {props.children}
    </div>
  );
}


function App() {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()

  const { admin } = useSelector(state => state.global)

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  const [isFetchingCred, setIsFetchCred] = useState(false)

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  useEffect(() => {
    checkSession({
      onAuthenticated: payload => {
        history.push(location.pathname)
        dispatch(setAdmin({ kode: payload.kodeAdmin, nama: payload.namaAdmin }))
      },
      onUnAuthenticated: _ => dispatch(resetAdmin()),
      onError: err => console.log('error ', err)
    })
  }, [])


  // useEffect(() => {
  //   getSessionProfile()
  //     .then(res => {
  //       handleResponse200({
  //         httpResponse: res,
  //         onSuccess: cred => {
  //           const payload = {
  //             kodeAdmin : cred.profile.idAdmin,
  //             namaAdmin : cred.profile.namaAdmin,
  //             email : cred.profile.email,
  //             accesses : cred.accesses
  //           }
  //           dispatch(setAdmin(payload))
  //         },
  //         onRecovFailure: errors => errors.forEach(err => {
  //           notifyError(err)
  //         }),
  //         onUnAuth: error => {
  //           notifyError('Forbidden. Please Login')
  //           history.push("/login")
  //         },
  //         onTechnicalError: errors => errors.forEach(err => {
  //           notifyError(err)
  //         })
  //       })
  //     })
  //     .catch(err => {

  //     })
      
  // }, [])

  useEffect(() => {
    dispatch(setScreenSize(windowDimensions))
  }, [windowDimensions])

  const LoadComp = () => {
    return (
      <div
        className="flex items-center justify-center text-blue-600"
        style={{ minHeight: "200px" }}
      >
        <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
      </div>
    )
  }


  return (
    <Latex>
      <div className="App h-screen">
        {isFetchingCred ?
          (<LoadComp />)
          :
          (
            <>
              <Toaster
                position="top-center"
              />
              <Switch>
                {/* <Route path={"/home"}>
                  <HomePage />
                </Route>
                <Route path={"/login"}>
                  <LoginPage />
                </Route>
                <Route path={"/"}>
                  <LoginPage />
                </Route> */}
                <Route path={"/home"}>
                  <HomePage />
                </Route>
                <Route path={"/"}>
                  <LoginPage />
                </Route>
              </Switch>
            </>
          )
        }
      </div>
    </Latex>
  );
}


function checkSession({
  onAuthenticated,
  onUnAuthenticated,
  onError
}) {
  getSessionProfile()
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: onAuthenticated,
        onRecovFailure: onError,
        onUnAuth: onUnAuthenticated,
        onTechnicalError: onError
      })
    })
    .catch(error => { })
    .finally(() => { })
}

export default App;
