import { Transition } from "@headlessui/react"
import { useEffect, useState } from "react"
import {
  RiAddLine,
  RiArrowLeftLine,
  RiCloseFill,
  RiEdit2Line,
  RiEye2Fill,
  RiEyeCloseFill,
  RiEyeFill,
  RiFilePaperLine,
  RiLoader5Fill,
  RiSearchLine
} from "react-icons/ri";
import { MdSort } from "react-icons/md";
import { 
  resetSearchText, 
  setDataSummary, 
  setSearchText, 
  setSortFromNewestData, 
  setSortFromOldestData 
} from "../Models/Model";
import { format } from "date-fns"

function DataSummary({
  rootModel,
  setRootModel,
  totalData,
  totalDataAktif,
  latestInputDateTime,
  onOpenAddForm,
}) {

  const [headerModel, setHeaderModel] = useState({
    selectedMenu: null, //search, filter, sort,
  })

  const openFilterMenu = (m) => ({ ...m, selectedMenu: m.selectedMenu === 'filter' ? null : 'filter' })
  const openSortMenu = (m) => ({ ...m, selectedMenu: m.selectedMenu === 'sort' ? null : 'sort' })
  const openSearchMenu = (m) => ({ ...m, selectedMenu: m.selectedMenu === 'search' ? null : 'search' })
  const closeMenu = (m) => ({ ...m, selectedMenu: null })

  return (
    <>
      <Transition
        show={headerModel.selectedMenu === 'search'}
      >
        <div
          className="bg-white rounded-lg shadow-lg flex items-center"
          style={{ height: "50px" }}
        >
          <div className="p-2">
            <button
              className="h-full flex items-center p-2 rounded-lg hover:bg-blue-500 hover:text-white"
              onClick={() => setHeaderModel(closeMenu(headerModel))}
            >
              <RiArrowLeftLine className="h-6 w-6" />
            </button>
          </div>
          <div className="flex-1 h-full py-2 text-gray-700">
            <input
              autoFocus
              className="w-full h-full outline-none italic"
              onChange={e => setRootModel(setSearchText(rootModel, e.target.value))}
              value={rootModel.searchHeader.searchText}
              placeholder={"Cari kode Mapel atau nama Mapel"}
            />
          </div>
          <div className="p-2">
            <button
              className="h-full flex items-center p-2 rounded-lg hover:bg-blue-500 hover:text-white"
              onClick={() => setRootModel(resetSearchText(rootModel))}
            >
              <RiCloseFill className="h-6 w-6" />
            </button>
          </div>
        </div>
      </Transition>
      <Transition
        show={headerModel.selectedMenu !== 'search'}
      >
        <div className="">
          <div
            className="flex items-center justify-between bg-gray-200 p-3 py-7 rounded-sm"
            style={{ height: "50px" }}
          >
            <div>
              <div
                className="text-left font-bold"
                style={{
                  fontSize: "18px",
                  fontFamily: "Nunito"
                }}
              >
                Data Skema Cicilan
            </div>
            </div>
            <div
              className="flex space-x-2"
            >
              <div className="flex items-center">
                <button
                  className={
                    "rounded-md p-2 bg-white hover:text-blue-700 text-black "
                  }
                  onClick={() => setHeaderModel(openSearchMenu(headerModel))}
                >
                  <RiSearchLine className="h-5 w-5" />
                </button>
              </div>
              <div className="flex items-center relative">
                <button
                  className={
                    "rounded-md p-2 bg-white hover:bg-blue-700 hover:text-white text-black "
                  }
                  onClick={() => setHeaderModel(openSortMenu(headerModel))}
                >
                  <MdSort className="h-5 w-5" />
                </button>
                <Transition
                  show={headerModel.selectedMenu === 'sort'}
                  enter="duration-350"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div
                    className="absolute top-10"
                  >
                    <div
                      className="bg-white z-10 rounded-lg shadow-lg text-left"
                      style={{
                        width: "200px",
                        marginLeft: "-200px"
                      }}
                    >
                      <div>
                        <button
                          className={
                            "w-full p-2 hover:bg-blue-300 text-left rounded-lg " +
                            (rootModel.dataProperties.ord === 'newestData' ? ' bg-blue-500 text-white ' : '')
                          }
                          onClick={() => {
                            setHeaderModel(closeMenu(headerModel))
                            setRootModel(setSortFromNewestData(rootModel))
                          }}
                        >
                          Dari terbaru
                      </button>
                      </div>
                      <div>
                        <button
                          className={
                            "w-full p-2 hover:bg-blue-300 text-left rounded-lg " +
                            (rootModel.dataProperties.ord === 'oldestData' ? ' bg-blue-500 text-white ' : '')
                          }
                          onClick={() => {
                            setHeaderModel(closeMenu(headerModel))
                            setRootModel(setSortFromOldestData(rootModel))
                          }}
                        >
                          Dari terlama
                      </button>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
              <button
                className={
                  "rounded-md p-2 bg-white hover:bg-blue-700 hover:text-white text-black "
                }
                onClick={onOpenAddForm}
              >
                <RiAddLine className="h-5 w-5" />
              </button>
            </div>
          </div>
          <div
            className="flex px-5 py-3 space-x-12 bg-white rounded-bl-lg rounded-br-lg"
            style={{
              fontSize: "14px"
            }}
          >
            <div className="text-left">
              <div>
                Total Data 
              </div>
              <div>
                {totalData}
              </div>
            </div>
            <div className="text-left">
              <div>
                Tanggal terakhir input
            </div>
              <div>
                {latestInputDateTime ? format(new Date(latestInputDateTime), "dd/MM/yyyy") : 'tidak ada data'}
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  )
}

export default DataSummary;