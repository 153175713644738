import { useEffect, useState } from "react"
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"
import { RiCheckLine, RiEye2Line, RiEyeFill, RiLoader5Fill, RiSearchLine } from "react-icons/ri"
import Select from "react-select"
import { handleResponse200 } from "../../../utils/HttpUtils"
import { notifyError } from "../../../utils/Notification"
import { getDataSoal, getDataSoalSummary, getJenjangIdLabel, getMapelIdLabel, getMateriIdLabel } from "../Apis/Api"
import QuestionDetailBox from "./QuestionDetailBox"

const initPageModel = {
  questionDataSummary: {
    totalData: null
  },
  questionListProp: {
    search: '',
    limit: 50,
    offset: 0,
    orderBy: 'newestData'
  },
  questions: [],
  selectedJenjangIdLabel: ({ label: "Semua Jenjang", value: null }),
  selectedMapelIdLabel: ({ label: "Semua Mapel", value: null }),
  selectedMateriIdLabel: ({ label: "Semua Materi", value: null }),
  isLoadJenjangIdLabel: false,
  isLoadMapelIdLabel: false,
  idLoadMateriIdLabel: false,
  jenjangIdLabel: [],
  mapelIdLabel: [],
  materiIdLabel: [],
  selectedQuestionDetail: null
}

export const setQuestions = (model, questions) => ({
  ...model,
  questions: questions
})

export const setQuestionDataSummary = (model, totalData) => ({
  ...model,
  questionDataSummary: {
    totalData: totalData
  }
})

export const setSearchText = (model, searchText) => ({ ...model, questionListProp: { ...model.questionListProp, search: searchText } })

export const setSelectedJenjangIdLabel = (model, idLabel) => ({ ...model, selectedJenjangIdLabel: idLabel })
export const setSelectedMapelIdLabel = (model, idLabel) => ({ ...model, selectedMapelIdLabel: idLabel })
export const setSelectedMateriIdLabel = (model, idLabel) => ({ ...model, selectedMateriIdLabel: idLabel })

export const setJenjangIdLabel = (model, idLabel) => ({ ...model, jenjangIdLabel: idLabel })
export const setMapelIdLabel = (model, idLabel) => ({ ...model, mapelIdLabel: idLabel })
export const setMateriIdLabel = (model, idLabel) => ({ ...model, materiIdLabel: idLabel })

export const setIsLoadJenjang = (model, isLoad) => ({ ...model, isLoadJenjangIdLabel: isLoad })
export const setIsLoadMapel = (model, isLoad) => ({ ...model, isLoadMapelIdLabel: isLoad })
export const setIsLoadMateri = (model, isLoad) => ({ ...model, isLoadMateriIdLabel: isLoad })

export const resetOffset = (model) => ({ ...model, questionListProp: { ...model.questionListProp, offset: 0 } })

export const openQuestionDetail = (model, question) => ({ ...model, selectedQuestionDetail: question })
export const closeQuestionDetail = (model) => ({ ...model, selectedQuestionDetail: null })

export const dataPropertOrdToStr = (model) => {
  if (model.questionListProp.ord === 'newestData') {
    return 'Dari data terbaru'
  }
  if (model.questionListProp.ord === 'oldestData') {
    return 'Dari data terlama'
  }

  return 'Undefined Order'

}

export const hasNextPage = (model) => {
  return (model.questionListProp.offset + model.questionListProp.limit) < model.questionDataSummary.totalData
}
export const hasPrevPage = (model) => {
  return model.questionListProp.offset > 0
}
export const goToNextPage = (model) => {
  if ((model.questionListProp.offset + model.questionListProp.limit) <= model.questionDataSummary.totalData) {
    return {
      ...model,
      questionListProp: {
        ...model.questionListProp,
        offset: model.questionListProp.offset + model.questionListProp.limit
      }
    }
  }
  return model
}
export const goToPrevPage = (model) => {
  if ((model.questionListProp.offset - model.questionListProp.limit) >= 0) {
    return {
      ...model,
      questionListProp: {
        ...model.questionListProp,
        offset: model.questionListProp.offset - model.questionListProp.limit
      }
    }
  }
  return model
}

function DataTableFooter({
  pageNum,
  showCount,
  sortMode,
  searchText,
  hasNextPage,
  hasPrevPage,
  onGoToNextPage,
  onGoToPrevPage
}) {
  return (
    <div
      className="bg-white py-1 px-3 flex items-center justify-between border-t"
      style={{
        fontSize: "14px",
        height: "45px"
      }}
    >
      <div className="flex space-x-2">
        <label>
          Hal. {pageNum},
        </label>
        <label>
          Tampil: {showCount},
        </label>
        <label>
          Pencarian: {searchText && searchText !== '' ? searchText : '-'}
        </label>
      </div>
      <div className="flex">
        {hasPrevPage && (
          <button
            className=" p-2 rounded-lg hover:bg-gray-200"
            title={"Ke Halaman sebelumnya"}
            onClick={onGoToPrevPage}
          >
            <BsChevronLeft className="h-5 w-5" />
          </button>
        )}
        {hasNextPage && (
          <button
            className=" p-2 rounded-lg hover:bg-gray-200"
            title={"Ke Halaman selanjutnya"}
            onClick={onGoToNextPage}
          >
            <BsChevronRight className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  )
}

function ItemBox({
  no,
  kodeSoal,
  namaMateri,
  jenjang,
  mapel,
  onOpenQuestionDetail
}) {

  return (
    <div
      className="w-full bg-white group hover:bg-blue-500 flex shadow-lg divide-x divide-gray-300"
      style={{
        fontSize: "14px"
      }}
    >
      <div
        className="px-2 text-left flex items-center group-hover:text-white text-ellipsis"
        style={{
          width: "15%",
        }}
      >
        <p className="truncate">
          {kodeSoal}
        </p>
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "42%",
        }}
      >
        <p className="truncate">
          {namaMateri}
        </p>
      </div>
      <div className="flex px-2 items-center justify-left group-hover:text-white" style={{ width: "20%" }}>
        {mapel}
      </div>
      <div className="flex px-2 items-center justify-center group-hover:text-white" style={{ width: "15%" }}>
        {jenjang}
      </div>
      <div className="px-3 py-2 flex justify-center" style={{ width: "15%" }}>
        <button
          className="p-2 rounded-lg hover:bg-black text-black group-hover:text-white"
          onClick={onOpenQuestionDetail}
          title="Lihat Detail Soal"
        >
          <RiEyeFill className="h-5 w-5" />
        </button>
      </div>
    </div>
  )
}

function HeaderTable() {
  return (
    <div
      className="w-full bg-white flex items-center shadow-lg divide-x divide-gray-300 border-b bg-gray-100"
      style={{
        fontSize: "15px"
      }}
    >
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "15%",
        }}
      >
        Kode Soal
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "42%",
        }}
      >
        Materi
      </div>
      <div className="flex px-2 items-center justify-left group-hover:text-white" style={{ width: "20%" }}>
        Mapel
      </div>
      <div className="flex px-2 items-center justify-center group-hover:text-white" style={{ width: "15%" }}>
        Jenjang
      </div>
      <div className="px-3 flex justify-center" style={{ width: "15%" }}>
        #
      </div>
    </div>
  )
}

function DataTable({
  isLoadData,
  datas,
  rootModel,
  setRootModel
}) {

  return (
    <>
      <div className="space-y-3 w-full">
        <div className="overflow-x-auto bg-white">
          <HeaderTable />
          {
            isLoadData ?
              (<div
                className="flex items-center justify-center text-blue-600 bg-white"
                style={{ minHeight: "50px" }}
              >
                <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
              </div>)
              :
              datas.length > 0 ?
                (<div>
                  {datas.map((d, idx) => (
                    <ItemBox
                      no={idx + 1}
                      kodeSoal={d.kodeSoal}
                      namaMateri={d.namaMateri}
                      jenjang={d.namaJenjang}
                      mapel={d.namaMapel}
                      onOpenQuestionDetail={() => setRootModel(openQuestionDetail(rootModel, d))}
                    />
                  ))}
                </div>)
                :
                (<div className="py-2 bg-white">
                  Tidak ada data
                </div>)
          }
        </div>
      </div>
      <DataTableFooter
        pageNum={(rootModel.questionListProp.offset / rootModel.questionListProp.limit) + 1}
        showCount={rootModel.questions.length}
        sortMode={dataPropertOrdToStr(rootModel)}
        searchText={rootModel.questionListProp.searchText}
        hasNextPage={hasNextPage(rootModel)}
        hasPrevPage={hasPrevPage(rootModel)}
        onGoToNextPage={() => setRootModel(goToNextPage(rootModel))}
        onGoToPrevPage={() => setRootModel(goToPrevPage(rootModel))}
      />
    </>
  )
}

export default function QuestionListBox({
  kodePaketSoal,
  idTahapUjian,
  noSoal,
  onSuccessAddedQuestion
}) {

  const [model, setModel] = useState(initPageModel)

  const [tempSelectedJenjangIdLabel, setTempSelectedJenjangIdLabel] = useState({ label: "Semua Jenjang", value: null })
  const [tempSelectedMapelIdLabel, setTempSelectedMapelIdLabel] = useState({ label: "Semua Mapel", value: null })
  const [tempSelectedMateriIdLabel, setTempSelectedMateriIdLabel] = useState({ label: "Semua Materi", value: null })

  useEffect(() => {

    const payload = {
      search: model.questionListProp.search,
      offset: model.questionListProp.offset,
      limit: model.questionListProp.limit,
      orderBy: model.questionListProp.orderBy,
      kodeJenjang: model.selectedJenjangIdLabel.value,
      kodeMapel: model.selectedMapelIdLabel.value,
      kodeMateri: model.selectedMateriIdLabel.value
    }

    const dataSoalProm = getDataSoal(payload)
    const dataSummProm = getDataSoalSummary()

    Promise
      .all([dataSoalProm, dataSummProm])
      .then(ress => {
        handleResponse200({
          httpResponse: ress[0],
          onSuccess: payload => {
            setModel(latestM => setQuestions(latestM, payload))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })

        handleResponse200({
          httpResponse: ress[1],
          onSuccess: payload => {
            setModel(latestM => setQuestionDataSummary(latestM, payload.totalData))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => {

      })
      .finally(() => { })

  }, [])

  useEffect(() => {
    const payload = {
      search: model.questionListProp.search,
      offset: model.questionListProp.offset,
      limit: model.questionListProp.limit,
      orderBy: model.questionListProp.orderBy,
      kodeJenjang: model.selectedJenjangIdLabel.value,
      kodeMapel: model.selectedMapelIdLabel.value,
      kodeMateri: model.selectedMateriIdLabel.value
    }

    getDataSoal(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setModel(latestM =>
              setQuestions(
                latestM,
                payload
              )
            )
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => {

      })
      .finally(() => { })

  }, [model.questionListProp.search, model.questionListProp.offset, model.selectedJenjangIdLabel, model.selectedMapelIdLabel, model.selectedMateriIdLabel])

  useEffect(() => {
    setTempSelectedMateriIdLabel({ label: "Semua Materi", value: null })
  }, [tempSelectedJenjangIdLabel])

  useEffect(() => {
    setTempSelectedMateriIdLabel({ label: "Semua Materi", value: null })
  }, [tempSelectedMapelIdLabel])

  // useEffect(() => {
  //   setModel(setSelectedMateriIdLabel(model, ({ label: "Semua Materi", value: null })))
  // }, [model.selectedJenjangIdLabel])

  // useEffect(() => {
  //   setModel(setSelectedMateriIdLabel(model, ({ label: "Semua Materi", value: null })))
  // }, [model.selectedMapelIdLabel])

  function onSelectJenjang() {
    setModel(latestM => setIsLoadJenjang(latestM, true))

    getJenjangIdLabel()
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setModel(latestM => setJenjangIdLabel(latestM, [{ label: "Semua Jenjang", value: null }, ...payload.map(p => ({ value: p.id, label: p.label }))]))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => { setModel(latestM => setIsLoadJenjang(latestM, false)) })
  }

  function onSelectMapel() {
    setModel(latestM => setIsLoadMapel(latestM, true))

    getMapelIdLabel()
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setModel(latestM => setMapelIdLabel(latestM, [{ label: "Semua Mapel", value: null }, ...payload.map(p => ({ value: p.id, label: p.label }))]))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => { setModel(latestM => setIsLoadMapel(latestM, false)) })
  }

  function onSelectMateri() {
    setModel(latestM => setIsLoadMateri(latestM, true))

    getMateriIdLabel(tempSelectedJenjangIdLabel.value, tempSelectedMapelIdLabel.value)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setModel(latestM => setMateriIdLabel(latestM, [{ label: "Semua Materi", value: null }, ...payload.map(p => ({ value: p.id, label: p.label }))]))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => { })
  }

  function onAppliedFilter() {
    setModel(
      setSelectedJenjangIdLabel(
        setSelectedMapelIdLabel(
          setSelectedMateriIdLabel(
            resetOffset(model),
            tempSelectedMateriIdLabel
          ),
          tempSelectedMapelIdLabel
        ),
        tempSelectedJenjangIdLabel
      )
    )
  }

  return (
    <div className="relative">
      { model.selectedQuestionDetail && (
        <div
          className="absolute w-full h-full top-0 left-0 bg-white"
          style={{
            zIndex: "9"
          }}
        >
          <div className="pb-10">
            <QuestionDetailBox
              kodePaketSoal={kodePaketSoal}
              idTahapUjian={idTahapUjian}
              kodeSoal={model.selectedQuestionDetail.kodeSoal}
              noSoal={noSoal}
              onBack={() => setModel(closeQuestionDetail(model))}
              onSuccessAddedQuestion={onSuccessAddedQuestion}
              isAddedMode={true}
            />
          </div>
        </div>
      )}
      <div className="bg-white py-2">
        <div className="py-3 px-2 flex items-center space-x-2 border-b">
          <RiSearchLine className="h-5 w-5" />
          <input
            autoFocus
            className="w-full h-full outline-none italic"
            onChange={e => setModel(setSearchText(resetOffset(model), e.target.value))}
            value={model.questionListProp.search}
            placeholder={"Cari Kode Soal, Materi, Mapel atau Jenjang"}
          />
        </div>
        <div className="py-2 px-2 border-b flex space-x-2">
          <Select
            value={tempSelectedJenjangIdLabel}
            options={model.jenjangIdLabel}
            isLoading={model.isLoadJenjangIdLabel}
            onFocus={onSelectJenjang}
            menuPlacement="auto"
            className="w-40 border border-gray-200 rounded-md text-left"
            onChange={(value) => {
              // setModel({ ...model, selectedJenjangIdLabel: value })
              setTempSelectedJenjangIdLabel(value)
            }}
          />
          <Select
            value={tempSelectedMapelIdLabel}
            options={model.mapelIdLabel}
            isLoading={model.isLoadMapelIdLabel}
            onFocus={onSelectMapel}
            menuPlacement="auto"
            className="w-40 border border-gray-200 rounded-md text-left"
            onChange={(value) => {
              // setModel({ ...model, selectedMapelIdLabel: value })
              setTempSelectedMapelIdLabel(value)
            }}
          />
          <Select
            value={tempSelectedMateriIdLabel}
            options={model.materiIdLabel}
            isLoading={model.idLoadMateriIdLabel}
            onFocus={onSelectMateri}
            menuPlacement="auto"
            className="w-40 border border-gray-200 rounded-md text-left"
            onChange={(value) => {
              // setModel({ ...model, selectedMateriIdLabel: value })
              setTempSelectedMateriIdLabel(value)
            }}
          />
          <button
            className="p-2 px-3 bg-blue-500 hover:bg-blue-700 hover:shadow-lg text-white rounded-sm"
            onClick={onAppliedFilter}
          >
            Aplikasikan
        </button>
        </div>
        <div>
          <DataTable
            isLoadData={false}
            datas={model.questions}
            rootModel={model}
            setRootModel={setModel}
          />
        </div>

      </div>
    </div>
  )
}