import { FaCogs, FaUsersCog, FaCashRegister } from "react-icons/fa";
import { IoFileTrayFull } from "react-icons/io5";
import { AiFillDashboard } from "react-icons/ai";
import { GiWhiteBook } from "react-icons/gi";
import { useHistory } from "react-router-dom";

export default function Sidebar() {
  const history = useHistory()

  const sidebarWidth = "250px"

  return (
    <div
      className="bg-white fixed top-0 bottom-0 py-2 overflow-auto"
      style={{
        width: sidebarWidth,
        fontSize: "14px"
      }}
    >
      <div
        className="font-bold uppercase py-4 px-3 text-left"
        style={{ fontSize: "15px" }}
      >
        Manajemen Bimbel ALC
      </div>

      <div
        className="space-y-3"
      >

        <div
          className="text-left px-3 "
        >
          <div className="space-y-2 border rounded-lg pb-2">
            <div
              className="font-bold bg-blue-500 shadow-lg rounded-lg px-3 py-2 text-white"
              style={{
                fontFamily: 'Nunito'
              }}
            >
              Dashboard
            </div>
            <div>
              <div
                className="px-2"
              >
                <button
                  className="w-full text-left flex items-center space-x-2 p-2 text-gray-700 rounded-md font-bold hover:text-white hover:bg-blue-500"
                  onClick={() => history.push("/home/home")}
                >
                  <label className="cursor-pointer">Dashboard</label>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="text-left px-3 "
        >
          <div className="space-y-2 border rounded-lg pb-2">
            <div
              className="font-bold bg-blue-500 shadow-lg rounded-lg px-3 py-2 text-white"
              style={{
                fontFamily: 'Nunito'
              }}
            >
              Global Setting
            </div>
            <div
              className=""
            >
              <div
                className="px-2"
              >
                <button
                  className="w-full text-left flex items-center space-x-2 p-2 text-gray-700 rounded-md font-bold hover:text-white hover:bg-blue-500"
                  onClick={() => history.push("/home/admin")}
                >
                  <label className="cursor-pointer">Admin</label>
                </button>
              </div>
              <div
                className="px-2"
              >
                <button
                  className="w-full text-left flex items-center space-x-2 p-2 text-gray-700 rounded-md font-bold hover:text-white hover:bg-blue-500"
                  onClick={() => history.push("/home/fitur")}
                >
                  <label className="cursor-pointer">Fitur</label>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="text-left px-3 "
        >
          <div className="space-y-2 border rounded-lg pb-2">
            <div
              className="font-bold bg-blue-500 shadow-lg rounded-lg px-3 py-2 text-white"
              style={{
                fontFamily: 'Nunito'
              }}
            >
              Master Data
            </div>
            <div
              className=""
            >
              <div
                className="px-2"
              >
                <button
                  className="w-full text-left flex items-center space-x-2 p-2 text-gray-700 rounded-md font-bold hover:text-white hover:bg-blue-500"
                  onClick={() => history.push("/home/sekolah")}
                >
                  <label className="cursor-pointer">Sekolah</label>
                </button>
              </div>
              <div
                className="px-2"
              >
                <button
                  className="w-full text-left flex items-center space-x-2 p-2 text-gray-700 rounded-md font-bold hover:text-white hover:bg-blue-500"
                  onClick={() => history.push("/home/kelas")}
                >
                  <label className="cursor-pointer">Kelas</label>
                </button>
              </div>
              <div
                className="px-2"
              >
                <button
                  className="w-full text-left flex items-center space-x-2 p-2 text-gray-700 rounded-md font-bold hover:text-white hover:bg-blue-500"
                  onClick={() => history.push("/home/skema-cicilan")}
                >
                  <label className="cursor-pointer">Skema Cicilan</label>
                </button>
              </div>
              <div
                className="px-2"
              >
                <button
                  className="w-full text-left flex items-center space-x-2 p-2 text-gray-700 rounded-md font-bold hover:text-white hover:bg-blue-500"
                  onClick={() => history.push("/home/discount")}
                >
                  <label className="cursor-pointer">Diskon</label>
                </button>
              </div>
              <div
                className="px-2"
              >
                <button
                  className="w-full text-left flex items-center space-x-2 p-2 text-gray-700 rounded-md font-bold hover:text-white hover:bg-blue-500"
                  onClick={() => history.push("/home/program")}
                >
                  <label className="cursor-pointer">Program</label>
                </button>
              </div>
              <div
                className="px-2"
              >
                <button
                  className="w-full text-left flex items-center space-x-2 p-2 text-gray-700 rounded-md font-bold hover:text-white hover:bg-blue-500"
                  onClick={() => history.push("/home/grup-tahap-ujian")}
                >
                  <label className="cursor-pointer">Tahap Ujian</label>
                </button>
              </div>
              <div
                className="px-2"
              >
                <button
                  className="w-full text-left flex items-center space-x-2 p-2 text-gray-700 rounded-md font-bold hover:text-white hover:bg-blue-500"
                  onClick={() => history.push("/home/paket-soal")}
                >
                  <label className="cursor-pointer">Paket Soal</label>
                </button>
              </div>
              <div
                className="px-2"
              >
                <button
                  className="w-full text-left flex items-center space-x-2 p-2 text-gray-700 rounded-md font-bold hover:text-white hover:bg-blue-500"
                  onClick={() => history.push("/home/paket-bimbel")}
                >
                  <label className="cursor-pointer">Paket Bimbel</label>
                </button>
              </div>

            </div>
          </div>
        </div>
        <div
          className="text-left px-3 "
        >
          <div className="space-y-2 border rounded-lg pb-2">
            <div
              className="font-bold bg-blue-500 shadow-lg rounded-lg px-3 py-2 text-white"
              style={{
                fontFamily: 'Nunito'
              }}
            >
              Kelola Member
            </div>
            <div
              className=""
            >
              <div
                className="px-2"
              >
                <button
                  className="w-full text-left flex items-center space-x-2 p-2 text-gray-700 rounded-md font-bold hover:text-white hover:bg-blue-500"
                  onClick={() => history.push("/home/orangtua")}
                >
                  <label className="cursor-pointer">Data Member</label>
                </button>
              </div>
              <div
                className="px-2"
              >
                <button
                  className="w-full text-left flex items-center space-x-2 p-2 text-gray-700 rounded-md font-bold hover:text-white hover:bg-blue-500"
                  onClick={() => history.push("/home/anak")}
                >
                  <label className="cursor-pointer">Data Anak</label>
                </button>
              </div>
              <div
                className="px-2"
              >
                <button
                  className="w-full text-left flex items-center space-x-2 p-2 text-gray-700 rounded-md font-bold hover:text-white hover:bg-blue-500"
                  onClick={() => history.push("/home/cicilan")}
                >
                  <label className="cursor-pointer">Cicilan</label>
                </button>
              </div>
              <div
                className="px-2"
              >
                <button
                  className="w-full text-left flex items-center space-x-2 p-2 text-gray-700 rounded-md font-bold hover:text-white hover:bg-blue-500"
                  onClick={() => history.push("/home/transaksi")}
                >
                  <label className="cursor-pointer">Transaksi</label>
                </button>
              </div>
              <div
                className="px-2"
              >
                <button
                  className="w-full text-left flex items-center space-x-2 p-2 text-gray-700 rounded-md font-bold hover:text-white hover:bg-blue-500"
                  onClick={() => history.push("/home/ujian")}
                >
                  <label className="cursor-pointer">Ujian</label>
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}