export const initModel = {
  questionEvals: [],
  selectedQuestionCode: null,
  selectedQuestionNumber: 1,
  selectedQuestionDetail: null,
  isFetchingQuestions: false,
  isFetchingQuestionDetail: false
}

export const setQuestionEvals = (model, questions) => ({ ...model, questionEvals: questions })
export const setSelectedQuestionCode = (model, questionCode, number) => ({ ...model, selectedQuestionCode: questionCode, selectedQuestionNumber: number })
export const setSelectedQuestionDetail = (model, detail) => ({ ...model, selectedQuestionDetail: detail })
export const setIsFetchingQuestions = (model, isFetching) => ({ ...model, isFetchingQuestions: isFetching })
export const setIsFetchingQuestionDetail = (model, isFetching) => ({ ...model, isFetchingQuestionDetail: isFetching })

export const getActiveQuestionNumber = (model) => {
  const act = model.questionEvals.find(q => q.kodeSoal === model.selectedQuestionCode)
  if (!act) return -1
  return act.noSoal
}
export const hasPrevQuestion = (model) => {
  const act = model.questionEvals.find(q => q.kodeSoal === model.selectedQuestionCode)
  if (!act) return false
  return act.noSoal > 1
}
export const hasNextQuestion = (model) => {
  const act = model.questionEvals.find(q => q.kodeSoal === model.selectedQuestionCode)
  if (!act) return false
  return act.noSoal < model.questionEvals.length
}

export const goToPrevQuestion = (model) => {
  if (!hasPrevQuestion(model)) return model

  const curr = model.questionEvals.find(q => q.kodeSoal === model.selectedQuestionCode)
  if (!curr) return model

  const prev = model.questionEvals.find(q => q.noSoal === (curr.noSoal - 1))
  if (!prev) return model

  return {
    ...model,
    selectedQuestionCode: prev.kodeSoal,
    selectedQuestionNumber: model.selectedQuestionNumber - 1
  }

}

export const goToNextQuestion = (model) => {
  if (!hasNextQuestion(model)) return model

  const curr = model.questionEvals.find(q => q.kodeSoal === model.selectedQuestionCode)
  if (!curr) return model

  const next = model.questionEvals.find(q => q.noSoal === (curr.noSoal + 1))
  if (!next) return model

  return {
    ...model,
    selectedQuestionCode: next.kodeSoal,
    selectedQuestionNumber: model.selectedQuestionNumber + 1
  }
}