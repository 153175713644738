import axios from "axios"

export const getData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/transaction"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getDataSummary = async (payload) => {
  const finalUrl =
    window._HOST + "admin/transaction-summary"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getProgramIdLabel = async () => {
  const finalUrl = window._HOST + "admin/program/id-label"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}
