import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { setHomeActivePage } from "../../../app/GlobalSlice"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { registerLocale } from "react-datepicker";
import id from 'date-fns/locale/id'
import { GoCalendar } from "react-icons/go"
import { initModel, setData, setDateParam } from "../Models/Model";
import {
  dataPropertOrdToStr,
  goToNextPage,
  goToPrevPage,
  hasNextPage,
  hasPrevPage
} from "../Models/Model";
import { setIsLoadData } from "../../Member/Models/Model";
import { getData, getProgramIdLabel } from "../Apis/Api";
import { handleResponse200 } from "../../../utils/HttpUtils";
import { notifyError } from "../../../utils/Notification";
import { RiLoader5Fill } from "react-icons/ri";
import { format } from "date-fns"
import Select from "react-select"
import { BsChevronLeft, BsChevronRight, BsFillGridFill, BsTrash } from "react-icons/bs"

registerLocale(id)

const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
    { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}

function DataTableFooter({
  pageNum,
  showCount,
  sortMode,
  searchText,
  hasNextPage,
  hasPrevPage,
  onGoToNextPage,
  onGoToPrevPage
}) {
  return (
    <div
      className="bg-white py-1 px-3 shadow-lg flex items-center justify-between rounded-md"
      style={{
        fontSize: "14px",
        height: "45px"
      }}
    >
      <div className="flex space-x-2">
        <label>
          Hal. {pageNum},
        </label>
        <label>
          Tampil: {showCount},
        </label>
        <label>
          Mode pengurutan:  {sortMode},
        </label>
        <label>
          Pencarian: {searchText && searchText !== '' ? searchText : '-'}
        </label>
      </div>
      <div className="flex">
        {hasPrevPage && (
          <button
            className=" p-2 rounded-lg hover:bg-gray-200"
            title={"Ke Halaman sebelumnya"}
            onClick={onGoToPrevPage}
          >
            <BsChevronLeft className="h-5 w-5" />
          </button>
        )}
        {hasNextPage && (
          <button
            className=" p-2 rounded-lg hover:bg-gray-200"
            title={"Ke Halaman selanjutnya"}
            onClick={onGoToNextPage}
          >
            <BsChevronRight className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  )
}

function ItemBox({
  no,
  idCicilanMember,
  idAnak,
  namaAnak,
  idMember,
  namaMember,
  idPaketBimbel,
  namaPaketBimbel,
  idSkemaCicilan,
  namaSkemaCicilan,
  isLunas,
  createdAt,
  onViewDetail
}) {
  const rowClassName = "flex px-2 py-2 items-center bg-white border-b border-gray-200 "

  return (
    <div
      className="w-full flex shadow-lg divide-x divide-gray-300"
      style={{
        fontSize: "14px",
        fontFamily: 'Nunito'
      }}
    >
      <div 
        className={rowClassName} 
        style={{ 
          width: "5%",
          minWidth: "50px"
        }}
      >
        {no}
      </div>
      <div 
        className={rowClassName} 
        style={{ 
          width: "10%",
          minWidth: "150px"
        }}
      >
        {idCicilanMember}
      </div>
      <div 
        className={rowClassName} 
        style={{ 
          width: "20%",
          minWidth: "250px"
        }}
      >
        <div className="space-y-1 text-left">
          <div>
            {idPaketBimbel}
          </div>
          <div>
            {namaPaketBimbel}
          </div>
        </div>
      </div>
      <div 
        className={rowClassName} 
        style={{ 
          width: "20%",
          minWidth: "250px"
        }}
      >
        <div className="space-y-1 text-left">
          <div>
            {idAnak}
          </div>
          <div>
            {namaAnak}
          </div>
        </div>
      </div>
      <div 
        className={rowClassName} 
        style={{ 
          width: "20%",
          minWidth: "250px"
        }}
      >
        <div className="space-y-1 text-left">
          <div>
            {idMember}
          </div>
          <div>
            {namaMember}
          </div>
        </div>
      </div>
      <div 
        className={rowClassName} 
        style={{ 
          width: "20%",
          minWidth: "250px"
        }}
      >
        <div className="space-y-1 text-left">
          <div>
            {idSkemaCicilan}
          </div>
          <div>
            {namaSkemaCicilan}
          </div>
        </div>
      </div>
      <div 
        className={rowClassName + ' justify-center '} 
        style={{ 
          width: "10%",
          minWidth: "150px"
        }}
      >
        { isLunas ? 'Lunas' : 'Belum Lunas' } 
      </div>
      <div 
        className={rowClassName + ' justify-center '} 
        style={{ 
          width: "10%",
          minWidth: "150px"
        }}
      >
        {format(new Date(createdAt), "dd/MM/yyyy HH:mm")}
      </div>
      <div 
        className={rowClassName + ' justify-center '} 
        style={{ 
          width: "10%",
          minWidth: "200px"
        }}
      >
        <button
          className="p-1 px-2 rounded-lg bg-blue-500 text-white"
          onClick={onViewDetail}
        >
          Lihat Detail
        </button> 
      </div>
    </div>
  )
} 

function HeaderTable() {
  return (
    <div
      className="w-full bg-white flex items-center shadow-lg divide-x divide-gray-300 font-bold"
      style={{
        fontSize: "15px",
        fontFamily: 'Nunito'
      }}
    >
      <div 
        className="flex px-2 py-2 group-hover:text-white items-center justify-center bg-gray-100 border-b" 
        style={{ 
          width: "5%",
          minWidth: "50px" 
        }}
      >
        No
      </div>
      <div
        className="px-2 py-2 text-left flex items-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{
          width: "10%",
          minWidth: "150px"
        }}
      >
        Kode
      </div>
      <div
        className="px-2 py-2 text-left flex items-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{
          width: "20%",
          minWidth: "250px"
        }}
      >
        Paket Bimbel
      </div>
      <div
        className="px-2 py-2 text-left flex items-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{
          width: "20%",
          minWidth: "250px"
        }}
      >
        Anak
      </div>
      <div
        className="px-2 py-2 text-left flex items-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{
          width: "20%",
          minWidth: "250px"
        }}
      >
        Member
      </div>
      <div
        className="px-2 py-2 text-left flex items-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{
          width: "20%",
          minWidth: "250px"
        }}
      >
        Skema Cicilan
      </div>
      <div
        className="flex px-2 py-2 items-center justify-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{ 
          width: "10%",
          minWidth: "150px" 
        }}
      >
        Status
      </div>
      <div
        className="flex px-2 py-2 items-center justify-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{ 
          width: "10%",
          minWidth: "150px" 
        }}
      >
        Tgl dibuat
      </div>
      <div
        className="flex px-2 py-2 items-center justify-center group-hover:text-white justify-center bg-gray-100 border-b"
        style={{ 
          width: "10%",
          minWidth: "200px" 
        }}
      >
        #
      </div>
    </div>
  )
}

function DataTable({
  isLoadData,
  datas,
  rootModel,
  setRootModel
}) {

  const history = useHistory()

  return (
    <div className="space-y-3">
      <div className="overflow-x-auto bg-white">
        <HeaderTable />
        {
          isLoadData ?
            (<div
              className="flex items-center justify-center text-blue-600 bg-white"
              style={{ minHeight: "50px" }}
            >
              <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
            </div>)
            :
            datas.length > 0 ?
              (<div>
                {datas.map((d, idx) => (
                  <ItemBox
                    no={idx + 1}
                    idCicilanMember={d.idCicilanMember}
                    idAnak={d.idAnak}
                    namaAnak={d.namaAnak}
                    idMember={d.idOrangtua}
                    namaMember={d.namaOrangtua}
                    idPaketBimbel={d.idPaketBimbel}
                    namaPaketBimbel={d.namaPaketBimbel}
                    idSkemaCicilan={d.idSkemaCicilan}
                    namaSkemaCicilan={d.namaSkemaCicilan}
                    isLunas={d.isLunas}
                    createdAt={d.createdAt}
                    onViewDetail={() => history.push('/home/cicilan/detail/' + d.idCicilanMember)}
                    
                  />
                ))}
              </div>)
              :
              (<div className="py-2 bg-white">
                Tidak ada data
              </div>)
        }
      </div>
      {/* <DataTableFooter
        pageNum={(rootModel.dataProperties.offset / rootModel.dataProperties.limit) + 1}
        showCount={rootModel.dataList.length}
        sortMode={dataPropertOrdToStr(rootModel)}
        searchText={rootModel.searchHeader.searchText}
        hasNextPage={hasNextPage(rootModel)}
        hasPrevPage={hasPrevPage(rootModel)}
        onGoToPrevPage={() => setRootModel(goToPrevPage(rootModel))}
        onGoToNextPage={() => setRootModel(goToNextPage(rootModel))}
      /> */}
    </div>
  )

}

const CountedBox = ({
  totalCreated,
  totalSettled
}) => {
  return (
    <div
      className="flex space-x-5 p-2 "
    >
      <div
        className="space-y-2"
      >
        <div className="flex justify-center">
          <div
            className="flex items-center justify-center rounded-full border-4 border-blue-500 font-bold"
            style={{
              width: "70px",
              height: "70px",
              fontSize: "22px"
            }}
          >
            {totalCreated}
          </div>
        </div>
        <div
          className="flex justify-center"
        >
          <label
            className="py-1 px-5 rounded-full bg-blue-500 text-white"
            style={{
              fontSize: '14px'
            }}
          >
            Transaksi
          </label>
        </div>
      </div>
      <div
        className="space-y-2"
      >
        <div className="flex justify-center">
          <div
            className="flex items-center justify-center rounded-full border-4 border-green-500 font-bold"
            style={{
              width: "70px",
              height: "70px",
              fontSize: "22px"
            }}
          >
            {totalSettled}
          </div>
        </div>
        <div
          className="flex justify-center"
        >
          <label
            className="py-1 px-5 rounded-full bg-green-500 text-white"
            style={{
              fontSize: '14px'
            }}
          >
            Lunas
          </label>
        </div>
      </div>
    </div>
  )
}

const ParamBox = ({
  onParamChanged
}) => {

  const date = new Date()
  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1))
  const [untilDate, setUntilDate] = useState(date)

  const [programOpts, setProgramOpts] = useState({
    selectedOpt: { label: "Semua", value: null },
    data: [],
    isLoad: false
  })

  useEffect(() => {

    onParamChanged(toIsoDate(startDate), toIsoDate(untilDate), programOpts.selectedOpt.value)

  }, [startDate, untilDate, programOpts.selectedOpt])

  function toIsoDate(date) {
    return date.getFullYear() + '-' + padZero(date.getMonth() + 1) + '-' + padZero(date.getDate().toString())
  }

  function padZero(n) {
    return String(n).padStart(2, '0')
  }

  function onSelectProgramOpts() {
    setProgramOpts({ ...programOpts, isLoad: true })

    getProgramIdLabel()
      .then(response => {
        if (response.success) {
          const data = [{ label: "Semua", value: null }].concat(response.payload.map(d => ({ label: d.label, value: d.id })))
          setProgramOpts({ ...programOpts, data: data, isLoad: false })
        }
        else {
          setProgramOpts({ ...programOpts, isLoad: false })
        }
      })
      .catch(error => {
        setProgramOpts({ ...programOpts, isLoad: false })
        if (error.response != undefined && error.response != null) {
          if (error.response.status == 401) {
            notifyError("Forbidden")
          }
          else {
            notifyError("Server Error")
          }
        }
      })
  }

  return (
    <div>
      <div
        className="flex items-center space-x-2 px-5"
        style={{ height: "50px" }}
      >
        <div
          className="font-bold w-20 text-left"
          style={{
            fontFamily: 'Nunito'
          }}
        >
          Periode
        </div>
        <div className="flex items-center">
          <div className="h-8 flex items-center px-1 border-t border-l border-b border-gray-400">
            <GoCalendar />
          </div>
          <DatePicker 
            locale={id}
            className="border border-gray-400 p-1 w-28 h-8" 
            selected={startDate} 
            onChange={(date) => setStartDate(date)} 
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div>-</div>
        <div className="flex items-center">
          <div className="h-8 flex items-center px-1 border-t border-l border-b border-gray-400">
            <GoCalendar />
          </div>
          <DatePicker
            className="border border-gray-400 p-1 w-28 h-8" 
            selected={untilDate} 
            onChange={(date) => setUntilDate(date)} 
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </div>
      <div
        className="flex items-center space-x-2 px-5"
        style={{ height: "50px" }}
      >
        <div
          className="font-bold w-20 text-left"
          style={{
            fontFamily: 'Nunito'
          }}
        >
          Program
        </div>
        <div className="flex items-center">
          <Select
            value={programOpts.selectedOpt}
            options={programOpts.data}
            isLoading={programOpts.isLoad}
            menuPlacement="auto"
            className="w-60 border border-gray-200 rounded-md text-left"
            onFocus={onSelectProgramOpts}
            onChange={(value) => {
              setProgramOpts({ ...programOpts, selectedOpt: value })
            }}
          />
        </div>
      </div>

    </div>
  )
}

export default function CicilanPage() {
  const dispatch = useDispatch()
  const history = useHistory()

  const [model, setModel] = useState(initModel)

  const [countedData, setCountedData] = useState({
    created: 0,
    settled: 0
  })

  useEffect(() => {
    // dispatch(setHomeActivePage('transaction'))
  }, [])

  useEffect(() => {
    if (!model.dataProperties.fromDate || !model.dataProperties.untilDate) return

    onGetData(model, setModel, history)
  }, [model.dataProperties])

  useEffect(() => {
    if (model.dataList.length === 0) {
      return setCountedData({
        created: 0,
        settled: 0
      })
    }

    let current = new Date()

    setCountedData({
      created: model.dataList.length,
      settled: model.dataList.filter(d => d.isLunas).length
    })

  }, [model.dataList])

  return (
    <div className="space-y-3 w-full">
      <div className="bg-white rounded-lg shadow-lg ">
        <ParamBox 
          onParamChanged={(fromDateStr, untilDataStr, programId) => setModel(setDateParam(model, fromDateStr, untilDataStr, programId))}
        />
        <div
          className="flex border border-gray-300 bg-white mt-3 px-5 py-2"
        >
          <CountedBox 
            totalCreated={countedData.created}
            totalSettled={countedData.settled}
          />
        </div>
      </div>
      
      <div>
        <DataTable 
          isLoadData={model.isLoadData}
          rootModel={model}
          setRootModel={setModel}
          datas={model.dataList}
        />
      </div>
    </div>
  )
}

function onGetData(rootModel, setRootModel, history) {
  const payload = {
    search: rootModel.searchHeader.searchText,
    offset: rootModel.dataProperties.offset,
    limit: rootModel.dataProperties.limit,
    orderBy: rootModel.dataProperties.ord,
    fromDate: rootModel.dataProperties.fromDate,
    untilDate: rootModel.dataProperties.untilDate,
    program: rootModel.dataProperties.programId
  }

  // setRootModel(setIsLoadData(rootModel, true))

  getData(payload)
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          // console.log(payload)
          setRootModel(latestM => setData(latestM, payload))
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          history.push("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => {
      // setRootModel(latestM => setIsLoadData(rootModel, false))
    })

}