export const initModel = {
  activeSubPage: 'home', //home, form
  dataSummary: {
    latestInputDateTime: null,
    totalData: null,
    totalDataAktif: null
  },
  dataProperties: {
    ord: 'newestData', //newestData, oldestData,
    offset: 0,
    limit: 50
  },
  searchHeader: {
    searchText: null
  },
  form: {
    mode: null, //add, edit, detail
    selectedData: null,
    isSaving: false
  },
  deleteDialog: {
    isOpen: false,
    isDeleting: false,
    selectedData: null
  },
  dataList: [],
  isLoadData: false,
  refetchData: false,
  refetchDataSummary: false
}

export const setDataSummary = (model, totalData, totalDataAktif, latestInputDateTime) => ({
  ...model,
  dataSummary: {
    latestInputDateTime: latestInputDateTime,
    totalData: totalData,
    totalDataAktif: totalDataAktif
  }
})

export const setData = (model, data) => ({
  ...model,
  dataList: data
})

export const setSearchText = (model, text) => ({ ...model, searchHeader: { ...model.searchHeader, searchText: text } })
export const resetSearchText = (model) => ({ ...model, searchHeader: { ...model.searchHeader, searchText: '' } })

export const openDetailFormPage = (model, data) => ({ ...model, activeSubPage: 'form', form: { ...model.form, mode: 'detail', selectedData: data } })
export const openAddFormPage = (model) => ({ ...model, activeSubPage: 'form', form: { ...model.form, mode: 'add' } })
export const openEditFormPage = (model, data) => ({ ...model, activeSubPage: 'form', form: { ...model.form, mode: 'edit', selectedData: data } })
export const closeFormDialog = (model) => ({ ...model, activeSubPage: 'home', form: { ...model.from, mode: null, selectedData: null } })

export const openDeleteDialog = (model, data) => ({ ...model, activeSubPage: 'delete', deleteDialog: { ...model.deleteDialog, isOpen: true, selectedData: data } })
export const closeDeleteDialog = (model) => ({ ...model, activeSubPage: 'home', deleteDialog: { ...model.deleteDialog, isOpen: false, selectedData: null } })

export const setSortFromNewestData = (model) => ({ ...model, dataProperties: { ...model.dataProperties, ord: 'newestData', offset: 0 } })
export const setSortFromOldestData = (model) => ({ ...model, dataProperties: { ...model.dataProperties, ord: 'oldestData', offset: 0 } })

export const setStartRefetchData = (model) => ({ ...model, refetchData: true })
export const setFinishRefetchData = (model) => ({ ...model, refetchData: false })

export const setStartRefetchDataSummary = (model) => ({ ...model, refetchDataSummary: true })
export const setFinishRefetchDataSummary = (model) => ({ ...model, refetchDataSummary: false })

export const dataPropertOrdToStr = (model) => {
  if (model.dataProperties.ord === 'newestData') {
    return 'Dari data terbaru'
  }
  if (model.dataProperties.ord === 'oldestData') {
    return 'Dari data terlama'
  }

  return 'Undefined Order'

}

export const isAddForm = (model) => {
  return model.activeSubPage === 'form' && model.form.mode === 'add'
}

export const isEditForm = (model) => {
  return model.activeSubPage === 'form' && model.form.mode === 'edit'
}

export const openHomePage = (model) => ({ ...model, activeSubPage: 'home' })
export const setIsLoadData = (model, isLoad) => ({ ...model, isLoadData: isLoad })

export const hasNextPage = (model) => {
  return (model.dataProperties.offset + model.dataProperties.limit) < model.dataSummary.totalData
}
export const hasPrevPage = (model) => {
  return model.dataProperties.offset > 0
}
export const goToNextPage = (model) => {
  if ((model.dataProperties.offset + model.dataProperties.limit) <= model.dataSummary.totalData) {
    return {
      ...model,
      dataProperties: {
        ...model.dataProperties,
        offset: model.dataProperties.offset + model.dataProperties.limit
      }
    }
  }
  return model
}
export const goToPrevPage = (model) => {
  if ((model.dataProperties.offset - model.dataProperties.limit) >= 0) {
    return {
      ...model,
      dataProperties: {
        ...model.dataProperties,
        offset: model.dataProperties.offset - model.dataProperties.limit
      }
    }
  }
  return model
}

export const resetOffset = (model) => ({ ...model, dataProperties: { ...model.dataProperties, offset: 0 } })