import axios from "axios"

export const getData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/cicilan"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getDetail = async (idCicilan) => {
  const finalUrl =
    window._HOST + "admin/cicilan-detail/" + idCicilan

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const getCicilanTransaction = async (idCicilan) => {
  const finalUrl =
    window._HOST + "admin/cicilan-transaction/" + idCicilan

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const getPaymentChannel = async () => {
  const finalUrl =
    window._HOST + "admin/payment-channel"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const createInvoice = async (payload) => {
  const finalUrl =
    window._HOST + "admin/cicilan-invoice"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getProgramIdLabel = async () => {
  const finalUrl = window._HOST + "admin/program/id-label"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}