import { useEffect, useState } from "react"
import { AiOutlineCheck, AiOutlineClose, AiOutlinePlus } from "react-icons/ai"
import { RiArrowLeftLine, RiLoader4Fill, RiLoader4Line } from "react-icons/ri"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import { handleResponse200 } from "../../../utils/HttpUtils"
import { notifyError } from "../../../utils/Notification"
import { createData, getDetail, getDiskonIdLabel, getProgramIdLabel, getSkemaCicilanIdLabel, updateData } from "../Apis/Api"
import Select from "react-select"
import { GrCheckbox, GrCheckboxSelected } from "react-icons/gr"
import { SketchPicker } from 'react-color'

const formatRupiah = (angka) => {
  angka = angka.toString()

  var number_string = angka.replace(/[^,\d]/g, '').toString(),
  split   		      = number_string.split(','),
  sisa     		      = split[0].length % 3,
  rupiah     		    = split[0].substr(0, sisa),
  ribuan     		    = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if(ribuan){
    let separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }

  rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
  return rupiah
}

const unFormatRupiah = (angkaStr) => {
  return parseInt(angkaStr.replaceAll('.', '')) 
}

export default function FormPage() {
  const history = useHistory()

  const { screenSize } = useSelector(state => state.global)

  const [model, setModel] = useState({
    form: {
      idPaketBimbel: '',
      namaPaketBimbel: '',
      description: '',
      color: '#3b73c5', //default warna biru
      harga: 0,
      isInternal: false,
      isExternal: false,
      benefits: []
    },
    isProcess: false
  })

  const [file, setFile] = useState(null)
  const [preview, setPreview] = useState(null)

  const fileReader = new FileReader()
  fileReader.onload = r => {
    setPreview(r.target.result)
  }

  useEffect(() => {
    if (!file) {
      setPreview(null)
      return
    }
    fileReader.readAsDataURL(file)
  }, [file])

  function onSelectFile(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setFile(null)
      return
    }

    setFile(e.target.files[0])
  }

  const [discountOpts, setDiscountOpts] = useState({
    selectedOpt: { label: 'Tanpa Diskon', value: null },
    data: [],
    rawData: [],
    isLoad: false
  })

  const [programOpts, setProgramOpts] = useState({
    selectedOpt: null,
    data: [],
    isLoad: false
  })

  const [skemaCicilanOpts, setSkemaCicilanOpts] = useState({
    selectedOpt: { value: null, label: 'Tidak ada' },
    data: [{ value: null, label: 'Tidak ada' }],
    isLoad: false
  })

  const { mode, id } = useParams()

  useEffect(() => {
    if (!mode) return;

    setModel({...model, isProcess: true})
    getDataDetail(id)

  }, [])

  function getDataDetail(idData) {
    getDetail(idData)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {

            setModel(latestM => ({ 
              ...latestM, 
              form: {
                ...model.form, 
                idPaketBimbel: payload.idPaketBimbel,
                namaPaketBimbel: payload.namaPaketBimbel,
                description: payload.description,
                color : payload.color,
                harga: payload.harga,
                isInternal: payload.isInternal,
                isExternal: payload.isExternal,
                benefits: payload.benefits,
              } 
            }))
            
            setPreview(payload.iconBase64)
              
            if (payload.discount) {
              setDiscountOpts({ 
                ...discountOpts, 
                selectedOpt: { label: payload.discount.label, value: payload.discount.id },
                rawData: [payload.discount]
              })
            }

            if (payload.program) {
              setProgramOpts({
                ...programOpts,
                selectedOpt: { label: payload.program.label, value: payload.program.id }
              })
            }

            if (payload.skemaCicilan) {
              setSkemaCicilanOpts({
                ...skemaCicilanOpts,
                selectedOpt: { label: payload.skemaCicilan.label, values: payload.skemaCicilan.id }
              })
            }

          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))

  }

  function saveData() {
    setModel({ ...model, isProcess: true })

    const payload = {
      idPaketBimbel: model.form.idPaketBimbel,
      namaPaketBimbel: model.form.namaPaketBimbel,
      description: model.form.description,
      color: model.form.color,
      iconBase64: preview,
      program: programOpts.selectedOpt ? programOpts.selectedOpt.value : null,
      harga: parseInt(model.form.harga),
      discount: discountOpts.selectedOpt ? discountOpts.selectedOpt.value : null,
      isInternal: model.form.isInternal,
      isExternal: model.form.isExternal,
      benefit: model.form.benefits,
      idSkemaCicilan: skemaCicilanOpts.selectedOpt ? skemaCicilanOpts.selectedOpt.value : null
    }

    if (mode) {
      updateData(payload)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
              history.push("/home/paket-bimbel")
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))

    }
    else {
      createData(payload)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
              history.push("/home/paket-bimbel")
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))
    }
  }

  function onSelectDiscounts() {
    setDiscountOpts({ ...discountOpts, isLoad: true })

    getDiskonIdLabel()
      .then(response => {
        if (response.success) {
          const data = [{ label: 'Tanpa Diskon', value: null }].concat(response.payload.map(d => ({ label: d.label, value: d.id })))
          setDiscountOpts({ ...discountOpts, data: data, isLoad: false, rawData: response.payload })
        }
        else {
          setDiscountOpts({ ...discountOpts, isLoad: false })
        }
      })
      .catch(error => {
        setDiscountOpts({ ...discountOpts, isLoad: false })
        if (error.response != undefined && error.response != null) {
          if (error.response.status == 401) {
            notifyError("Forbidden")
          }
          else {
            notifyError("Server Error")
          }
        }
      })
  }

  function onSelectProgramOpts() {
    setProgramOpts({ ...programOpts, isLoad: true })

    getProgramIdLabel()
      .then(response => {
        if (response.success) {
          const data = response.payload.map(d => ({ label: d.label, value: d.id }))
          setProgramOpts({ ...programOpts, data: data, isLoad: false })
        }
        else {
          setProgramOpts({ ...programOpts, isLoad: false })
        }
      })
      .catch(error => {
        setProgramOpts({ ...programOpts, isLoad: false })
        if (error.response != undefined && error.response != null) {
          if (error.response.status == 401) {
            notifyError("Forbidden")
          }
          else {
            notifyError("Server Error")
          }
        }
      })
  }

  function onSelectSkemaCicilanOpts() {
    setSkemaCicilanOpts({ ...skemaCicilanOpts, isLoad: true })

    getSkemaCicilanIdLabel()
      .then(response => {
        if (response.success) {
          const data = response.payload.map(d => ({ label: d.label, value: d.id }))
          setSkemaCicilanOpts({ ...skemaCicilanOpts, data: [{ value: null, label: 'Tidak ada' }].concat(data), isLoad: false })
        }
        else {
          setSkemaCicilanOpts({ ...skemaCicilanOpts, isLoad: false })
        }
      })
      .catch(error => {
        setSkemaCicilanOpts({ ...skemaCicilanOpts, isLoad: false })
        if (error.response != undefined && error.response != null) {
          if (error.response.status == 401) {
            notifyError("Forbidden")
          }
          else {
            notifyError("Server Error")
          }
        }
      })
  }

  function calculateFinalPrice(prevPrice, selectedDiscount) {
    if (!selectedDiscount) return prevPrice

    if (selectedDiscount.discountType === 'discount_by_percentage') {
      const disc = prevPrice * (selectedDiscount.nominal / 100)
      return prevPrice - disc  
    }
    else {
      return prevPrice - selectedDiscount.nominal
    }
  }

  return (
    <div
      className="py-2 bg-white w-full shadow-xl rounded-md divide-y divide-gray-300 relative"
    >
      { model.isProcess && (
        <>
          <div
            className="absolute bg-black z-10 w-full h-full top-0 left-0 opacity-70"
          >
          </div>
          <div
            className="absolute z-20 top-0 left-0 w-full h-full flex items-center justify-center text-blue-500 "
          >
            <RiLoader4Fill className="h-12 w-12 animate-spin"/>
          </div>
        </>
      )}
      
      <div
        className="flex items-center justify-between p-2"
      >
        <div>
          <button
            onClick={() => history.push("/home/paket-bimbel")}
          >
            <RiArrowLeftLine className="h-6 w-6"/>
          </button>
        </div>
        <div
          className="font-bold uppercase"
          style={{
            fontSize: "15px"
          }}
        >
          {
            mode ? 
              mode == 'edit' ? "update data" : "lihat data"
            :
              "Tambah data"
          }
        </div>
      </div>
      <div
        className="pt-4 pb-5 px-4 text-left space-y-5 bg-gray-100"
      >
        { mode && (
          <div
            className="space-y-2"
          >
            <div
              className="font-bold"
              style={{ fontFamily: 'Nunito' }}
            >
              ID Paket Bimbel
            </div>
            <div>
              <input
                className="w-full p-2 rounded-sm border border-gray-400"
                style={{
                  fontSize: '14px'
                }}
                value={model.form.idPaketBimbel}
                readOnly
              />
            </div>
          </div>  
        )}
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
            style={{ fontFamily: 'Nunito' }}
          >
            Nama Paket Bimbel
          </div>
          <div>
            <input
              className="w-full p-2 rounded-sm border border-gray-400"
              style={{
                fontSize: '14px'
              }}
              value={model.form.namaPaketBimbel}
              onChange={e => setModel({ ...model, form: { ...model.form, namaPaketBimbel: e.target.value } })}
            />
          </div>
        </div>
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
            style={{ fontFamily: 'Nunito' }}
          >
            Deskripsi Paket Bimbel
          </div>
          <div>
            <textarea
              className="w-full p-2 rounded-sm border border-gray-400"
              style={{
                fontSize: '14px'
              }}
              value={model.form.description}
              onChange={e => setModel({ ...model, form: { ...model.form, description: e.target.value } })}
              rows={7}
            />
          </div>
        </div>
        <div>
          <div
            className="font-bold"
          >
            Icon
          </div>
          <div>
            <div>
              <img
                src={preview}
                className="rounded-md"
                style={{
                  maxHeight: "300px"
                }}
              />
            </div>
            <div>
              <input
                type="file"
                className="p-3 rounded-lg font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none"
                onChange={onSelectFile}
              />
            </div>
          </div>
        </div>
        <div>
          <div
            className="font-bold"
          >
            Warna
          </div>
          <div className="flex space-x-5">
            <SketchPicker
              color={model.form.color}
              onChangeComplete={color => setModel({ ...model, form: { ...model.form, color: color.hex } })}
            />
            <div className="space-y-3 w-60">
              <div
                className="w-full py-3 px-2 font-bold text-center text-white"
                style={{
                  backgroundColor: model.form.color,
                  fontSize: '18px',
                  fontFamily: 'Nunito'
                }}
              >
                {model.form.namaPaketBimbel}
              </div>
              <div>
                <input
                  className="p-1 rounded-md w-full"
                  value={model.form.color}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
            style={{ fontFamily: 'Nunito' }}
          >
            Program
          </div>
          <div>
            { 
              mode === 'view' ?
                (<input 
                  className="w-full p-2 rounded-sm border border-gray-400"
                  style={{
                    fontSize: '14px'
                  }}
                  value={programOpts.selectedOpt ? programOpts.selectedOpt.label : ''}
                />)
                :
                (<Select
                    value={programOpts.selectedOpt}
                    options={programOpts.data}
                    isLoading={programOpts.isLoad}
                    menuPlacement="auto"
                    className="w-96 border border-gray-200 rounded-md"
                    onFocus={onSelectProgramOpts}
                    onChange={(value) => {
                      setProgramOpts({ ...programOpts, selectedOpt: value })
                    }}
                  />)
            }
          </div>
        </div>
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
            style={{ fontFamily: 'Nunito' }}
          >
            Tersedia di
          </div>
          <div
            className="space-y-2"
          >
            <div
              className="flex items-center space-x-4"
            >
              <button
                onClick={() => setModel({ ...model, form: { ...model.form, isInternal: !model.form.isInternal } })}
                disabled={mode && mode === 'view'}
              >
                { model.form.isInternal ?
                  (<GrCheckboxSelected className="h-5 w-5"/>)
                  :
                  (<GrCheckbox className="h-5 w-5 bg-green-100" />) 
                }
              </button>
              <label>
                Internal (Bimbel ALC)
              </label>
            </div>
            <div
              className="flex items-center space-x-4"
            >
              <button
                onClick={() => setModel({ ...model, form: { ...model.form, isExternal: !model.form.isExternal } })}
                disabled={mode && mode === 'view'}
              >
                { model.form.isExternal ?
                  (<GrCheckboxSelected className="h-5 w-5"/>)
                  :
                  (<GrCheckbox className="h-5 w-5 bg-green-100" />) 
                }
              </button>
              <label>
                External (Kunci Belajar)
              </label>
            </div>
          </div>
        </div>
        <div
          className="flex space-x-10"
        >
          <div className="space-y-3">
            <div className="space-y-2">
              <div
                className="font-bold"
                style={{ fontFamily: 'Nunito' }}
              >
                Harga Paket Bimbel
              </div>
              <div
                className="flex items-center space-x-2"
              >
                <div
                  className="px-2 h-full"
                >
                  Rp
                </div>
                <input
                  style={{
                    fontSize: '14px'
                  }}
                  className="w-[100px] p-2 border border-gray-400"
                  value={formatRupiah(model.form.harga)}
                  onChange={e => setModel({ ...model, form: { ...model.form, harga: unFormatRupiah(e.target.value) } })}
                />
                <label>.00</label>
              </div>
            </div>
            <div
              className="space-y-2"
            > 
              <div
                className="font-bold"
                style={{ fontFamily: 'Nunito' }}
              >
                Harga Akhir
              </div>
              <div
                className="flex items-center space-x-2"
              >
                <div
                  className="px-2 h-full"
                >
                  Rp
                </div>
                <input
                  style={{
                    fontSize: '14px'
                  }}
                  className="w-[100px] p-2 border border-gray-400"
                  value={
                    formatRupiah(
                      calculateFinalPrice(
                        model.form.harga,
                        discountOpts.selectedOpt ? discountOpts.rawData.find(d => d.id === discountOpts.selectedOpt.value) : null
                      )
                    )
                  }
                  readOnly
                />
                <label>.00</label>
              </div>
            </div>
          </div>
          <div className="">
            <div
              className="space-y-2"
            >
              <div
                className="font-bold"
                style={{ fontFamily: 'Nunito' }}
              >
                Pilih Diskon
              </div>
              <div
                className="space-y-2"
              >
                <div
                  className="flex items-center space-x-2"
                >
                  <Select
                    value={discountOpts.selectedOpt}
                    options={discountOpts.data}
                    isLoading={discountOpts.isLoad}
                    menuPlacement="auto"
                    className="w-60 border border-gray-200 rounded-md"
                    onFocus={onSelectDiscounts}
                    onChange={(value) => {
                      setDiscountOpts({ ...discountOpts, selectedOpt: value })
                    }}
                  />
                </div>
                {discountOpts.selectedOpt.value && (
                  <div
                    className="p-2 border border-gray-300 w-60 rounded-md"
                    style={{ fontSize: '14px' }}
                  >
                    <div className="flex space-x-2">
                      <div className="w-16">Nominal</div>
                      <div>:</div>
                      <div>{
                        discountOpts.rawData.find(d => d.id === discountOpts.selectedOpt.value).nominal
                      }</div>
                    </div>
                    <div className="flex space-x-2">
                      <div className="w-16">Jenis</div>
                      <div>:</div>
                      <div>{
                        discountOpts.rawData.find(d => d.id === discountOpts.selectedOpt.value).discountType === 'discount_by_percentage' ?
                        'Persentase'
                        :
                        'Potongan Harga'
                      }</div>
                    </div>
                  </div>
                )}
              </div>
            </div>

          </div>
        </div>
        <div
          className="space-y-2"
        >
          <div
              className="font-bold"
              style={{ fontFamily: 'Nunito' }}
            >
              Skema Cicilan (Jika Ada)
            </div>
            <div
              className="flex items-center space-x-2"
            >
              <Select
                value={skemaCicilanOpts.selectedOpt}
                options={skemaCicilanOpts.data}
                isLoading={skemaCicilanOpts.isLoad}
                menuPlacement="auto"
                className="w-60 border border-gray-200 rounded-md"
                onFocus={onSelectSkemaCicilanOpts}
                onChange={(value) => {
                  setSkemaCicilanOpts({ ...skemaCicilanOpts, selectedOpt: value })
                }}
              /> 
            </div>
        </div>
        <BenefitBox 
          benefits={model.form.benefits}
          onAddNewBenefit={benefit => setModel({ ...model, form: { ...model.form, benefits: [ ...model.form.benefits, benefit ] } })}
          onRemoveBenefit={benefit => setModel({ ...model, form: { ...model.form, benefits: model.form.benefits.filter(b => b !== benefit) } })}
        />
      </div>
      { (!mode || mode != 'view') && (
        <div
          className="pt-4 pb-2 px-2 flex items-center space-x-2"
        >
          <button
            className="px-5 py-2 bg-blue-600 text-white font-bold rounded-md"
            onClick={saveData}
          >
            Simpan
          </button>
        </div>
      )}

    </div>
  )
}

const BenefitBox = ({
  benefits,
  onAddNewBenefit,
  onRemoveBenefit
}) => {

  const [isAddFormOpen, setIsAddFormOpen] = useState(false)
  const [form, setForm] = useState('')

  const Item = ({benefit}) => {
    return (
      <div className="flex">
        <div
          className="flex items-center space-x-2 p-1 rounded-full border border-gray-400"
        >
          <AiOutlineCheck className="text-blue-700 h-5 w-5"/>
          <label>{benefit}</label>
          <button 
            className="p-1 bg-red-500 text-white rounded-full"
            onClick={() => {
              onRemoveBenefit(benefit)
            }}
          >
            <AiOutlineClose />
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="space-y-2">
      <div
        className="flex items-center space-x-2"
      >
        <label className="font-bold">Benefit</label>
        { !isAddFormOpen && (
          <button
            className="p-2 bg-blue-500 text-white"
            onClick={() => {
              setForm('')
              setIsAddFormOpen(true)
            }}
          >
            <AiOutlinePlus className="h-5 w-5"/>
          </button>
        )}
      </div>
      <div
        className="space-y-3"
      >
        { isAddFormOpen && (
          <div
            className="flex pl-3 space-x-1"
          >
            <input
              className="p-1 px-2 border border-gray-400 outline-none rounded-sm" 
              style={{
                fontSize: '14px',
                width: '250px'
              }}
              value={form}
              onChange={e => setForm(e.target.value)}
            />
            <button
              className="p-1 px-2 bg-blue-500 text-white"
              onClick={() => {
                onAddNewBenefit(form)
                setForm('')
                setIsAddFormOpen(false)
              }}
            >
              <AiOutlineCheck />
            </button>
            <button
              className="p-1 px-2 bg-red-500 text-white"
              onClick={() => setIsAddFormOpen(false)}
            >
              <AiOutlineClose />
            </button>
          </div>
        )}

        <div className="space-y-3 pl-3">
          {benefits && benefits.map(b => (<Item benefit={b}/>))}
        </div>
      </div>

    </div>
  )

}