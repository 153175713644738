import { useEffect, useState } from "react"
import { RiArrowLeftLine, RiLoader4Fill, RiLoader4Line } from "react-icons/ri"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import { handleResponse200 } from "../../../utils/HttpUtils"
import { notifyError } from "../../../utils/Notification"
import { createData, getDetail, getOrangtuaIdLabel, getSekolahIdLabel, updateData } from "../Apis/Api"
import Select from "react-select"
import { getKelasIdLabel, getProgramIdLabel } from "../../Member/Apis/Api"
import { AiFillEye, AiFillEyeInvisible} from "react-icons/ai"

export default function FormPage() {
  const history = useHistory()

  const { screenSize } = useSelector(state => state.global)

  const [model, setModel] = useState({
    form: {
      idAnak: '',
      namaAnak: '',
      email: '',
      password: '',
      passwordShown: '',
      asalSekolahLainnya: ''
    },
    isProcess: false
  })

  const [parentOpts, setParentOpts] = useState({
    selectedOpt: {
      label: null,
      value: null
    },
    data: [],
    isLoad: false
  })

  const [kelasOpts, setKelasOpts] = useState({
    selectedOpt: {
      label: null,
      value: null
    },
    data: [],
    isLoad: false
  })

  const [programOpts, setProgramOpts] = useState({
    selectedOpt: {
      label: null,
      value: null
    },
    data: [],
    isLoad: false
  })

  const [sekolahOpts, setSekolahOpts] = useState({
    selectedOpt: {
      label: null,
      value: null
    },
    data: [],
    isLoad: false
  })

  const [jenisKelaminOpts, setJenisKelaminOpts] = useState({
      selectedOpt: { label: 'laki-laki', value: 'laki-laki' },
      data: [ { label: 'laki-laki', value: 'laki-laki' }, { label: 'perempuan', value: 'perempuan' },  ],
      isLoad: false
  })

  const { mode, id } = useParams()

  useEffect(() => {
    if (!mode) return;

    setModel({...model, isProcess: true})
    getDataDetail(id)

  }, [])

  function onSelectParent() {
    setParentOpts({ ...parentOpts, isLoad: true })
    getOrangtuaIdLabel()
      .then(response => {
        if (response.success) {
          const data = response.payload.map(d => ({ label: d.label, value: d.id }))
          setParentOpts({ ...parentOpts, data: data, isLoad: false })
        }
        else {
          setParentOpts({ ...parentOpts, isLoad: false })
        }
      })
      .catch(error => {
        setParentOpts({ ...parentOpts, isLoad: false })
        if (error.response != undefined && error.response != null) {
          if (error.response.status == 401) {
            notifyError('Request Forbidden. Silahkan login')
            history.push("/login")
          }
          else {
            notifyError('Server Error')
          }
        }
      })
  }

  function onSelectKelasOpts() {
      setKelasOpts({ ...kelasOpts, isLoad: true })

      getKelasIdLabel()
          .then(response => {
              if (response.success) {
                  const data = response.payload.map(d => ({ label: d.label, value: d.id }))
                  setKelasOpts({ ...kelasOpts, data: data, isLoad: false, rawData: response.payload })
              }
              else {
                  setKelasOpts({ ...kelasOpts, isLoad: false })
              }
          })
          .catch(error => {
              setKelasOpts({ ...kelasOpts, isLoad: false })
              if (error.response != undefined && error.response != null) {
                  if (error.response.status == 401) {
                  notifyError("Forbidden")
                  }
                  else {
                  notifyError("Server Error")
                  }
              }
          })
  }

  function onSelectProgramOpts(idKelas) {
      setProgramOpts({ ...programOpts, isLoad: true })

      getProgramIdLabel(idKelas)
          .then(response => {
              if (response.success) {
              const data = response.payload.map(d => ({ label: d.label, value: d.id }))
              setProgramOpts({ ...programOpts, data: data, isLoad: false, rawData: response.payload })
              }
              else {
              setProgramOpts({ ...programOpts, isLoad: false })
              }
          })
          .catch(error => {
              setProgramOpts({ ...programOpts, isLoad: false })
              if (error.response != undefined && error.response != null) {
              if (error.response.status == 401) {
                  notifyError("Forbidden")
              }
              else {
                  notifyError("Server Error")
              }
              }
          })
  }

  function onSelectSekolahOpts() {
      setSekolahOpts({ ...sekolahOpts, isLoad: true })

      getSekolahIdLabel()
          .then(response => {
              if (response.success) {
                  const data = response.payload.map(d => ({ label: d.label, value: d.id })).concat([{ label: 'Lainnya...', value: 'lainnya' }])
                  setSekolahOpts({ ...sekolahOpts, data: data, isLoad: false, rawData: response.payload })
              }
              else {
                  setSekolahOpts({ ...sekolahOpts, isLoad: false })
              }
          })
          .catch(error => {
              setSekolahOpts({ ...sekolahOpts, isLoad: false })
              if (error.response != undefined && error.response != null) {
                  if (error.response.status == 401) {
                      notifyError("Forbidden")
                  }
                  else {
                      notifyError("Server Error")
                  }
              }
          })
  }

  function getDataDetail(idData) {
    getDetail(idData)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setModel(latestM => ({ 
              ...latestM, 
              form: { 
                idAnak: payload.idAnak,
                namaAnak: payload.nama,
                email: payload.email,
                asalSekolahLainnya: payload.sekolahLainnya
              } 
            }))

            setParentOpts({
              ...parentOpts,
              selectedOpt: { label: payload.orangtua.label, value: payload.orangtua.id }
            })

            setKelasOpts({
              ...kelasOpts,
              selectedOpt: { label: payload.kelas.label, value: payload.kelas.id }
            })

            setProgramOpts({
              ...programOpts,
              selectedOpt: { label: payload.program.label, value: payload.program.id }
            })

            setSekolahOpts({
              ...sekolahOpts,
              selectedOpt: payload.sekolah ? { label: payload.sekolah.label, value: payload.sekolah.id } : { label: 'Lainnya', value: 'lainnya' }
            })
            
            setJenisKelaminOpts({
              ...jenisKelaminOpts,
              selectedOpt: { label: payload.jenisKelamin === 'perempuan' ? 'Perempuan' : 'Laki-laki', value: payload.jenisKelamin }
            })

          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))

  }

  function saveData() {
    setModel({ ...model, isProcess: true })

    const payload = {
      idAnak: model.form.idAnak,
      namaAnak: model.form.namaAnak,
      email: model.form.email,
      password: model.form.password,
      idOrangtua: parentOpts.selectedOpt ? parentOpts.selectedOpt.value : null,
      idKelas : kelasOpts.selectedOpt ? kelasOpts.selectedOpt.value : null,
      idProgram : programOpts.selectedOpt ? programOpts.selectedOpt.value : null,
      idSekolah : sekolahOpts.selectedOpt ? sekolahOpts.selectedOpt.value : null,
      asalSekolahLainnya : model.form.asalSekolahLainnya,
      jenisKelamin : jenisKelaminOpts.selectedOpt.value
    }

    if (mode) {
      updateData(payload)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
              history.push("/home/anak")
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))

    }
    else {
      createData(payload)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
              history.push("/home/anak")
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))
    }
  }


  return (
    <div
      className="bg-white w-full shadow-xl rounded-md divide-y divide-gray-300 relative"
    >
      { model.isProcess && (
        <>
          <div
            className="absolute bg-black z-10 w-full h-full top-0 left-0 opacity-70"
          >
          </div>
          <div
            className="absolute z-20 top-0 left-0 w-full h-full flex items-center justify-center text-blue-500 "
          >
            <RiLoader4Fill className="h-12 w-12 animate-spin"/>
          </div>
        </>
      )}
      
      <div
        className="flex items-center justify-between p-2 pt-3"
      >
        <div>
          <button
            onClick={() => history.push("/home/anak")}
          >
            <RiArrowLeftLine className="h-6 w-6"/>
          </button>
        </div>
        <div
          className="font-bold uppercase"
          style={{
            fontSize: "15px"
          }}
        >
        </div>
      </div>
      <div
        className="pt-4 pb-6 px-4 text-left space-y-3"
      >
        { mode && (
          <div
            className="space-y-2"
          >
            <div
              className="font-bold"
            >
              ID Anak
            </div>
            <div>
              <input
                className="w-full p-2 rounded-sm border border-gray-400"
                value={model.form.idAnak}
                readOnly
              />
            </div>
          </div>  
        )}
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
          >
            Nama Anak
          </div>
          <div>
            <input
              className="w-full p-2 rounded-sm border border-gray-400"
              value={model.form.namaAnak}
              onChange={e => setModel({ ...model, form: { ...model.form, namaAnak: e.target.value } })}
            />
          </div>
        </div>
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
          >
            Orangtua
          </div>
          <div>
            <Select
              value={parentOpts.selectedOpt}
              options={parentOpts.data}
              isLoading={parentOpts.isLoad}
              menuPlacement="auto"
              className="w-full border border-gray-200 rounded-md"
              onFocus={onSelectParent}
              onChange={(value) => {
                setParentOpts({ ...parentOpts, selectedOpt: value })
              }}
            />
          </div>
        </div>
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
          >
            Kelas
          </div>
          <div>
            <Select
              value={kelasOpts.selectedOpt}
              options={kelasOpts.data}
              isLoading={kelasOpts.isLoad}
              menuPlacement="auto"
              onFocus={onSelectKelasOpts}
              onChange={(value) => {
                  setKelasOpts({ ...kelasOpts, selectedOpt: value })
                  setProgramOpts({ ...programOpts, selectedOpt: null })
              }}
              className="w-full border border-gray-200 rounded-md"
              readOnly
            />
          </div>
        </div>
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
          >
            Program
          </div>
          <div>
            <Select
              value={programOpts.selectedOpt}
              options={programOpts.data}
              isLoading={programOpts.isLoad}
              menuPlacement="auto"
              onFocus={() => {
                  if (kelasOpts.selectedOpt) {
                    onSelectProgramOpts(kelasOpts.selectedOpt.value)
                  }
              }}
              onChange={(value) => {
                  setProgramOpts({ ...programOpts, selectedOpt: value })
              }}
              className="w-full border border-gray-200 rounded-md"
            />
          </div>
        </div>
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
          >
            Asal Sekolah
          </div>
          <div className="space-y-1">
            <div>
              <Select
                  value={sekolahOpts.selectedOpt}
                  options={sekolahOpts.data}
                  isLoading={sekolahOpts.isLoad}
                  menuPlacement="auto"
                  className="w-full border border-gray-200 rounded-md"
                  onFocus={onSelectSekolahOpts}
                  onChange={(value) => {
                      setSekolahOpts({ ...sekolahOpts, selectedOpt: value })
                      setModel({ ...model, form: { ...model.form, asalSekolahLainnya: '' } })
                  }}
              />
            </div>
          </div>
        </div>
        {sekolahOpts.selectedOpt && sekolahOpts.selectedOpt.value === 'lainnya' && (
                <div
                    className="space-y-2"
                >
                    <div
                        style={{ fontSize: '13px' }}
                    >
                        Nama Asal Sekolah
                    </div>
                    <div>
                        <input 
                            className={
                                'w-full py-1 px-2 border-2 rounded-md outline-none ' +
                                (model.form.asalSekolahLainnya && model.form.asalSekolahLainnya !== '' ? ' ' : ' ')
                            }
                            value={model.form.asalSekolahLainnya}
                            onChange={e => setModel({ ...model, form: { ...model.form, asalSekolahLainnya: e.target.value } })}
                        />
                    </div>
                </div>
        )}
        <div
            className="space-y-2"
        >
            <div
                className="font-bold"
            >
                Jenis Kelamin
            </div>
            <div>
                <Select
                    value={jenisKelaminOpts.selectedOpt}
                    options={jenisKelaminOpts.data}
                    isLoading={jenisKelaminOpts.isLoad}
                    menuPlacement="auto"
                    className="w-full border border-gray-200 rounded-md"
                    onChange={(value) => {
                        setJenisKelaminOpts({ ...jenisKelaminOpts, selectedOpt: value })
                    }}
                />
            </div>
        </div>
        { !mode && (
          <div
            className="space-y-2"
          >
            <div
              className="font-bold"
            >
              Password
            </div>
            {/* <div>
              <input
                type={"password"}
                className="w-full p-2 rounded-sm border border-gray-400"
                value={model.form.password}
                onChange={e => setModel({ ...model, form: { ...model.form, password: e.target.value } })}
              />

            </div> */}
            <div
                className={
                    'w-full py-2 px-2 border-2 rounded-md outline-none font-bold flex ' +
                    (model.form.password !== null && model.form.password !== '' ? '  ' : '  ')
                }
            >
                <input 
                    type={model.form.passwordShown ? 'text' : 'password'}
                    className={
                        'w-full rounded-md outline-none font-bold ' +
                        (model.form.password !== null && model.form.password !== '' ? '  ' : '  ')
                    }
                    style={{ fontSize: '15px', fontFamily: 'Nunito' }}
                    value={model.form.password}
                    // onChange={e => setForm({ ...form, password: e.target.value })}
                    onChange={e => setModel({ ...model, form: { ...model.form, password: e.target.value } })}
                />
                <button
                    onClick={() => setModel({ ...model, form: { ...model.form, passwordShown: !model.form.passwordShown } })}
                >
                    { model.form.passwordShown ?
                        <AiFillEye className='h-6 w-6 text-blue-700'/>
                        :
                        <AiFillEyeInvisible className='h-6 w-6 text-blue-700'/>
                    }
                </button>
            </div>
          </div>
        )}
        
      </div>
      { (!mode || mode != 'view') && (
        <div
          className="pt-4 pb-2 px-2 flex items-center space-x-2"
        >
          <button
            className="px-5 py-2 bg-blue-600 text-white font-bold rounded-md"
            onClick={saveData}
          >
            Simpan
          </button>
        </div>
      )}

    </div>
  )
}