import React, { useEffect, useState } from "react";
import { RiArrowLeftLine, RiCheckLine, RiLoader5Fill } from "react-icons/ri";
import { BsTrashFill } from "react-icons/bs"
import { useDispatch } from "react-redux";
import { setIsHomeProcess } from "../../../app/GlobalSlice";
import { handleResponse200 } from "../../../utils/HttpUtils";
import { notifyError, notifySuccess } from "../../../utils/Notification";
import { addSoal, deleteSoal, getDetailSoal } from "../Apis/Api";

const initModel = {
  isLoadData: false,
  soal: null,
  jenjang: null,
  mapel: null,
  materi: null,
  pilihanJawaban: []
}

function Latex(props) {
  let node = React.createRef();
  useEffect(() => {
    renderMath();
  });
  const renderMath = () => {
    window.MathJax.Hub.Queue([
      "Typeset",
      window.MathJax.Hub,
      node.current
    ]);
  }
  return (
    <div ref={node}>
      {props.children}
    </div>
  );
}

function SoalBox({
  soal,
  jenjang,
  mapel,
  materi,
  pilihanJawaban
}) {
  function renderTex(htmlStr) {
    var obj = document.createElement('div')
    obj.innerHTML = htmlStr

    var texs = obj.getElementsByClassName("math-tex")
    // console.log(obj)
    Array.from(texs).forEach(oldEl => {
      const newEl = document.createElement("MathJax")
      newEl.innerHTML = oldEl.innerHTML

      oldEl.parentNode.replaceChild(newEl, oldEl)
    })

    return obj.outerHTML

  }

  if (!soal || !jenjang || !materi || !mapel) return null;

  return (
    <div>
      <div className="px-2 py-2 pb-3 border-b space-y-2">
        <div
          className={"px-2 grid grid-cols-3 gap-x-2"}
        >
          <div className="text-left space-y-1">
            <div>
              Kode Soal
            </div>
            <div>
              <input
                className="w-full border p-2 py-1 outline-none text-left bg-gray-200 rounded-md"
                value={soal.kodeSoal}
                readOnly
              />
            </div>
          </div>
          <div className="text-left space-y-1">
            <div>
              Jenjang
            </div>
            <div>
              <input
                className="w-full border p-2 py-1 outline-none text-left bg-gray-200 rounded-md"
                value={jenjang.namaJenjang}
                readOnly
              />
            </div>
          </div>
          <div className="text-left space-y-1">
            <div>
              Mapel
            </div>
            <div>
              <input
                className="w-full border p-2 py-1 outline-none text-left bg-gray-200 rounded-md"
                value={mapel.namaMapel}
                readOnly
              />
            </div>
          </div>
        </div>
        <div
          className={"px-2"}
        >
          <div className="text-left space-y-1">
            <div>
              Materi
            </div>
            <div>
              <input
                className="w-full border p-2 py-1 outline-none text-left bg-gray-200 rounded-md"
                value={materi.namaMateri}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="py-2 px-4 pb-5 border-b"
      >
        <div className="text-left space-y-1">
          <div>
            Soal
          </div>
          <div>
            <div
              className="p-3 border border-gray-400 rounded-md text-left overflow-auto"
              style={{
                height: "350px"
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: renderTex(soal.redaksiSoal) }} />
            </div>
          </div>
        </div>
      </div>
      <div
        className="py-2 pb-5 px-4 border-b"
      >
        <div className="text-left space-y-2">
          <div>
            Pilihan Jawaban
          </div>
          <div className="space-y-3">
            {pilihanJawaban.map(j => (
              <div className="space-y-1">
                <div>Label : {j.label}</div>
                <div>

                  <div
                    className="p-3 border border-gray-400 rounded-md text-left overflow-auto"
                    style={{
                      height: "200px"
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: renderTex(j.jawaban) }} />
                  </div>

                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="py-2 pb-2 px-4 border-b"
      >
        <div className="text-left space-y-1">
          <div>
            Kunci Jawaban
          </div>
          <div>
            {pilihanJawaban.find(j => j.kodeJawaban === soal.kunciJawaban) ?
              pilihanJawaban.find(j => j.kodeJawaban === soal.kunciJawaban).label
              :
              '-'
            }
          </div>
        </div>
      </div>
      <div
        className="py-2 pb-10 px-4 border-b"
      >
        <div className="text-left space-y-2">
          <div>
            Pembahasan
          </div>
          <div>
            <div
              className="p-3 border border-gray-400 rounded-md text-left overflow-auto"
              style={{
                height: "200px"
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: renderTex(soal.solutionText) }} />
            </div>
          </div>
        </div>
      </div>

    </div>
  )

}

export default function QuestionDetailBox({
  kodePaketSoal,
  idTahapUjian,
  kodeSoal,
  noSoal,
  onBack,
  onSuccessAddedQuestion,
  isAddedMode,
  maxQuestionNumber,
  onSuccessDeleteQuestion
}) {

  const dispatch = useDispatch()

  const [model, setModel] = useState(initModel)

  useEffect(() => {

    setModel({ ...model, isLoadData: true })

    getDetailSoal(kodeSoal)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setModel(latestM => ({
              ...latestM,
              soal: payload.soal,
              jenjang: payload.jenjang,
              mapel: payload.mapel,
              materi: payload.materi,
              pilihanJawaban: payload.pilihanJawaban
            }))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => { setModel(latestM => ({ ...latestM, isLoadData: false })) })

  }, [kodeSoal])

  function onChooseQuestion() {
    const payload = {
      kodePaketSoal: kodePaketSoal,
      idTahapUjian: idTahapUjian ? idTahapUjian : null,
      kodeSoal: kodeSoal,
      noSoal: noSoal
    }

    dispatch(setIsHomeProcess(true))

    addSoal(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            onSuccessAddedQuestion()
            notifySuccess("Berhasil tambah soal")
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => { dispatch(setIsHomeProcess(false)) })

  }

  function onDeleteSoal(kodeSoal, kodePaketSoal) {
    const payload = {
      kodePaketSoal: kodePaketSoal,
      idTahapUjian: idTahapUjian ? idTahapUjian : null,
      kodeSoal: kodeSoal
    }

    dispatch(setIsHomeProcess(true))
    
    deleteSoal(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            notifySuccess("Berhasil hapus soal")
            onSuccessDeleteQuestion()
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => {})
      .finally(() => { dispatch(setIsHomeProcess(false)) })

  }

  return (
    <div
      className="h-full bg-white"
    >
      {isAddedMode && (
        <div
          className="py-1.5 flex items-center justify-between space-x-2 border-b"
        >
          <div className="flex items-center space-x-2">
            <button
              className="p-1.5"
              onClick={onBack}
            >
              <RiArrowLeftLine className="h-6 w-6" />
            </button>
            <label>Detail Soal</label>
          </div>
          <div className="px-5">
            <button
              className="p-2 hover:bg-gray-300 rounded-md"
              onClick={onChooseQuestion}
            >
              <RiCheckLine className="h-6 w-6" />
            </button>
          </div>
        </div>
      )}
      { model.isLoadData ?
        (<div className="w-full h-full flex items-center justify-center">
          <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
        </div>)
        :
        (
          <>
          <div
            className="flex items-center justify-between px-5 pt-5 pb-2 border-b border-gray-400"
          >
            <div
              className="space-x-2"
            >
              <label>No Soal</label>
              <label>:</label>
              <label>{noSoal}</label>
            </div>
            <div>
              {noSoal == maxQuestionNumber && (
                <button
                  className="text-red-600 p-2 hover:bg-red-500 hover:text-white rounded-md hover:shadow-lg"
                  title="Hapus soal"
                  onClick={() => onDeleteSoal(model.soal.kodeSoal, kodePaketSoal)}
                >
                  <BsTrashFill className="h-5 w-5" />
                </button>
              )}
            </div>
          </div>
          <Latex>
            <SoalBox
              soal={model.soal}
              jenjang={model.jenjang}
              mapel={model.mapel}
              materi={model.materi}
              pilihanJawaban={model.pilihanJawaban}
            />
          </Latex>
          </>
        )
      }

    </div>
  )

}