import { Transition } from "@headlessui/react"

import {
  RiAddLine,
  RiArrowLeftLine,
  RiCloseFill,
  RiEdit2Line,
  RiEye2Fill,
  RiEyeCloseFill,
  RiEyeFill,
  RiFilePaperLine,
  RiLoader5Fill,
  RiSearchLine
} from "react-icons/ri";
import { MdSort } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BsChevronLeft, BsChevronRight, BsTrash } from "react-icons/bs"

import { format } from "date-fns"
import { useEffect, useState } from "react";
import {
  closeDeleteDialog,
  closeFormDialog,
  dataPropertOrdToStr,
  goToNextPage,
  goToPrevPage,
  hasNextPage,
  hasPrevPage,
  initModel,
  openAddFormPage,
  openDeleteDialog,
  openDetailFormPage,
  openEditFormPage,
  resetSearchText,
  setData,
  setDataSummary,
  setFinishRefetchData,
  setFinishRefetchDataSummary,
  setIsLoadData,
  setSearchText,
  setSortFromNewestData,
  setSortFromOldestData,
  setStartRefetchData,
  setStartRefetchDataSummary
} from "../Models/ManagePaketBimbelModel";
import {
  addPaketBimbel,
  getAddedPaketBimbel,
  getUnAddedPaketBimbel,
  removePaketBimbel
} from "../Apis/Api";
import { handleResponse200 } from "../../../utils/HttpUtils"
import { useDispatch } from "react-redux";
import { setHomeActivePage } from "../../../app/GlobalSlice";
import { notifyError } from "../../../utils/Notification";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

function DataTableFooter({
  pageNum,
  showCount,
  sortMode,
  searchText,
  hasNextPage,
  hasPrevPage,
  onGoToNextPage,
  onGoToPrevPage
}) {
  return (
    <div
      className="bg-white py-1 px-3 shadow-lg flex items-center justify-between rounded-md"
      style={{
        fontSize: "14px",
        height: "45px"
      }}
    >
      <div className="flex space-x-2">
        <label>
          Hal. {pageNum},
        </label>
        <label>
          Tampil: {showCount},
        </label>
        <label>
          Mode pengurutan:  {sortMode},
        </label>
        <label>
          Pencarian: {searchText && searchText !== '' ? searchText : '-'}
        </label>
      </div>
      <div className="flex">
        {hasPrevPage && (
          <button
            className=" p-2 rounded-lg hover:bg-gray-200"
            title={"Ke Halaman sebelumnya"}
            onClick={onGoToPrevPage}
          >
            <BsChevronLeft className="h-5 w-5" />
          </button>
        )}
        {hasNextPage && (
          <button
            className=" p-2 rounded-lg hover:bg-gray-200"
            title={"Ke Halaman selanjutnya"}
            onClick={onGoToNextPage}
          >
            <BsChevronRight className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  )
}

function ItemBox({
  no,
  idPaketBimbel,
  namaPaketBimbel,
  createdAt,
  updatedAt,
  isActive,
  onAddData
}) {

  return (
    <div
      className="py-1 w-full bg-white group hover:bg-blue-500 flex shadow-lg divide-x divide-gray-300 border-b border-gray-200"
      style={{
        fontSize: "14px"
      }}
    >
      <div 
        className="flex px-2 group-hover:text-white items-center " 
        style={{ 
          width: "4%",
          minWidth: "50px"
        }}
      >
        {no}
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "12%",
          minWidth: "150px"
        }}
      >
        {idPaketBimbel}
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "39%",
          minWidth: "200px"
        }}
      >
        {namaPaketBimbel}
      </div>
      <div 
        className="flex px-2 items-center justify-center group-hover:text-white" 
        style={{ 
          width: "10%",
          minWidth: "130px"
        }}
      >
        {format(new Date(createdAt), "dd/MM/yyyy")}
      </div>
      <div 
        className="flex px-2 items-center justify-center group-hover:text-white" 
        style={{ 
          width: "10%",
          minWidth: "130px"
        }}
      >
        {updatedAt ? format(new Date(updatedAt), "dd/MM/yyyy") : '-'}
      </div>
      <div
        className="flex px-2 items-center justify-center group-hover:text-white"
        style={{ 
          width: "10%",
          minWidth: "130px" 
        }}
      >
        <label
          className={
            "rounded-md px-3 text-white " +
            (isActive ? " bg-green-500 " : " bg-red-500 ")
          }
        >
          {isActive ? 'Aktif' : 'Tdk Aktif'}
        </label>
      </div>
      <div 
        className="px-3 flex justify-center" 
        style={{ 
          width: "15%",
          minWidth: "195px" 
        }}
      >
        <button
          className="p-1 px-5 rounded-lg bg-blue-500 text-white hover:bg-black text-black group-hover:text-white"
          onClick={onAddData}
        >
          Tambah
        </button>
      </div>
    </div>
  )
}

function HeaderTable() {
  return (
    <div
      className="w-full bg-white flex items-center shadow-lg divide-x divide-gray-300 border-b bg-gray-100 font-bold"
      style={{
        fontSize: "15px"
      }}
    >
      <div 
        className="flex px-2 py-2 group-hover:text-white items-center " 
        style={{ 
          width: "4%",
          minWidth: "50px" 
        }}
      >
        No
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "12%",
          minWidth: "150px"
        }}
      >
        Kode
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "39%",
          minWidth: "200px"
        }}
      >
        Paket
      </div>
      <div 
        className="flex px-2 items-center justify-center group-hover:text-white" 
        style={{ 
          width: "10%",
          minWidth: "130px"
        }}
      >
        Tgl input
      </div>
      <div 
        className="flex px-2 items-center justify-center group-hover:text-white" 
        style={{ 
          width: "10%",
          minWidth: "130px" 
        }}
      >
        Tgl update
      </div>
      <div
        className="flex px-2 items-center justify-center group-hover:text-white"
        style={{ 
          width: "10%",
          minWidth: "130px" 
        }}
      >
        Status
      </div>
      <div 
        className="px-3 flex justify-center" 
        style={{ 
          width: "15%",
          minWidth: "195px" 
        }
      }>
        #
      </div>
    </div>
  )
}

function DataTable({
  isLoadData,
  datas,
  rootModel,
  setRootModel,
  idAnak
}) {

  const history = useHistory()

  return (
    <div className="space-y-3">
      <div className="overflow-x-auto bg-white">
        <HeaderTable />
        {
          isLoadData ?
            (<div
              className="flex items-center justify-center text-blue-600 bg-white"
              style={{ minHeight: "50px" }}
            >
              <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
            </div>)
            :
            datas.length > 0 ?
              (<div>
                {datas.map((d, idx) => (
                  <ItemBox
                    no={idx + 1}
                    idPaketBimbel={d.idPaketBimbel}
                    namaPaketBimbel={d.namaPaketBimbel}
                    createdAt={d.createdAt}
                    updatedAt={d.updatedAt}
                    isActive={d.isActive}
                    onAddData={() => onAddData(rootModel, setRootModel, history, d.idPaketBimbel, idAnak)}
                  />
                ))}
              </div>)
              :
              (<div className="py-2 bg-white">
                Tidak ada data
              </div>)
        }
      </div>
      <DataTableFooter
        pageNum={(rootModel.dataProperties.offset / rootModel.dataProperties.limit) + 1}
        showCount={rootModel.dataList.length}
        sortMode={dataPropertOrdToStr(rootModel)}
        searchText={rootModel.searchHeader.searchText}
        hasNextPage={hasNextPage(rootModel)}
        hasPrevPage={hasPrevPage(rootModel)}
        onGoToPrevPage={() => setRootModel(goToPrevPage(rootModel))}
        onGoToNextPage={() => setRootModel(goToNextPage(rootModel))}
      />
    </div>
  )
}

export default function UnAddedPaketBimbelComp() {
  const dispatch = useDispatch()
  const history = useHistory()

  const {id} = useParams()

  const [rootModel, setRootModel] = useState(initModel)

  useEffect(() => {
    dispatch(setHomeActivePage('anak'))
  }, [])

  useEffect(() => {
    onGetData(rootModel, setRootModel, history, id)
  }, [rootModel.searchHeader.searchText, rootModel.dataProperties])

  useEffect(() => {
    if (rootModel.refetchData) {
      onGetData(rootModel, setRootModel, history, id)
    }
  }, [rootModel.refetchData])

  return (
    <div className="space-y-3 w-full">
      <DataTable
        isLoadData={rootModel.isLoadData}
        rootModel={rootModel}
        setRootModel={setRootModel}
        datas={rootModel.dataList}
        idAnak={id}
      />
    </div>
  )
}

function onGetData(rootModel, setRootModel, history, idPaketBimbel) {
  const payload = {
    search: rootModel.searchHeader.searchText,
    offset: rootModel.dataProperties.offset,
    limit: 500,
    orderBy: rootModel.dataProperties.ord
  }

  setRootModel(setIsLoadData(rootModel, true))

  getUnAddedPaketBimbel(idPaketBimbel, payload)
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM => setData(latestM, payload))
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          history.push("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => {
      setRootModel(latestM => setIsLoadData(setFinishRefetchData(latestM), false))
    })

}

function onAddData(rootModel, setRootModel, history, idPaketBimbel, idAnak) {
  const payload = {
    idPaketBimbel: idPaketBimbel,
    idAnak: idAnak
  }

  setRootModel(setIsLoadData(rootModel, true))

  addPaketBimbel(payload)
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM => setStartRefetchData(latestM))
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          history.push("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => {
      setRootModel(latestM => setIsLoadData(latestM, false))
    })

}