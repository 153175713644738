import axios from "axios"

export const getData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/ujian/data-table"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getUjianDetail = async (idUjianPeserta) => {
  const finalUrl =
    window._HOST + "admin/ujian/detail/"+idUjianPeserta

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const getAnsweredQuestionEvaluation = async (kodeUjian) => {
  const finalUrl =
    window._HOST + "admin/ujian-evaluasi/" + kodeUjian

  const response = await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const getExamQuestionEvaluationDetail = async (kodeUjianPeserta, kodeSoal) => {
  const finalUrl =
    window._HOST + "admin/participant-answer-evaluation/" + kodeUjianPeserta + "/" + kodeSoal

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const getProgramIdLabel = async () => {
  const finalUrl = window._HOST + "admin/program/id-label"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}