import { Transition } from "@headlessui/react"

import {
  RiAddLine,
  RiArrowLeftLine,
  RiCloseFill,
  RiEdit2Line,
  RiEye2Fill,
  RiEyeCloseFill,
  RiEyeFill,
  RiFileExcel2Fill,
  RiFilePaperLine,
  RiLoader5Fill,
  RiSearchLine
} from "react-icons/ri";
import { MdSort } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BsChevronLeft, BsChevronRight, BsFillGridFill, BsTrash } from "react-icons/bs"

import { format } from "date-fns"
import { useEffect, useState } from "react";
import {
  closeDeleteDialog,
  closeFormDialog,
  dataPropertOrdToStr,
  goToNextPage,
  goToPrevPage,
  hasNextPage,
  hasPrevPage,
  initModel,
  openAddFormPage,
  openDeleteDialog,
  openDetailFormPage,
  openEditFormPage,
  resetSearchText,
  setData,
  setDataSummary,
  setDateParam,
  setFinishRefetchData,
  setFinishRefetchDataSummary,
  setIsLoadData,
  setSearchText,
  setSortFromNewestData,
  setSortFromOldestData,
  setStartRefetchData,
  setStartRefetchDataSummary
} from "../Models/Model";
import {
  createData,
  deleteData,
  getData,
  getDataSummary,
  getProgramIdLabel,
  updateData,
  updateDataActivation
} from "../Apis/Api";
import { handleResponse200 } from "../../../utils/HttpUtils"
import { useDispatch } from "react-redux";
import { setHomeActivePage, setIsHomeProcess } from "../../../app/GlobalSlice";
import { notifyError } from "../../../utils/Notification";
import { useHistory } from "react-router";

import { GoCalendar } from "react-icons/go"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { registerLocale } from "react-datepicker";
import id from 'date-fns/locale/id'
import Select from "react-select"

registerLocale(id)

function DataTableFooter({
  pageNum,
  showCount,
  sortMode,
  searchText,
  hasNextPage,
  hasPrevPage,
  onGoToNextPage,
  onGoToPrevPage
}) {
  return (
    <div
      className="bg-white py-1 px-3 shadow-lg flex items-center justify-between rounded-md"
      style={{
        fontSize: "14px",
        height: "45px"
      }}
    >
      <div className="flex space-x-2">
        <label>
          Hal. {pageNum},
        </label>
        <label>
          Tampil: {showCount},
        </label>
        <label>
          Mode pengurutan:  {sortMode},
        </label>
        <label>
          Pencarian: {searchText && searchText !== '' ? searchText : '-'}
        </label>
      </div>
      <div className="flex">
        {hasPrevPage && (
          <button
            className=" p-2 rounded-lg hover:bg-gray-200"
            title={"Ke Halaman sebelumnya"}
            onClick={onGoToPrevPage}
          >
            <BsChevronLeft className="h-5 w-5" />
          </button>
        )}
        {hasNextPage && (
          <button
            className=" p-2 rounded-lg hover:bg-gray-200"
            title={"Ke Halaman selanjutnya"}
            onClick={onGoToNextPage}
          >
            <BsChevronRight className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  )
}

function ItemBox({
  no,
  idUjianPeserta,
  startedAt,
  deadline,
  finishedAt,
  idParticipant,
  namaParticipant,
  idPaketSoal,
  namaPaketSoal,
  isMultipleTest,
  onClickDetail
}) {
  const rowClassName = "flex px-2 py-2 items-center bg-white border-b border-gray-200 "

  return (
    <div
      className="w-full flex shadow-lg divide-x divide-gray-300"
      style={{
        fontSize: "14px",
        fontFamily: 'Nunito'
      }}
    >
      <div 
        className={rowClassName} 
        style={{ 
          width: "4%",
          minWidth: "50px"
        }}
      >
        {no}
      </div>
      <div 
        className={rowClassName + ' justify-center '} 
        style={{ 
          width: "10%",
          minWidth: "150px"
        }}
      >
        {idUjianPeserta}
      </div>
      <div 
        className={rowClassName + ' justify-center '} 
        style={{ 
          width: "10%",
          minWidth: "150px"
        }}
      >
        {format(new Date(startedAt), "dd/MM/yyyy HH:mm")}
      </div>
      <div 
        className={rowClassName + ' justify-center '} 
        style={{ 
          width: "10%",
          minWidth: "150px"
        }}
      >
        {format(new Date(deadline), "dd/MM/yyyy HH:mm")}
      </div>
      <div 
        className={rowClassName + ' justify-center '} 
        style={{ 
          width: "10%",
          minWidth: "150px"
        }}
      >
        {finishedAt ? format(new Date(finishedAt), "dd/MM/yyyy HH:mm") : '-'}
      </div>
      <div 
        className={rowClassName} 
        style={{ 
          width: "20%",
          minWidth: "250px"
        }}
      >
        <div className="text-left">
          <div style={{ fontSize: '12px' }}>{idParticipant}</div>
          <div>{namaParticipant}</div>
        </div>
      </div>
      <div 
        className={rowClassName} 
        style={{ 
          width: "20%",
          minWidth: "250px"
        }}
      >
        <div className="text-left">
          <div style={{ fontSize: '12px' }}>{idPaketSoal}</div>
          <div>{namaPaketSoal}</div>
        </div>
      </div>
      <div 
        className={rowClassName + ' justify-center '} 
        style={{ 
          width: "10%",
          minWidth: "150px"
        }}
      >
        {isMultipleTest ? 'Multiple Test' : 'Single Test'}
      </div>
      <div 
        className={rowClassName + ' justify-center '} 
        style={{ 
          width: "10%",
          minWidth: "180px"
        }}
      >
        <button
          className="px-8 py-1 rounded-full bg-blue-500 text-white hover:bg-blue-700 hover:shadow-lg"
          onClick={onClickDetail}
        >
          Lihat Detail
        </button>
      </div>
    </div>
  )
} 

function HeaderTable() {

  const styles = "px-2 py-2 group-hover:text-white bg-gray-100 border-b"

  return (
    <div
      className="w-full bg-white flex items-center shadow-lg divide-x divide-gray-300 font-bold"
      style={{
        fontSize: "15px",
        fontFamily: 'Nunito'
      }}
    >
      <div 
        className={styles + " flex items-center justify-center "} 
        style={{ 
          width: "4%",
          minWidth: "50px" 
        }}
      >
        No
      </div>
      <div
        className={styles + " flex items-center justify-center "}
        style={{
          width: "10%",
          minWidth: "150px"
        }}
      >
        Kode Ujian
      </div>
      <div
        className={styles + " flex items-center justify-center "}
        style={{
          width: "10%",
          minWidth: "150px"
        }}
      >
        Waktu Mulai
      </div>
      <div
        className={styles + " flex items-center justify-center "}
        style={{
          width: "10%",
          minWidth: "150px"
        }}
      >
        Deadline
      </div>
      <div
        className={styles + " flex items-center justify-center "}
        style={{
          width: "10%",
          minWidth: "150px"
        }}
      >
        Waktu Selesai
      </div>
      <div
        className={styles + " flex items-center justify-center "}
        style={{ 
          width: "20%",
          minWidth: "250px" 
        }}
      >
        Peserta
      </div>
      <div
        className={styles + " flex items-center justify-center "}
        style={{ 
          width: "20%",
          minWidth: "250px" 
        }}
      >
        Paket Soal
      </div>
      <div
        className={styles + " flex items-center justify-center "}
        style={{ 
          width: "10%",
          minWidth: "150px" 
        }}
      >
        Jenis
      </div>
      <div
        className={styles + " flex items-center justify-center "}
        style={{ 
          width: "10%",
          minWidth: "180px" 
        }}
      >
        #
      </div>
    </div>
  )
}

function DataTable({
  isLoadData,
  datas,
  rootModel,
  setRootModel
}) {

  const history = useHistory()

  return (
    <div className="space-y-3">
      <div className="overflow-x-auto bg-white">
        <HeaderTable />
        {
          isLoadData ?
            (<div
              className="flex items-center justify-center text-blue-600 bg-white"
              style={{ minHeight: "50px" }}
            >
              <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
            </div>)
            :
            datas.length > 0 ?
              (<div>
                {datas.map((d, idx) => (
                  <ItemBox
                    no={idx + 1}
                    idUjianPeserta={d.idUjianPeserta}
                    startedAt={d.startedAt}
                    deadline={d.deadline}
                    finishedAt={d.finishedAt}
                    idParticipant={d.participant.id}
                    namaParticipant={d.participant.label}
                    idPaketSoal={d.paketSoal.id}
                    namaPaketSoal={d.paketSoal.label}
                    isMultipleTest={d.isMultipleTest}
                    onClickDetail={() => history.push('/home/ujian-detail/'+d.idUjianPeserta)}
                  />
                ))}
              </div>)
              :
              (<div className="py-2 bg-white">
                Tidak ada data
              </div>)
        }
      </div>
    </div>
  )

}

const ParamBox = ({
  onParamChanged
}) => {

  const date = new Date()
  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1))
  const [untilDate, setUntilDate] = useState(date)

  const [programOpts, setProgramOpts] = useState({
    selectedOpt: { label: "Semua", value: null },
    data: [],
    isLoad: false
  })

  useEffect(() => {

    onParamChanged(toIsoDate(startDate), toIsoDate(untilDate), programOpts.selectedOpt.value)

  }, [startDate, untilDate, programOpts.selectedOpt])

  function toIsoDate(date) {
    return date.getFullYear() + '-' + padZero(date.getMonth() + 1) + '-' + padZero(date.getDate().toString())
  }

  function padZero(n) {
    return String(n).padStart(2, '0')
  }

  function onSelectProgramOpts() {
    setProgramOpts({ ...programOpts, isLoad: true })

    getProgramIdLabel()
      .then(response => {
        if (response.success) {
          const data = [{ label: "Semua", value: null }].concat(response.payload.map(d => ({ label: d.label, value: d.id })))
          setProgramOpts({ ...programOpts, data: data, isLoad: false })
        }
        else {
          setProgramOpts({ ...programOpts, isLoad: false })
        }
      })
      .catch(error => {
        setProgramOpts({ ...programOpts, isLoad: false })
        if (error.response != undefined && error.response != null) {
          if (error.response.status == 401) {
            notifyError("Forbidden")
          }
          else {
            notifyError("Server Error")
          }
        }
      })
  }

  return (
    <div>
      <div
        className="flex items-center space-x-2 px-5"
        style={{ height: "50px" }}
      >
        <div
          className="font-bold w-20 text-left"
          style={{
            fontFamily: 'Nunito'
          }}
        >
          Periode
        </div>
        <div className="flex items-center">
          <div className="h-8 flex items-center px-1 border-t border-l border-b border-gray-400">
            <GoCalendar />
          </div>
          <DatePicker 
            locale={id}
            className="border border-gray-400 p-1 w-28 h-8" 
            selected={startDate} 
            onChange={(date) => setStartDate(date)} 
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div>-</div>
        <div className="flex items-center">
          <div className="h-8 flex items-center px-1 border-t border-l border-b border-gray-400">
            <GoCalendar />
          </div>
          <DatePicker
            className="border border-gray-400 p-1 w-28 h-8" 
            selected={untilDate} 
            onChange={(date) => setUntilDate(date)} 
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </div>
      <div
        className="flex items-center space-x-2 px-5"
        style={{ height: "50px" }}
      >
        <div
          className="font-bold w-20 text-left"
          style={{
            fontFamily: 'Nunito'
          }}
        >
          Program
        </div>
        <div className="flex items-center">
          <Select
            value={programOpts.selectedOpt}
            options={programOpts.data}
            isLoading={programOpts.isLoad}
            menuPlacement="auto"
            className="w-60 border border-gray-200 rounded-md text-left"
            onFocus={onSelectProgramOpts}
            onChange={(value) => {
              setProgramOpts({ ...programOpts, selectedOpt: value })
            }}
          />
          <button 
              className="bg-green-600 text-white p-1 px-5 flex items-center"
            onClick={() => {
              window.open('https://bimbelalc.id/bimbel_test_exporter/by-program.php?id_program=' + programOpts.selectedOpt.value , '_blank');
            }}
          >
            <RiFileExcel2Fill className="h-5 w-5 mr-2"/>
            Excel
          </button>
        </div>
      </div>

    </div>
  )
}

function SearchBox({
  model,
  setModel
}) {

  return (
    <div
      className="rounded-lg w-full bg-white shadow-lg px-5 py-2 "
    >
      <input
        className="w-full outline-none"
        placeholder="Nama atau Id Peserta, Nama atau Id Paket Soal"
        value={model.searchHeader.searchText}
        onChange={e => setModel(latestM => setSearchText(latestM, e.target.value))}
      />
    </div>
  )

}

export default function UjianPage() {
  const dispatch = useDispatch()
  const history = useHistory()

  const [model, setModel] = useState(initModel)

  const [countedData, setCountedData] = useState({
    created: 0,
    unpaid: 0,
    paid: 0,
    expired: 0
  })

  const [paymentStatusFilter, setPaymentStatusFilter] = useState(null)

  useEffect(() => {

    dispatch(setHomeActivePage('transaction'))

  }, [])

  useEffect(() => {
    // if (!model.dataProperties.fromDate || !model.dataProperties.untilDate) return

    onGetData(model, setModel, history)
  }, [model.dataProperties, model.searchHeader.searchText])


  return (
    <div className="space-y-3 w-full">
      <div className="flex bg-white rounded-lg">
        <ParamBox 
          onParamChanged={(fromDateStr, untilDataStr, programId) => setModel(setDateParam(model, fromDateStr, untilDataStr, programId))}
        />
      </div>
      <SearchBox 
        model={model}
        setModel={setModel}
      />
      <div>
        <DataTable 
          isLoadData={false}
          datas={model.dataList}
        />
      </div>
    </div>
  )
}

function onGetData(rootModel, setRootModel, history) {
  const payload = {
    search: rootModel.searchHeader.searchText,
    offset: rootModel.dataProperties.offset,
    limit: rootModel.dataProperties.limit,
    orderBy: rootModel.dataProperties.ord,
    fromDate: rootModel.dataProperties.fromDate,
    untilDate: rootModel.dataProperties.untilDate,
    program: rootModel.dataProperties.programId
  }

  // setRootModel(setIsLoadData(rootModel, true))

  getData(payload)
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          // console.log(payload)
          setRootModel(latestM => setData(latestM, payload))
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          history.push("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => {
      // setRootModel(latestM => setIsLoadData(rootModel, false))
    })

}