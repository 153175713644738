import axios from "axios"

export const getDataSummary = async () => {
  const finalUrl = window._HOST + "admin/grup-tahap-ujian/data-summary"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/grup-tahap-ujian/data-table"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getDetail = async (id) => {
  const finalUrl =
    window._HOST + "admin/grup-tahap-ujian/detail/" + id
  
  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const createData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/grup-tahap-ujian"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/grup-tahap-ujian"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateDataActivation = async (payload) => {
  const finalUrl =
    window._HOST + "admin/grup-tahap-ujian-activation"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const deleteData = async (kodeData) => {
  const finalUrl =
    window._HOST + "admin/grup-tahap-ujian/" + kodeData

  const response =
    await axios.delete(finalUrl, { withCredentials: true })

  return response.data
}

export const getDataExamPhaseSubgroup = async (idGrupTahapUjian) => {
  const finalUrl =
    window._HOST + "admin/subgrup-tahap-ujian/" + idGrupTahapUjian

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const getDataExamPhaseSubgroupIdLabel = async (idGrupTahapUjian) => {
  const finalUrl =
    window._HOST + "admin/subgrup-tahap-ujian/id-label/" + idGrupTahapUjian

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const addDataExamPhaseSubgroup = async (payload) => {
  const finalUrl =
    window._HOST + "admin/subgrup-tahap-ujian/"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateDataSubgroup = async (payload) => {
  const finalUrl =
    window._HOST + "admin/subgrup-tahap-ujian"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const deleteDataExamPhaseSubgroup = async (idTahapUJian) => {
  const finalUrl =
    window._HOST + "admin/subgrup-tahap-ujian/" + idTahapUJian

  const response =
    await axios.delete(finalUrl, { withCredentials: true })

  return response.data
}

export const setExamPhaseSubgroupPriority = async (payload) => {
  const finalUrl =
    window._HOST + "admin/subgrup-tahap-ujian-priority"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getDataExamPhase = async (idSubgroup) => {
  const finalUrl =
    window._HOST + "admin/tahap-ujian/" + idSubgroup

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const addDataExamPhase = async (payload) => {
  const finalUrl =
    window._HOST + "admin/tahap-ujian/"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const deleteDataExamPhase = async (idTahapUJian) => {
  const finalUrl =
    window._HOST + "admin/tahap-ujian/" + idTahapUJian

  const response =
    await axios.delete(finalUrl, { withCredentials: true })

  return response.data
}

export const setExamPhasePriority = async (payload) => {
  const finalUrl =
    window._HOST + "admin/tahap-ujian-priority"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}