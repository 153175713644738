import axios from "axios"

export const getDataSummary = async () => {
  const finalUrl = window._HOST + "admin/paket-soal/data-summary"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/paket-soal/data-table"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getDetail = async (id) => {
  const finalUrl =
    window._HOST + "admin/paket-soal/detail/" + id
  
  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const createData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/paket-soal"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/paket-soal"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateDataActivation = async (payload) => {
  const finalUrl =
    window._HOST + "admin/paket-soal-activation"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const deleteData = async (kodeData) => {
  const finalUrl =
    window._HOST + "admin/paket-soal/" + kodeData

  const response =
    await axios.delete(finalUrl, { withCredentials: true })

  return response.data
}

export const getJenjangIdLabel = async () => {
  const finalUrl = window._HOST + "admin/soal-drop/jenjang-id-label"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getMapelIdLabel = async () => {
  const finalUrl = window._HOST + "admin/soal-drop/mapel-id-label"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getMateriIdLabel = async (kodeJenjang, kodeMapel) => {
  const finalUrl =
    window._HOST + "admin/soal-drop/materi-id-label?" + (kodeJenjang ? "kode-jenjang=" + kodeJenjang + "&" : "") + (kodeMapel ? "kode-mapel=" + kodeMapel : "")

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const getDataSoalSummary = async () => {
  const finalUrl =
    window._HOST + "admin/soal/data-summary"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const getDataSoal = async (payload) => {
  const finalUrl =
    window._HOST + "admin/soal/data-table"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getDetailSoal = async (kodeSoal) => {
  const finalUrl =
    window._HOST + "admin/soal/" + kodeSoal

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const getAddedSoal = async (kodePaketSoal, idTahapUjian) => {
  const finalUrl =
    window._HOST + "admin/paket-soal/soal/" + kodePaketSoal + (idTahapUjian ? "?id-tahap-ujian="+idTahapUjian : "")

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const addSoal = async (payload) => {
  const finalUrl =
    window._HOST + "admin/paket-soal/soal"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const deleteSoal = async (payload) => {
  const finalUrl =
    window._HOST + "admin/paket-soal-delete-soal"

  const response =
    await axios.delete(finalUrl, { withCredentials: true, data: payload })

  return response.data
}

export const getGrupTahapUjian = async () => {
  const finalUrl =
    window._HOST + "admin/grup-tahap-ujian/id-label"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const getTahapUjianDetail = async (idTahapUjian) => {
  const finalUrl =
    window._HOST + "admin/tahap-ujian-detail/" + idTahapUjian

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}