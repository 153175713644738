import {
  RiAddLine,
  RiArrowLeftLine,
  RiCloseFill,
  RiEdit2Line,
  RiEye2Fill,
  RiEyeCloseFill,
  RiEyeFill,
  RiFilePaperLine,
  RiLoader5Fill,
  RiSearchLine
} from "react-icons/ri";
import { MdSort } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { BsChevronLeft, BsChevronRight, BsFillGridFill, BsTrash } from "react-icons/bs"
import { useNavigate } from "react-router";
import { dataPropertOrdToStr, goToNextPage, goToPrevPage, hasNextPage, hasPrevPage, openDeleteDialog } from "../Models/Model";
import { FaShippingFast } from "react-icons/fa"

import { format } from "date-fns"
import { useHistory } from "react-router-dom";

const formatRupiah = (angka) => {
  angka = angka.toString()

  var number_string = angka.replace(/[^,\d]/g, '').toString(),
  split   		      = number_string.split(','),
  sisa     		      = split[0].length % 3,
  rupiah     		    = split[0].substr(0, sisa),
  ribuan     		    = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if(ribuan){
    let separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }

  rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
  return rupiah
}

function DataTableFooter({
  pageNum,
  showCount,
  sortMode,
  searchText,
  hasNextPage,
  hasPrevPage,
  onGoToNextPage,
  onGoToPrevPage
}) {
  return (
    <div
      className="bg-white py-1 px-3 shadow-lg flex items-center justify-between rounded-md"
      style={{
        fontSize: "14px",
        height: "45px"
      }}
    >
      <div className="flex space-x-2">
        <label>
          Hal. {pageNum},
        </label>
        <label>
          Tampil: {showCount},
        </label>
        <label>
          Mode pengurutan:  {sortMode},
        </label>
        <label>
          Pencarian: {searchText && searchText !== '' ? searchText : '-'}
        </label>
      </div>
      <div className="flex">
        {hasPrevPage && (
          <button
            className=" p-2 rounded-lg hover:bg-gray-200"
            title={"Ke Halaman sebelumnya"}
            onClick={onGoToPrevPage}
          >
            <BsChevronLeft className="h-5 w-5" />
          </button>
        )}
        {hasNextPage && (
          <button
            className=" p-2 rounded-lg hover:bg-gray-200"
            title={"Ke Halaman selanjutnya"}
            onClick={onGoToNextPage}
          >
            <BsChevronRight className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  )
}

function ItemBox({
  no,
  idSkema,
  namaSkema,
  jmlCicilan,
  biayaPendaftaran,
  pokokCicilan,
  pertamaBayar,
  sisaCicilan,
  onEdit,
  onDelete
}) {

  return (
    <div
      className="py-1 w-full bg-white group hover:bg-blue-500 flex shadow-lg divide-x divide-gray-300 border-b border-gray-200"
      style={{
        fontSize: "14px"
      }}
    >
      <div 
        className="flex px-2 group-hover:text-white items-center " 
        style={{ 
          width: "5%",
          minWidth: "50px" 
        }}
      >
        {no}
      </div>
      <div
        className="px-2 flex items-center justify-center group-hover:text-white"
        style={{
          width: "15%",
          minWidth: "150px"
        }}
      >
        {idSkema}
      </div>
      <div
        className="px-2 flex items-center group-hover:text-white"
        style={{
          width: "20%",
          minWidth: "250px"
        }}
      >
        {namaSkema}
      </div>
      <div
        className="px-2 flex items-center justify-center group-hover:text-white"
        style={{
          width: "10%",
          minWidth: "100px"
        }}
      >
        {jmlCicilan}
      </div>
      <div
        className="px-2 flex items-center justify-end group-hover:text-white"
        style={{
          width: "10%",
          minWidth: "100px"
        }}
      >
        {biayaPendaftaran}
      </div>
      <div
        className="px-2 flex items-center justify-end group-hover:text-white"
        style={{
          width: "10%",
          minWidth: "100px"
        }}
      >
        {pokokCicilan}
      </div>
      <div
        className="px-2 flex items-center justify-end group-hover:text-white"
        style={{
          width: "10%",
          minWidth: "100px"
        }}
      >
        {pertamaBayar}
      </div>
      <div
        className="px-2 flex items-center justify-center group-hover:text-white"
        style={{
          width: "10%",
          minWidth: "100px"
        }}
      >
        {sisaCicilan}
      </div>

      <div 
        className="px-3 flex justify-center" 
        style={{ 
          width: "10%",
          minWidth: "100px" 
        }
      }>
        <button
          className="p-2 rounded-lg hover:bg-black text-black group-hover:text-white"
          onClick={onEdit}
        >
          <RiEdit2Line className="h-5 w-5" />
        </button>
        <button
          className="p-2 rounded-lg hover:bg-black text-black group-hover:text-white"
          onClick={onDelete}
        >
          <BsTrash className="h-5 w-5" />
        </button>
      </div>
    </div>
  )
}

function HeaderTable() {
  return (
    <div
      className="py-2 w-full bg-white flex items-center shadow-lg divide-x divide-gray-300 border-b bg-gray-100 font-bold"
      style={{
        fontSize: "15px",
        fontFamily: 'Nunito'
      }}
    >
      <div 
        className="flex px-2 group-hover:text-white items-center " 
        style={{ 
          width: "5%",
          minWidth: "50px" 
        }}
      >
        No
      </div>
      <div
        className="px-2 text-center flex items-center group-hover:text-white"
        style={{
          width: "15%",
          minWidth: "150px"
        }}
      >
        ID
      </div>
      <div
        className="px-2 text-center flex items-center group-hover:text-white"
        style={{
          width: "20%",
          minWidth: "250px"
        }}
      >
        Skema
      </div>
      <div
        className="px-2 text-center flex items-center group-hover:text-white"
        style={{
          width: "10%",
          minWidth: "100px"
        }}
      >
        Jml Cicilan
      </div>
      <div
        className="px-2 text-center flex items-center group-hover:text-white"
        style={{
          width: "10%",
          minWidth: "100px"
        }}
      >
        Biaya Pendaftaran
      </div>
      <div
        className="px-2 text-center flex items-center group-hover:text-white"
        style={{
          width: "10%",
          minWidth: "100px"
        }}
      >
        Pokok Cicilan
      </div>
      <div
        className="px-2 text-center flex items-center group-hover:text-white"
        style={{
          width: "10%",
          minWidth: "100px"
        }}
      >
        Pertama Bayar
      </div>
      <div
        className="px-2 text-center flex items-center group-hover:text-white"
        style={{
          width: "10%",
          minWidth: "100px"
        }}
      >
        Sisa Cicilan
      </div>

      <div 
        className="px-3 flex justify-center" 
        style={{ 
          width: "10%",
          minWidth: "100px" 
        }
      }>
        #
      </div>
    </div>
  )
}

function DataTable({
  isLoadData,
  datas,
  rootModel,
  setRootModel
}) {

  const navigate = useHistory()

  return (
    <div className="space-y-3">
      <div className="overflow-x-auto bg-white">
        <HeaderTable />
        {
          isLoadData ?
            (<div
              className="flex items-center justify-center text-blue-600 bg-white"
              style={{ minHeight: "50px" }}
            >
              <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
            </div>)
            :
            datas.length > 0 ?
              (<div>
                {datas.map((d, idx) => (
                  <ItemBox
                    key={d.idSkemaCicilan}
                    no={idx + 1}
                    idSkema={d.idSkemaCicilan}
                    namaSkema={d.namaSkemaCicilan}
                    jmlCicilan={d.jmlFrekuensiCicilan}
                    biayaPendaftaran={formatRupiah(d.biayaPendaftaran)}
                    pokokCicilan={formatRupiah(d.biayaPokokCicilan)}
                    pertamaBayar={formatRupiah(d.biayaPertamaBayar)}
                    sisaCicilan={d.sisaFrekuensiCicilan}
                    onEdit={() => navigate.push("/home/skema-cicilan/form/" + d.idSkemaCicilan)}
                    onDelete={() => setRootModel(openDeleteDialog(rootModel, d))}
                  />
                ))}
              </div>)
              :
              (<div className="py-2 bg-white text-center">
                Tidak ada data
              </div>)
        }
      </div>
      <DataTableFooter
        pageNum={(rootModel.dataProperties.offset / rootModel.dataProperties.limit) + 1}
        showCount={rootModel.dataList.length}
        sortMode={dataPropertOrdToStr(rootModel)}
        searchText={rootModel.searchHeader.searchText}
        hasNextPage={hasNextPage(rootModel)}
        hasPrevPage={hasPrevPage(rootModel)}
        onGoToPrevPage={() => setRootModel(goToPrevPage(rootModel))}
        onGoToNextPage={() => setRootModel(goToNextPage(rootModel))}
      />
    </div>
  )
}

export default DataTable;