import { Menu, Transition } from '@headlessui/react'
import { FaUserCircle } from 'react-icons/fa'
import { FiKey } from 'react-icons/fi'
import { AiOutlineLogout } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../Apis/AdminApi'
import { notifyError } from '../utils/Notification'
import { useHistory } from 'react-router-dom'
import { handleResponse200 } from '../utils/HttpUtils'
import { openChangePasswordDialog } from '../app/GlobalSlice'

export default function Topbar() {
  const history = useHistory()
  const dispatch = useDispatch()

  const { admin } = useSelector(state => state.global)

  return (
    <div 
      className="flex items-center justify-end px-10"
      style={{
        height: "60px"
      }}
    >
      <div
        className='relative'
      >
        <Menu
        >
          <Menu.Button
            className="rounded-full text-white"
          >
            <FaUserCircle className="h-8 w-8"/>
          </Menu.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items
              className={
                "bg-white shadow-lg flex flex-col absolute p-2 rounded-md divide-y divide-gray-200 z-50"
              }
              style={{
                width: "260px",
                marginLeft: "-230px",
                fontSize: "14px"
              }}
            >
              <Menu.Item disabled>
                {({ active }) => (
                  <div
                    className='p-2 space-y-1'
                  >
                    <div>
                      <div className='text-left flex'>
                        <div className='w-10'>ID</div>
                        <div className='w-2'>:</div>
                        <div>{admin.kodeAdmin}</div>
                      </div>
                    </div>
                    <div>
                      <div className='text-left flex'>
                        <div className='w-10'>Nama</div>
                        <div className='w-2'>:</div>
                        <div>{admin.namaAdmin}</div>
                      </div>
                    </div>
                    <div>
                      <div className='text-left flex'>
                        <div className='w-10'>Email</div>
                        <div className='w-2'>:</div>
                        <div>{admin.email}</div>
                      </div>
                    </div>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`p-2 flex items-center space-x-3 ${active && 'bg-blue-200'}`}
                    onClick={() => dispatch(openChangePasswordDialog())}
                  >
                    <FiKey className='h-4 w-4'/>
                    <label>Ubah password</label>
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`p-2 flex items-center space-x-3 ${active && 'bg-blue-200'}`}
                    onClick={() => {
                      logout()
                        .then(res => {
                          handleResponse200({
                            httpResponse: res,
                            onSuccess: _ => {
                              history.push("/login")
                            },
                            onRecovFailure: errors => errors.forEach(err => {
                              notifyError(err)
                            }),
                            onUnAuth: error => {
                              notifyError('Forbidden. Please Login')
                              history.push("/login")
                            },
                            onTechnicalError: errors => errors.forEach(err => {
                              notifyError(err)
                            })
                          })
                        })
                    }}
                  >
                    <AiOutlineLogout className='h-4 w-4'/>
                    <label>Keluar</label>
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

    </div>
  )
}