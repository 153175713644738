import { Dialog, Transition } from "@headlessui/react"
import { useDispatch, useSelector } from "react-redux"
import { VscChromeClose } from "react-icons/vsc"
import { useEffect, useState } from "react";

const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
    { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}

const CreateInvoiceDialog = ({
  isShow,
  cicilanDetail,
  payChans,
  onClose,
  onCreate
}) => {

  const { screenSize } = useSelector(state => state.global)

  const [selectedPayChan, setSelectedPayChan] = useState(null)

  const [reduceCicilanTotal, setReduceCicilanTotal] = useState(1)

  function findBiayaAdm() {
    const pc = 
      payChans.map(pc => pc.channels)
              .reduce((acc, curr) => acc.concat(curr), [])
              .find(pc => pc.code === cicilanDetail.paymentMethod)

    if (!pc) {
      return null
    }
    return pc.total_fee.flat
  }

  useEffect(() => {
    if (isShow && cicilanDetail && payChans.length > 0) {
      const pc = 
        payChans.map(pc => pc.channels)
                .reduce((acc, curr) => acc.concat(curr), [])
                .find(pc => pc.code === cicilanDetail.paymentMethod)

      setSelectedPayChan(pc)
    }

  }, [isShow, cicilanDetail, payChans])

  return (
    <Transition
      appear
      show={isShow}
    >
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto"
        style={{
          zIndex: "90"
        }}
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center flex justify-center items-center">
          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-opacity-70 bg-black" />
          </Transition.Child>

          <Transition.Child
            enter="ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={
                "absolute " +
                (screenSize.width > 500 ? " left-1/2 top-20 " : " left-0 top-0 ")
              }
            >
              <div
                className="py-1 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
                style={{
                  position: "absolute",
                  left: screenSize.width > 500 ? "-230px" : "0",
                  width: screenSize.width > 500 ? "460px" : screenSize.width + "px"
                }}
              >
                <Dialog.Title
                  as="h3"
                  className="px-2 text-lg flex items-center justify-between font-medium leading-6 text-gray-900 border-b border-gray-200"
                >
                  <button
                    className="p-2 hover:text-red-500 outline-none"
                    onClick={onClose}
                  >
                    <VscChromeClose className="h-6 w-6" />
                  </button>
                </Dialog.Title>
                <div
                  className="mt-4 mb-2 px-5 flex flex-col space-y-3 overflow-auto space-y-5"
                >
                  <div
                    className="space-y-5"
                    style={{
                      fontSize: '14px'
                    }}
                  >
                    <div
                      className=""
                    >
                      <div className="flex space-x-2">
                        <div className="w-24">
                          Biaya Pokok
                        </div>
                        <div>
                          :
                        </div>
                        <div>
                          {cicilanDetail && formatRupiah(cicilanDetail.biayaPokokCicilan)}
                        </div>
                      </div>
                      <div className="flex space-x-2">
                        <div className="w-24">
                          Sisa Cicilan
                        </div>
                        <div>
                          :
                        </div>
                        <div>
                          {cicilanDetail && cicilanDetail.sisaCicilan}
                        </div>
                      </div>
                      <div className="flex space-x-2">
                        <div className="w-24">
                          Pay Channel
                        </div>
                        <div>
                          :
                        </div>
                        <div>
                          {cicilanDetail && selectedPayChan && selectedPayChan.name}
                        </div>
                      </div>
                      <div className="flex space-x-2">
                        <div className="w-24">
                          Biaya Admin
                        </div>
                        <div>
                          :
                        </div>
                        <div>
                          {/* {cicilanDetail && cicilanDetail.paymentMethod}? */}
                          {cicilanDetail && selectedPayChan && formatRupiah(selectedPayChan.total_fee.flat) 
                          }
                        </div>
                      </div>
                      <div className="flex space-x-2">
                        <div className="w-24">
                          Total
                        </div>
                        <div>
                          :
                        </div>
                        <div>
                          {cicilanDetail && selectedPayChan && formatRupiah(selectedPayChan.total_fee.flat + (cicilanDetail.biayaPokokCicilan * reduceCicilanTotal))
                          }
                        </div>
                      </div>
                      <div className="flex space-x-2">
                        <div className="w-24">
                          Jml Cicilan
                        </div>
                        <div>
                          :
                        </div>
                        <div>
                          <input 
                            className="p-1 border text-left"
                            type={"number"}
                            value={reduceCicilanTotal}
                            onChange={(e) => {
                              if (parseInt(e.target.value) === 0 || parseInt(e.target.value) > cicilanDetail.sisaCicilan) {
                                return;
                              } 
                              setReduceCicilanTotal(parseInt(e.target.value))
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="border p-1 rounded-lg overflow-y-auto"
                      style={{
                        height: '350px'
                      }}
                    >
                      {payChans.map((p, idx) => (
                        <div
                          key={idx}
                        >
                            <div 
                                className="pt-2 pl-2 space-y-2"
                                style={{
                                    fontFamily: 'Nunito',
                                    fontSize: '14px'
                                }}
                            >
                                {p.channels.map(c => (
                                    <div
                                      key={c.name} 
                                      className={
                                        "p-2 px-3 rounded-md  flex items-center space-x-5 " +
                                        (selectedPayChan && c.code === selectedPayChan.code ? " bg-blue-100 border border-blue-300 " : " border border-gray-300 ") 
                                      }
                                    >
                                        <div>
                                            {<img src={c.icon_url} width={'60px'}/>}
                                        </div>
                                        <div>
                                            <div className="font-bold">{c.name}</div>
                                            <div className="font-bold">
                                                <div>Biaya Admin : {formatRupiah(c.total_fee.flat)}</div>   
                                            </div>
                                            <div className="pt-2">
                                                <button 
                                                    className="py-1 px-16 rounded-full bg-blue-500 text-white font-bold hover:bg-blue-900 hover:shadow-lg"
                                                    onClick={() => {
                                                      setSelectedPayChan(c)
                                                    }}
                                                >
                                                    Pilih 
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="py-2 flex justify-center space-x-5">
                    <button
                      className="rounded-full py-1 px-8 bg-red-500 hover:bg-red-700 text-white"
                      onClick={onClose}
                    >
                      Batal
                    </button>
                    <button
                      className="rounded-full py-1 px-5 bg-blue-500 hover:bg-blue-700 text-white"
                      onClick={() => {
                        if (!cicilanDetail || !selectedPayChan) return;

                        onCreate(
                          cicilanDetail.idCicilanMember, 
                          selectedPayChan.name, 
                          selectedPayChan.code,
                          reduceCicilanTotal
                        )
                      }}
                    >
                      Kirim
                    </button>
                  </div>

                </div>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )

}

export default CreateInvoiceDialog;