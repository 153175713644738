import axios from "axios"

export const getProgramIdLabel = async () => {
  const finalUrl = window._HOST + "admin/program/id-label"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getDataSummary = async () => {
  const finalUrl = window._HOST + "admin/paket-bimbel/data-summary"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/data-paket-bimbel"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getDetail = async (id) => {
  const finalUrl =
    window._HOST + "admin/paket-bimbel/detail/" + id
  
  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const createData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/paket-bimbel"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/paket-bimbel"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateDataActivation = async (payload) => {
  const finalUrl =
    window._HOST + "admin/paket-bimbel-activation"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const deleteData = async (kodeData) => {
  const finalUrl =
    window._HOST + "admin/paket-bimbel/" + kodeData

  const response =
    await axios.delete(finalUrl, { withCredentials: true })

  return response.data
}

export const getAddedPaketSoal = async (idPaketBimbel, payload) => {
  const finalUrl =
    window._HOST + "admin/get-added-paket-soal/" + idPaketBimbel

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getUnAddedPaketSoal = async (idPaketBimbel,payload) => {
  const finalUrl =
    window._HOST + "admin/get-unadded-paket-soal/" + idPaketBimbel

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const addPaketSoal = async (payload) => {
  const finalUrl =
    window._HOST + "admin/paket-bimbel/paket-soal"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const removePaketSoal = async (payload) => {
  const finalUrl =
    window._HOST + "admin/remove-paket-bimbel-soal"

  const response =
    await axios.delete(finalUrl,{ withCredentials: true, data: payload })

  return response.data
}

export const getDiskonIdLabel = async () => {
  const finalUrl =
    window._HOST + "admin/discount"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const getSkemaCicilanIdLabel = async () => {
  const finalUrl =
    window._HOST + "admin/skema-cicilan/id-label"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}
