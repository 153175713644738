import { useEffect, useState } from "react"
import { RiArrowLeftLine, RiLoader4Fill } from "react-icons/ri"
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import { handleResponse200 } from "../../../utils/HttpUtils"
import { notifyError } from "../../../utils/Notification"
import { getDetail } from "../Apis/Api"
import AddedPaketSoaComp from "./AddedPaketSoalComp"
import UnAddedPaketSoalComp from "./UnAddedPaketSoalComp"

export default function ManagePaketSoalPage() {
  const history = useHistory()

  const { id } = useParams()

  const [model, setModel] = useState({
    tabs: [
      {
        id: 'added_paket_soal',
        label: 'Paket Soal ditambah'
      },
      {
        id: 'unadded_paket_soal',
        label: 'Paket Soal tersedia'
      }
    ],
    activeTab: 'added_paket_soal',
    paketDetail: {
      idPaketBimbel: '',
      namaPaketBimbel: ''
    },
    isLoad: false
  })

  useEffect(() => {
    getDataDetail(id)
  }, [])

  function getDataDetail(idData) {
    setModel({ ...model, isLoad: true })

    getDetail(idData)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setModel(latestM => ({ 
              ...latestM, 
              paketDetail: { 
                idPaketBimbel: payload.idPaketBimbel,
                namaPaketBimbel: payload.namaPaketBimbel 
              } 
            }))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .finally(() => setModel(latestM => ({ ...latestM, isLoad: false })))

  }

  return (
    <div
      className="bg-white w-full shadow-xl rounded-md  relative"
    >
      { model.isLoad && (
        <>
          <div
            className="absolute bg-black z-10 w-full h-full top-0 left-0 opacity-70"
          >
          </div>
          <div
            className="absolute z-20 top-0 left-0 w-full h-full flex items-center justify-center text-blue-500 "
          >
            <RiLoader4Fill className="h-12 w-12 animate-spin"/>
          </div>
        </>
      )}
      <div
        className="flex items-center justify-between p-2"
      >
        <div>
          <button
            onClick={() => history.push("/home/paket-bimbel")}
          >
            <RiArrowLeftLine className="h-6 w-6"/>
          </button>
        </div>
        <div
          className="font-bold uppercase"
          style={{
            fontSize: "15px"
          }}
        >
          Kelola Paket Soal
        </div>
      </div>
      <div
        className="px-3 pb-5 bg-white space-y-3"
        style={{
          fontSize: "14px"
        }}
      >
        <div
          className="text-left space-y-2"
        >
          <div
            className=""
          >
            ID Paket Bimbel
          </div>
          <div>
            <input
              className="p-1 rounded-md border border-gray-200 w-full"
              value={model.paketDetail.idPaketBimbel}
              readOnly
            />
          </div>
        </div>
        <div
          className="text-left space-y-2"
        >
          <div
            className=""
          >
            Nama Paket Bimbel
          </div>
          <div>
            <input
              className="p-1 rounded-md border border-gray-200 w-full"
              value={model.paketDetail.namaPaketBimbel}
              readOnly
            />
          </div>
        </div>
      </div>
      <div
        className="pt-2 px-2 bg-gray-500 flex"
      >
        { model.tabs.map(t => (
          <button
            className={
              model.activeTab === t.id ?
                "py-2 px-5 bg-white border-b border-white "
                :
                "py-2 px-5 bg-gray-400 hover:bg-gray-200"
            }
            onClick={() => setModel({ ...model, activeTab: t.id })}
          >
            {t.label}
          </button>  
        )) }
      </div>
      <div>
        {
          model.activeTab == 'added_paket_soal' ?
            (<AddedPaketSoaComp />)
            :
            (<UnAddedPaketSoalComp />)
        }
      </div>
    </div>
  )
}