import { useEffect, useState } from "react"
import { RiArrowLeftLine, RiLoader4Fill, RiLoader5Fill } from "react-icons/ri"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router-dom"
import { handleResponse200 } from "../../../utils/HttpUtils"
import { notifyError } from "../../../utils/Notification"
import { getDataExamPhase, getDataExamPhaseSubgroupIdLabel } from "../../ExamPhase/Apis/Api"
import { getDetail } from "../Apis/Api"
import { format } from "date-fns"
import Select from "react-select"

function ItemBox({
  no,
  idTahapUjian,
  namaTahapUjian,
  durasiTahapUjian,
  createdAt,
  onSelect
}) {

  return (
    <div
      className="py-1 w-full bg-white hover:bg-blue-100 flex shadow-lg divide-x divide-gray-300 border-b border-gray-200"
      style={{
        fontSize: "14px"
      }}
    >
      <div 
        className="flex px-2 group-hover:text-white items-center " 
        style={{ 
          width: "4%",
          minWidth: "50px"
        }}
      >
        {no}
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "59%",
          minWidth: "200px"
        }}
      >
        {namaTahapUjian}
      </div>
      <div
        className="px-2 text-center flex items-center justify-center group-hover:text-white"
        style={{
          width: "10%",
          minWidth: "200px"
        }}
      >
        {durasiTahapUjian}
      </div>
      <div 
        className="flex px-2 items-center justify-center group-hover:text-white" 
        style={{ 
          width: "12%",
          minWidth: "130px"
        }}
      >
        {format(new Date(createdAt), "dd/MM/yyyy")}
      </div>
      <div 
        className="px-3 flex justify-center" 
        style={{ 
          width: "15%",
          minWidth: "195px" 
        }}
      >
        <button
          className="p-1 px-5 rounded-lg bg-blue-500 text-white hover:bg-black text-black group-hover:text-white"
          onClick={onSelect}
        >
          Kelola Soal
        </button>
      </div>
    </div>
  )
}

function HeaderTable() {
  return (
    <div
      className="w-full bg-white flex items-center shadow-lg divide-x divide-gray-300 border-b bg-gray-100 font-bold"
      style={{
        fontSize: "15px"
      }}
    >
      <div 
        className="flex px-2 py-2 group-hover:text-white items-center " 
        style={{ 
          width: "4%",
          minWidth: "50px" 
        }}
      >
        No
      </div>
      <div
        className="px-2 text-left flex items-center group-hover:text-white"
        style={{
          width: "59%",
          minWidth: "200px"
        }}
      >
        Tahap Ujian
      </div>
      <div
        className="px-2 text-center flex items-center justify-center group-hover:text-white"
        style={{
          width: "10%",
          minWidth: "200px"
        }}
      >
        Durasi Tahap Ujian
      </div>
      <div 
        className="flex px-2 items-center justify-center group-hover:text-white" 
        style={{ 
          width: "12%",
          minWidth: "130px"
        }}
      >
        Tgl input
      </div>
      <div 
        className="px-3 flex justify-center" 
        style={{ 
          width: "15%",
          minWidth: "195px" 
        }
      }>
        #
      </div>
    </div>
  )
}

function DataTable({
  isLoadData,
  datas,
  idPaketSoal,
  rootModel,
  setRootModel,
  idGrupTahapUjian
}) {

  const history = useHistory()

  return (
    <div className="space-y-3">
      <div className="overflow-x-auto bg-white">
        <HeaderTable />
        {
          isLoadData ?
            (<div
              className="flex items-center justify-center text-blue-600 bg-white"
              style={{ minHeight: "50px" }}
            >
              <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
            </div>)
            :
            datas.length > 0 ?
              (<div>
                {datas.map((d, idx) => (
                  <ItemBox
                    no={idx + 1}
                    idTahapUjian={d.idTahapUjian}
                    namaTahapUjian={d.namaTahapUjian}
                    durasiTahapUjian={d.durasiTahapUjian}
                    createdAt={d.createdAt}
                    onSelect={() => history.push("/home/paket-soal/manage-soal/"+idPaketSoal+"/"+d.idTahapUjian)}
                  />
                ))}
              </div>)
              :
              (<div className="py-2 bg-white">
                Tidak ada data
              </div>)
        }
      </div>
    </div>
  )
}

export default function SelectExamPhasePage() {

  const dispatch = useDispatch()
  const history = useHistory()

  const { id } = useParams()

  const [model, setModel] = useState({
    isLoad: false,
    dataDetail: {
      idPaketSoal: '',
      namaPaketSoal: '',
      idGrupTahapUjian: '',
      namaGrupTahapUjian: ''
    },
    tahapanUjian: []
  })

  useEffect(() => {

    setModel({ ...model, isLoad: true })

    getDataDetail(id)

  }, [])

  // useEffect(() => {
  //   if (!model.dataDetail.idPaketSoal || !model.dataDetail.idGrupTahapUjian) return;

  //   setModel({ ...model, isLoad: true })

  //   getTahapanUjian(model.dataDetail.idGrupTahapUjian)

  // }, [model.dataDetail.idPaketSoal])

  const [subgrupOpts, setSubgroupOpts] = useState({
    selectedOpt: null,
    data: [],
    isLoad: false
  })

  function onSelectSubgrup() {
    if (!model.dataDetail.idGrupTahapUjian) return;

    setSubgroupOpts({
      ...subgrupOpts,
      isLoad: true
    })

    getDataExamPhaseSubgroupIdLabel(model.dataDetail.idGrupTahapUjian)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setSubgroupOpts(latestM => ({ 
              ...latestM, 
              data: payload.map(d => ({ label: d.label, value: d.id })) 
            }))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => {
        setSubgroupOpts(latestM => ({ ...latestM, isLoad: false }))
      })
  }

  useEffect(() => {
    if (!subgrupOpts.selectedOpt) return;

    getTahapanUjian(subgrupOpts.selectedOpt.value)
  }, [subgrupOpts.selectedOpt])

  function getDataDetail(idData) {
    getDetail(idData)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setModel(latestM => ({ ...latestM, dataDetail: payload }))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .finally(() => setModel(latestM => ({ ...latestM, isLoad: false })))

  }

  function getTahapanUjian(idGrupTahapUjian) {
    setModel(latestM => ({ ...latestM, isLoad: true }))

    getDataExamPhase(idGrupTahapUjian)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setModel(latestM => ({ ...latestM, tahapanUjian: payload }))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => {})
      .finally(() => setModel(latestM => ({ ...latestM, isLoad: false })))
  }

  return (
    <div
      className="bg-white w-full shadow-xl rounded-md  relative"
    >
      { model.isLoad && (
        <>
          <div
            className="absolute bg-black z-10 w-full h-full top-0 left-0 opacity-70"
          >
          </div>
          <div
            className="absolute z-20 top-0 left-0 w-full h-full flex items-center justify-center text-blue-500 "
          >
            <RiLoader4Fill className="h-12 w-12 animate-spin"/>
          </div>
        </>
      )}
      <div
        className="flex items-center justify-between p-2"
      >
        <div>
          <button
            onClick={() => history.push("/home/paket-soal")}
          >
            <RiArrowLeftLine className="h-6 w-6"/>
          </button>
        </div>
        <div
          className="font-bold uppercase"
          style={{
            fontSize: "15px"
          }}
        >
          Pilih tahapan ujian
        </div>
      </div>
      <div
        className="px-3 pb-5 bg-white space-y-3"
        style={{
          fontSize: "14px"
        }}
      >
        <div
          className="text-left space-y-2"
        >
          <div
            className=""
          >
            ID Paket Soal
          </div>
          <div>
            <input
              className="p-1 rounded-md border border-gray-200 w-full bg-gray-200"
              value={model.dataDetail.idPaketSoal}
              readOnly
            />
          </div>
        </div>
        <div
          className="text-left space-y-2"
        >
          <div
            className=""
          >
            Nama Paket Soal
          </div>
          <div>
            <input
              className="p-1 rounded-md border border-gray-200 w-full bg-gray-200"
              value={model.dataDetail.namaPaketSoal}
              readOnly
            />
          </div>
        </div>
        <div
          className="text-left space-y-2"
        >
          <div
            className=""
          >
            Grup tahapan ujian
          </div>
          <div>
            <input
              className="p-1 rounded-md border border-gray-200 w-full bg-gray-200"
              value={model.dataDetail.namaGrupTahapUjian}
              readOnly
            />
          </div>
        </div>
      </div>
      <div
        className="flex px-3 pb-5"
      >
        <Select
          value={subgrupOpts.selectedOpt}
          options={subgrupOpts.data}
          isLoading={subgrupOpts.isLoad}
          onFocus={onSelectSubgrup}
          menuPlacement="auto"
          className="w-60 border border-gray-200 rounded-md text-left"
          onChange={(value) => {
            setSubgroupOpts({
              ...subgrupOpts,
              selectedOpt: value
            })
          }}
          placeholder="pilih subgrup tahap ujian"
        />
      </div>
      <div
        className="px-3 pb-5"
      >
        <DataTable
          isLoadData={model.isLoad}
          rootModel={model}
          setRootModel={setModel}
          datas={model.tahapanUjian}
          idPaketSoal={id}
        />
      </div>

    </div>
  )

}