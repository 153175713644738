import axios from "axios"

export const getDataSummary = async () => {
  const finalUrl = window._HOST + "admin/anak/data-summary"
  const response = await axios.get(finalUrl, { withCredentials: true })
  return response.data
}

export const getData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/anak/data-table"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getOrangtuaIdLabel = async (payload) => {
  const finalUrl =
    window._HOST + "admin/member/id-label"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const getIdLabel = async (payload) => {
  const finalUrl =
    window._HOST + "admin/anak/id-label"

  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const getSekolahIdLabel = async () => {
  const finalUrl =
      window._HOST + "admin/sekolah/id-label"

  const response =
      await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const getDetail = async (id) => {
  const finalUrl =
    window._HOST + "admin/anak/detail/" + id
  
  const response =
    await axios.get(finalUrl, { withCredentials: true })

  return response.data
}

export const createData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/anak"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateData = async (payload) => {
  const finalUrl =
    window._HOST + "admin/anak"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const updateDataActivation = async (payload) => {
  const finalUrl =
    window._HOST + "admin/anak-activation"

  const response =
    await axios.put(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const deleteData = async (kodeData) => {
  const finalUrl =
    window._HOST + "admin/anak/" + kodeData

  const response =
    await axios.delete(finalUrl, { withCredentials: true })

  return response.data
}

export const getAddedPaketBimbel = async (idPaketBimbel, payload) => {
  const finalUrl =
    window._HOST + "admin/anak-paket-bimbel-added/" + idPaketBimbel

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const getUnAddedPaketBimbel = async (idPaketBimbel,payload) => {
  const finalUrl =
    window._HOST + "admin/anak-paket-bimbel-unadded/" + idPaketBimbel

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const addPaketBimbel = async (payload) => {
  const finalUrl =
    window._HOST + "admin/anak-paket-bimbel"

  const response =
    await axios.post(finalUrl, payload, { withCredentials: true })

  return response.data
}

export const removePaketBimbel = async (payload) => {
  const finalUrl =
    window._HOST + "admin/remove-anak-paket-bimbel"

  const response =
    await axios.delete(finalUrl,{ withCredentials: true, data: payload })

  return response.data
}



