import { useEffect, useState } from "react"
import { RiArrowLeftLine, RiLoader4Fill } from "react-icons/ri"
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import { handleResponse200 } from "../../../utils/HttpUtils"
import { notifyError } from "../../../utils/Notification"
import { addAdminAccess, getAdminAccess, getDetail } from "../Apis/Api"

export default function ManageAdminAccess() {
  const history = useHistory()

  const { id } = useParams()

  const [model, setModel] = useState({
    admin: {
      idAdmin: null,
      namaAdmin: null
    },
    adminAccess: [],
    isProcess: false
  })

  useEffect(() => {
    getDataDetail(id)
  }, [])

  useEffect(() => {
    if (!model.admin.idAdmin) return;

    getDataAccess(model.admin.idAdmin)

  }, [model.admin.idAdmin])

  function getDataDetail(idData) {
    setModel({ ...model, isLoad: true })

    getDetail(idData)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            console.log(payload)
            setModel(latestM => ({
              ...latestM,
              admin: {
                idAdmin: payload.idAdmin,
                namaAdmin: payload.namaAdmin
              }
            }))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))

  }

  function getDataAccess(idData) {
    setModel({ ...model, isProcesss: true })

    getAdminAccess(idData)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setModel(latestM => ({ ...latestM, adminAccess: payload }))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))

  }

  function addAccess(idFeature) {
    setModel({ ...model, isProcesss: true })

    const payload = {
      idAdmin: model.admin.idAdmin,
      features: [idFeature]
    }

    addAdminAccess(payload)
      .then(res =>
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            getDataAccess(model.admin.idAdmin)
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      )
      .catch(err => setModel({ ...model, isProcesss: false }))
  }

  return (
    <div
      className="bg-white w-full shadow-xl rounded-md  relative"
    >
      { model.isProcess && (
        <>
          <div
            className="absolute bg-black z-10 w-full h-full top-0 left-0 opacity-70"
          >
          </div>
          <div
            className="absolute z-20 top-0 left-0 w-full h-full flex items-center justify-center text-blue-500 "
          >
            <RiLoader4Fill className="h-12 w-12 animate-spin"/>
          </div>
        </>
      )}
      <div
        className="flex items-center justify-between p-2"
      >
        <div>
          <button
            onClick={() => history.push("/home/admin")}
          >
            <RiArrowLeftLine className="h-6 w-6"/>
          </button>
        </div>
        <div
          className="font-bold uppercase"
          style={{
            fontSize: "15px"
          }}
        >
          Kelola Akses Admin
        </div>
      </div>
      <div
          className="px-3 pb-5 bg-white space-y-3"
          style={{
            fontSize: "14px"
          }}
        >
          <div
            className="text-left space-y-2"
          >
            <div
              className=""
            >
              ID Admin
            </div>
            <div>
              <input
                className="p-1 rounded-md border border-gray-200 w-full"
                value={model.admin.idAdmin}
                readOnly
              />
            </div>
          </div>
          <div
            className="text-left space-y-2"
          >
            <div
              className=""
            >
              Nama
            </div>
            <div>
              <input
                className="p-1 rounded-md border border-gray-200 w-full"
                value={model.admin.namaAdmin}
                readOnly
              />
            </div>
          </div>
        </div>
        <div
          className=""
        >
          { model.adminAccess.map(acc => (
            <div
              className="flex items-center justify-between py-2 px-4 hover:bg-blue-100"
            >
              <div
              >
                {acc.label}
              </div>
              <div
                className=""
              >
                <button
                  className={
                    "p-2 px-5 text-white " + (acc.hasAccess ? " bg-red-600 " : " bg-green-600 ")
                  }
                  onClick={() => !acc.hasAccess ? addAccess(acc.idFeature) : null}
                >
                  {acc.hasAccess ? "Hapus" : "Izinkan"}
                </button>
              </div>
            </div>
          )) }
        </div>
        
      
    </div>
  )


}