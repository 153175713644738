import { useEffect, useState } from "react"
import { RiArrowLeftLine, RiLoader4Fill, RiLoader4Line, RiLoader5Fill } from "react-icons/ri"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import { handleResponse200 } from "../../../utils/HttpUtils"
import { notifyError } from "../../../utils/Notification"
import { createData, getDataKelas, getDetail, updateData } from "../Apis/Api"
import { getData } from "../../Kelas/Apis/Api"
import Select from "react-select"
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md"


const KelasBox = ({
  onSelectedClassChanged,
  existingKelas
}) => {

  const history = useHistory()

  const [isLoad, setIsLoad] = useState(false)
  const [classes, setClasses] = useState([])

  useEffect(() => {
    setIsLoad(true)

    const payload = {
      search: '',
      offset: 0,
      limit: 500, //tampilkan semua kelas
      orderBy: 'oldestData' //gunakan default
    }

    getData(payload)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            console.log('payload ', payload)
            setClasses(payload.map(k => ({ idKelas: k.idKelas, namaKelas: k.namaKelas, isActive: existingKelas.find(kl => kl.id === k.idKelas) })))
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(error => { })
      .finally(() => {
        setIsLoad(false)
      })

  }, [existingKelas])

  useEffect(() => {
    const selected = classes.filter(cl => cl.isActive)

    onSelectedClassChanged(selected)
  }, [classes])

  const ItemBox = ({ 
    kelas,
    onChange
  }) => {
    return (
      <div className="flex items-center space-x-2">
        <button 
          className=""
          onClick={() => onChange(kelas.idKelas)}
        >
          { 
            kelas.isActive ? 
              (<MdCheckBox className="text-blue-700 h-6 w-6"/>)
              :
              (<MdCheckBoxOutlineBlank className="text-blue-700 h-6 w-6"/>)
          }
        </button>
        <label>{kelas.namaKelas}</label>
      </div>
    )
  }

  return (
    <div 
      className="p-2 overflow-auto border bg-white"
      style={{
        height: '200px'
      }}
    >
      { isLoad ? 
          (<div className="w-full flex justify-center py-10">
            <RiLoader5Fill className="text-blue-500 h-10 w-10 animate-spin"/>
          </div>)
          :
          (<div className="space-y-2">
            {classes.map(c => (
              <ItemBox 
                key={c.idKelas} 
                kelas={c}
                onChange={idKelas => setClasses(classes.map(cl => cl.idKelas === idKelas ? { ...cl, isActive: !cl.isActive } : cl))}
              />
            ))}
          </div>)
      }
    </div>
  )
}

export default function FormPage() {
  const history = useHistory()

  const { screenSize } = useSelector(state => state.global)

  const [model, setModel] = useState({
    form: {
      idProgram: '',
      namaProgram: '',
      description: '',
      harga: undefined,
      kelas: [],
      prevKelas: []
    },
    isProcess: false
  })

  useEffect(() => {
    console.log('form ', model.form)
  }, [model.form])

  const { mode, id } = useParams()

  const [kelasOpts, setKelasOpts] = useState({
    selectedOpt: null,
    data: [],
    isLoad: false
  })

  const [file, setFile] = useState(null)
  const [preview, setPreview] = useState(null)

  const fileReader = new FileReader()
  fileReader.onload = r => {
    setPreview(r.target.result)
  }

  useEffect(() => {
    if (!file) {
      setPreview(null)
      return
    }
    fileReader.readAsDataURL(file)
  }, [file])

  function onSelectFile(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setFile(null)
      return
    }

    setFile(e.target.files[0])
  }

  useEffect(() => {
    if (!mode) return;

    setModel({...model, isProcess: true})
    getDataDetail(id)

  }, [])

  function getDataDetail(idData) {
    getDetail(idData)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            console.log('test ', payload.kelas)
            setModel(latestM => ({ 
              ...latestM, 
              form: { 
                idProgram: payload.idProgram,
                namaProgram: payload.namaProgram,
                description: payload.description,
                harga: payload.harga,
                kelas: [],
                prevKelas: payload.kelas
              } 
            }))

            setPreview(payload.iconBase64)

          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))

  }

  function saveData() {
    setModel({ ...model, isProcess: true })

    const payload = {
      idProgram: model.form.idProgram,
      namaProgram: model.form.namaProgram,
      description: model.form.description,
      harga: parseInt(model.form.harga),
      iconBase64: preview,
      kelas: model.form.kelas.map(k => k.idKelas)
    }

    if (mode) {
      updateData(payload)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
              history.push("/home/program")
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))

    }
    else {
      createData(payload)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
              history.push("/home/program")
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))
    }
  }

  function onSelectKelasOpts() {
    setKelasOpts({ ...kelasOpts, isLoad: true })

    getDataKelas()
      .then(response => {
        if (response.success) {
          const data = response.payload.map(d => ({ label: d.label, value: d.id }))
          setKelasOpts({ ...kelasOpts, data: data, isLoad: false, rawData: response.payload })
        }
        else {
          setKelasOpts({ ...kelasOpts, isLoad: false })
        }
      })
      .catch(error => {
        setKelasOpts({ ...kelasOpts, isLoad: false })
        if (error.response != undefined && error.response != null) {
          if (error.response.status == 401) {
            notifyError("Forbidden")
          }
          else {
            notifyError("Server Error")
          }
        }
      })
  }


  return (
    <div
      className="p-2 bg-white w-full shadow-xl rounded-md divide-y divide-gray-300 relative"
    >
      { model.isProcess && (
        <>
          <div
            className="absolute bg-black z-10 w-full h-full top-0 left-0 opacity-70"
          >
          </div>
          <div
            className="absolute z-20 top-0 left-0 w-full h-full flex items-center justify-center text-blue-500 "
          >
            <RiLoader4Fill className="h-12 w-12 animate-spin"/>
          </div>
        </>
      )}
      
      <div
        className="flex items-center justify-between p-2"
      >
        <div>
          <button
            onClick={() => history.push("/home/program")}
          >
            <RiArrowLeftLine className="h-6 w-6"/>
          </button>
        </div>
        <div
          className="font-bold uppercase"
          style={{
            fontSize: "15px"
          }}
        >
          {
            mode ? 
              mode == 'edit' ? "update data" : "lihat data"
            :
              "Tambah data"
          }
        </div>
      </div>
      <div
        className="pt-4 pb-2 px-2 text-left space-y-3 bg-gray-100"
      >
        { mode && (
          <div
            className="space-y-2"
          >
            <div
              className="font-bold"
            >
              ID Program
            </div>
            <div>
              <input
                className="w-full p-2 rounded-sm border border-gray-400"
                value={model.form.idProgram}
                readOnly
              />
            </div>
          </div>  
        )}
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
          >
            Nama Program
          </div>
          <div>
            <input
              className="w-full p-2 rounded-sm border border-gray-400"
              value={model.form.namaProgram}
              onChange={e => setModel({ ...model, form: { ...model.form, namaProgram: e.target.value } })}
            />
          </div>
        </div>
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
          >
            Deskripsi Program
          </div>
          <div>
            <textarea
              className="w-full p-2 rounded-sm border border-gray-400"
              value={model.form.description}
              onChange={e => setModel({ ...model, form: { ...model.form, description: e.target.value } })}
              rows={7}
            />
          </div>
        </div>
        <div>
          <div
            className="font-bold"
          >
            Icon
          </div>
          <div>
            <div>
              <img
                src={preview}
                className="rounded-md"
                style={{
                  maxHeight: "300px"
                }}
              />
            </div>
            <div>
              <input
                type="file"
                className="p-3 rounded-lg font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none"
                onChange={onSelectFile}
              />
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <div
            className="font-bold"
          >
            Kelas
          </div>
          <div>
            <KelasBox 
              onSelectedClassChanged={selectedKelas => setModel({ ...model, form: { ...model.form, kelas: selectedKelas } })}
              existingKelas={model.form.prevKelas}
            />
          </div>
        </div>
      </div>
      { (!mode || mode != 'view') && (
        <div
          className="pt-4 pb-2 px-2 flex items-center space-x-2"
        >
          <button
            className="px-5 py-2 bg-blue-600 text-white font-bold rounded-md"
            onClick={() => {              
              saveData()
            }}
          >
            Simpan
          </button>
        </div>
      )}

    </div>
  )
}