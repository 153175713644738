import { Transition } from "@headlessui/react";
import { RiArrowLeftLine, RiCloseFill, RiLoader5Fill, RiSearchLine } from "react-icons/ri";
import { openHomePage } from "../Models/Model"
import { Editor } from '@tinymce/tinymce-react'
import { createRef, useEffect, useRef, useState, ReactDOM } from "react";
import { BiTrash } from "react-icons/bi";
import { getAddedSoal, getDetail, getTahapUjianDetail } from "../Apis/Api";
import { handleResponse200 } from "../../../utils/HttpUtils";
import Select from "react-select"
import { AiOutlinePlus } from "react-icons/ai"
import { format } from "date-fns"
import { notifyError, notifySuccess } from "../../../utils/Notification";
import QuestionListBox from "./QuestionListBox";
import QuestionDetailBox from "./QuestionDetailBox";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showSidebar, unShowSidebar } from "../../../app/GlobalSlice";

const initInnerModel = {
  questions: [],
  maxQuestionNumber: null,
  activeQuestionNumber: null,
  selectedQuestionDetail: null,
  isProcessing: false,
  errors: [],
  activeTab: 'question_list', //question_detail or question_list,
}

export const setQuestion = (model, questions) => {
  let maxNum = 0
  if (questions.length > 0) {
    const maxNumQuestion = questions.reduce((prev, curr) => prev.noSoal > curr.noSoal ? prev : curr)
    maxNum = maxNumQuestion.noSoal
  }

  return ({
    ...model,
    questions: questions,
    maxQuestionNumber: maxNum,
    activeQuestionNumber: maxNum + 1
  })
}

export const setMaxQuestionNumber = (model, num) => ({ ...model, maxQuestionNumber: num })
export const setSelectedQuestionDetail = (model, questionDetail) => ({ ...model, selectedQuestionDetail: questionDetail, activeTab: 'question_detail' })
export const setActiveQuestionNumber = (model, questionNum) => ({ ...model, activeQuestionNumber: questionNum })
export const setStartProcessing = (model) => ({ ...model, isProcessing: true })
export const setProcessingFinish = (model) => ({ ...model, isProcessing: false })
export const setEmptyError = (model) => ({ ...model, errors: [] })
export const setErrors = (model, errors) => ({ ...model, errors: errors })
export const getQuestion = (model, questionNum) => {
  return model.questions.find(q => q.noSoal === questionNum)
}

function DetailPaketSoalBox({
  paketSoalModel,
  setPaketSoalModel,
  isMultipleTest
}) {

  const history = useHistory()

  return (
    <div
      className="p-2 rounded-lg bg-white relative space-y-3"
    >
      <div
        className="font-bold flex justify-center relative"
      >
        <button
          className="absolute left-0 flex items-center"
          onClick={() => {
            if (isMultipleTest) {
              history.push("/home/paket-soal/exam-phase/"+paketSoalModel.idPaketSoal)
            }
            else {
              history.push("/home/paket-soal")
            }
          }}
        >
          <RiArrowLeftLine className="h-6 w-6" />
        </button>
        <label>Detail Paket Soal</label>
      </div>
      <div
        className="space-y-2 px-2"
        style={{ fontSize: "14px", marginBottom: "30px" }}
      >
        <div className="flex">
          <div className="text-left" style={{ width: "60px" }}>Kode</div>
          <div className="pr-1">:</div>
          <div className="text-left" style={{ width: "150px" }}>
            {paketSoalModel.idPaketSoal}
          </div>
        </div>
        <div className="flex">
          <div className="text-left" style={{ width: "60px" }}>Nama </div>
          <div className="pr-1">:</div>
          <div className="text-left" style={{ width: "150px" }}>
            {paketSoalModel.namaPaketSoal}
          </div>
        </div>
        { !isMultipleTest && (
          <div className="flex">
            <div className="text-left" style={{ width: "60px" }}>Durasi</div>
            <div className="pr-1">:</div>
            <div className="text-left" style={{ width: "150px" }}>
              {paketSoalModel.durasiUjian} menit
            </div>
          </div>
        )}
        <div className="flex">
          <div className="text-left" style={{ width: "60px" }}>Tgl Input</div>
          <div className="pr-1">:</div>
          <div className="text-left" style={{ width: "150px" }}>
            {format(new Date(paketSoalModel.createdAt), "dd/MM/yyyy")}
          </div>
        </div>
      </div>
    </div>
  )
}

function DetailTahapanUjian({
  tahapUjianDetail
}) {

  return (
    <div
      className="p-2 rounded-lg bg-white relative space-y-3"
    >
      <div
        className="font-bold flex justify-center relative"
      >
        <label>Detail Tahap Ujian</label>
      </div>
      <div
        className="space-y-2 px-2"
        style={{ fontSize: "14px", marginBottom: "10px" }}
      >
        <div className="flex">
          <div className="text-left" style={{ width: "60px" }}>Tahap </div>
          <div className="pr-1">:</div>
          <div className="text-left" style={{ width: "150px" }}>
            {tahapUjianDetail.namaTahapUjian}
          </div>
        </div>
        <div className="flex">
          <div className="text-left" style={{ width: "60px" }}>Durasi</div>
          <div className="pr-1">:</div>
          <div className="text-left" style={{ width: "150px" }}>
            {tahapUjianDetail.durasiTahapUjian} menit
          </div>
        </div>
      </div>
    </div> 
  )

}

function QuestionNumberBox({
  innerModel,
  setInnerModel
}) {
  return (
    <div
      className="p-2 rounded-lg bg-white grid grid-cols-4 gap-3"
    >
      {Array.from({ length: innerModel.maxQuestionNumber + 1 }, (x, i) => (
        <button
          className={
            "rounded-full border   " +
            (innerModel.activeQuestionNumber === (i + 1) ? " border-blue-500 bg-blue-500 text-white " : " border-gray-500 hover:bg-blue-200 ")
          }
          style={{
            width: "40px",
            height: "40px"
          }}
          onClick={() => {
            if (innerModel.activeQuestionNumber !== (i + 1)) setInnerModel(setActiveQuestionNumber(innerModel, i + 1))
          }}
        >
          {i + 1}
        </button>
      ))}
    </div>
  )
}

export default function ExamQuestionForm() {
  const history = useHistory()
  const dispatch = useDispatch()

  const { id, idTahapUjian } = useParams()

  const [innerModel, setInnerModel] = useState(initInnerModel)
  const [paketSoalModel, setPaketSoalModel] = useState(null)
  const [tahapUjianDetail, setTahapUjianDetail] = useState(null)
  const errorRef = useRef(null)

  useEffect(() => {
    dispatch(unShowSidebar())

    getDataDetail(id)

    return () => {
      dispatch(showSidebar())
    }
  }, [])

  useEffect(() => {
    if (!paketSoalModel) return;

    fetchAddedSoal()

  }, [paketSoalModel])

  useEffect(() => {
    const question = getQuestion(innerModel, innerModel.activeQuestionNumber)

    if (!question) return setInnerModel(setEmptyError(setSelectedQuestionDetail(innerModel, null)));

    setInnerModel(setSelectedQuestionDetail(innerModel, question))

  }, [innerModel.activeQuestionNumber])

  function getDataDetail(idData) {
    
    if (idTahapUjian) {
      Promise
        .all([getDetail(idData), getTahapUjianDetail(idTahapUjian)])
        .then(responses => {
          const paketSoalRes = responses[0]
          const tahapUjianRes = responses[1]

          handleResponse200({
            httpResponse: paketSoalRes,
            onSuccess: payload => {
              setPaketSoalModel(payload)
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })

          handleResponse200({
            httpResponse: tahapUjianRes,
            onSuccess: payload => {
              setTahapUjianDetail(payload)
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })

        })
        .catch(err => {})

    }
    else {
      getDetail(idData)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
              setPaketSoalModel(payload)
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        // .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))
    }

  }

  function fetchAddedSoal() {
    getAddedSoal(paketSoalModel.idPaketSoal, idTahapUjian)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: questions => {
            setInnerModel(setQuestion(innerModel, questions))
          },
          onRecovFailure: () => {
          },
          onUnAuth: () => {
            console.log("Token not found")
          },
          onTechnicalError: () => { }
        })
      })
      .catch(error => { })
      .finally(() => { })
  }

  function onSuccessAddedQuestion() {
    fetchAddedSoal()
  }

  return (
    <div
      className="flex space-x-3 h-full w-full"
    >
      <div
        className="relative"
        style={{ width: "250px" }}
      >
        <div className="space-y-3  sticky top-16">
          { paketSoalModel && (
            <DetailPaketSoalBox
              paketSoalModel={paketSoalModel}
              setPaketSoalModel={setPaketSoalModel}
              isMultipleTest={idTahapUjian !== undefined && idTahapUjian !== null}
            />
          )}
          { tahapUjianDetail &&  (
            <DetailTahapanUjian
              tahapUjianDetail={tahapUjianDetail} 
            />
          )}
          <QuestionNumberBox
            innerModel={innerModel}
            setInnerModel={setInnerModel}
          />
        </div>
      </div>
      <div
        className="flex-1 relative"
      >
        <div
          className="pt-2 px-2 bg-gray-500 flex"
        >
          <button
            className={
              innerModel.activeTab === 'question_detail' ?
                "py-2 px-5 bg-white"
                :
                "py-2 px-5 bg-gray-400 hover:bg-gray-200"
            }
            onClick={() => setInnerModel({ ...innerModel, activeTab: 'question_detail' })}
          >
            Detail Soal
          </button>
          <button
            className={
              innerModel.activeTab === 'question_list' ?
                "py-2 px-5 bg-white"
                :
                "py-2 px-5 bg-gray-400 hover:bg-gray-200"
            }
            onClick={() => setInnerModel({ ...innerModel, activeTab: 'question_list' })}
          >
            Daftar Soal
          </button>
        </div>
        <div>
          {innerModel.activeTab === 'question_detail' && innerModel.selectedQuestionDetail && paketSoalModel ?
            <QuestionDetailBox
              kodePaketSoal={paketSoalModel.idPaketSoal}
              kodeSoal={innerModel.selectedQuestionDetail.kodeSoal}
              noSoal={innerModel.selectedQuestionDetail.noSoal}
              onBack={() => null}
              onSuccessAddedQuestion={() => notifySuccess("berhasil tambah soal 2")}
              maxQuestionNumber={innerModel.maxQuestionNumber}
              onSuccessDeleteQuestion={() => {
                // setInnerModel(
                //   setSelectedQuestionDetail(
                //     innerModel.selectedQuestionDetail.noSoal > 1 ?
                //       setActiveQuestionNumber(
                //         innerModel, innerModel.selectedQuestionDetail.noSoal - 1
                //       )
                //     :
                //       innerModel
                //     , 
                //     null
                //   )
                // )
                
                if (innerModel.selectedQuestionDetail.noSoal > 1) {
                  setInnerModel(setActiveQuestionNumber(innerModel, innerModel.selectedQuestionDetail.noSoal - 1))
                  fetchAddedSoal()
                }
                else {
                  setInnerModel(setMaxQuestionNumber(setSelectedQuestionDetail(innerModel, null), 0))
                }
              }}
            />
            :
            innerModel.activeTab === 'question_list' && paketSoalModel ?
              <QuestionListBox
                kodePaketSoal={paketSoalModel.idPaketSoal}
                idTahapUjian={idTahapUjian}
                noSoal={innerModel.activeQuestionNumber}
                onSuccessAddedQuestion={onSuccessAddedQuestion}
              />
              :
              innerModel.activeTab === 'question_detail' ?
                (
                  <div
                    className="bg-white flex items-center justify-center"
                    style={{ height: "300px" }}
                  >
                    <label>Belum dipilih soal untuk no {innerModel.activeQuestionNumber}</label>
                  </div>
                )
                :
                null
          }
        </div>
      </div>
    </div>
  )


}

