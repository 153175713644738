import { RiArrowLeftLine, RiLoader5Fill } from "react-icons/ri"
import { isDesktop } from "../../../utils/PlatformHelper"
import { initModel, setIsFetchingQuestionDetail, setQuestionEvals, setSelectedQuestionCode, setSelectedQuestionDetail } from "../Models/UjianDetailModel"
import { format } from "date-fns"
import { useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import { getAnsweredQuestionEvaluation, getExamQuestionEvaluationDetail, getUjianDetail } from "../Apis/Api"
import { useParams } from "react-router-dom"
import { useHistory } from "react-router";

export function Latex(props) {
  let node = React.createRef();
  useEffect(() => {
    renderMath();
  });
  const renderMath = () => {
    window.MathJax.Hub.Queue([
      "Typeset",
      window.MathJax.Hub,
      node.current
    ]);
  }
  return (
    <div ref={node}>
      {props.children}
    </div>
  );
}

const ExamNumberBox = ({
  innerModel,
  setInnerModel
}) => {

  return (
    <div
      className="p-3 rounded-lg bg-white grid grid-cols-5 gap-2"
    >
      { innerModel.questionEvals.map(q => ({ ...q, noSoal: parseInt(q.noSoal)})).sort((a, b) => { return a.noSoal - b.noSoal }).map(q => (
        <button
          className={
            "rounded-full border  " +
            (q.kodeSoal === innerModel.selectedQuestionCode ?
              " bg-blue-500 text-white border-blue-500 "
              :
              q.isAnswered ?
                q.isCorrect ?
                  " bg-green-500 text-white border-green-500 "
                  :
                  " bg-red-500 text-white border-red-500 "
                :
                " bg-gray-200 hover:bg-blue-200 "
            )
          }
          style={{
            height: "45px",
            width: "45px"
          }}
          onClick={() => {
            setInnerModel(prevModel =>
              setSelectedQuestionCode(
                prevModel,
                q.kodeSoal,
                q.noSoal
              )
            )
          }}
        >
          {q.noSoal}
        </button>
      ))}
    </div>
  )
}

const ExamDetailBox = ({
  ujianPeserta,
  innerModel,
  setInnerModel,
}) => {
  const history = useHistory()
  const { screenSize } = useSelector(state => state.global)

  return (
    <div
      className="p-3 rounded-lg bg-white relative space-y-3"
    >
      <div
        className="font-bold flex justify-center relative"
      >
        {isDesktop(screenSize.width) && (
          <button
            className="absolute left-0 flex items-center"
            onClick={() => {
              history.push('/home/ujian')
            }}
          >
            <RiArrowLeftLine className="h-6 w-6" />
          </button>
        )}
        <label>Detail Ujian</label>
      </div>
      <div
        className="space-y-2 px-2"
        style={{ fontSize: "14px", marginBottom: "30px" }}
      >
        <div className="flex">
          <div className="text-left" style={{ width: "90px" }}>No. Ujian</div>
          <div className="pr-1">:</div>
          <div className="text-left" style={{ width: "150px" }}>{ujianPeserta.idUjianPeserta}</div>
        </div>
        <div className="flex">
          <div className="text-left" style={{ width: "90px" }}>Durasi</div>
          <div className="pr-1">:</div>
          <div className="text-left" style={{ width: "150px" }}>{ujianPeserta.durasiUjian} menit</div>
        </div>
        <div className="flex">
          <div className="text-left" style={{ width: "90px" }}>Mulai </div>
          <div className="pr-1">:</div>
          <div className="text-left" style={{ width: "150px" }}>
            {format(new Date(ujianPeserta.startedAt), "dd/MM/yyyy, HH:mm")}
          </div>
        </div>
        <div className="flex">
          <div className="text-left" style={{ width: "90px" }}>Deadline </div>
          <div className="pr-1">:</div>
          <div className="text-left" style={{ width: "150px" }}>
            {format(new Date(ujianPeserta.deadline), "dd/MM/yyyy, HH:mm")}
          </div>
        </div>
        <div className="flex">
          <div className="text-left" style={{ width: "90px" }}>Selesai </div>
          <div className="pr-1">:</div>
          <div className="text-left" style={{ width: "150px" }}>
            {ujianPeserta.finishedAt ? format(new Date(ujianPeserta.finishedAt), "dd/MM/yyyy, HH:mm") : format(new Date(ujianPeserta.deadline), "dd/MM/yyyy, HH:mm")}
          </div>
        </div>
        <div className="flex pt-2 border-t">
          <div className="text-left" style={{ width: "65px" }}>Id Peserta </div>
          <div className="pr-1">:</div>
          <div className="text-left" style={{ width: "60px" }}>
            {ujianPeserta.participant.id}
          </div>
        </div>
        <div className="flex">
          <div className="text-left" style={{ width: "65px" }}>Nama </div>
          <div className="pr-1">:</div>
          <div className="text-left" style={{ width: "160px" }}>
            {ujianPeserta.participant.label}
          </div>
        </div>
        <div className="flex pt-2 border-t">
          <div className="text-left" style={{ width: "65px" }}>Id Paket </div>
          <div className="pr-1">:</div>
          <div className="text-left" style={{ width: "60px" }}>
            {ujianPeserta.paketSoal.id}
          </div>
        </div>
        <div className="flex">
          <div className="text-left" style={{ width: "65px" }}>Nama </div>
          <div className="pr-1">:</div>
          <div className="text-left" style={{ width: "160px" }}>
            {ujianPeserta.paketSoal.label}
          </div>
        </div>
        <div className="flex pt-2 border-t">
          <div className="text-left" style={{ width: "95px" }}>Total Soal </div>
          <div className="pr-1">:</div>
          <div className="text-right" style={{ width: "60px" }}>
            {innerModel.questionEvals.length} Soal
          </div>
        </div>
        <div className="flex">
          <div className="text-left" style={{ width: "95px" }}>Dikerjakan </div>
          <div className="pr-1">:</div>
          <div className="text-right" style={{ width: "60px" }}>
            {innerModel.questionEvals.filter(q => q.isAnswered).length} Soal
          </div>
        </div>
        <div className="flex">
          <div className="text-left" style={{ width: "95px" }}>Tidak Dijawab </div>
          <div className="pr-1">:</div>
          <div className="text-right" style={{ width: "60px" }}>
            {innerModel.questionEvals.length - innerModel.questionEvals.filter(q => q.isAnswered).length} Soal
          </div>
        </div>
        <div className="flex">
          <div className="text-left" style={{ width: "95px" }}>Jawaban Benar </div>
          <div className="pr-1">:</div>
          <div className="text-right" style={{ width: "60px" }}>
            {innerModel.questionEvals.filter(q => q.isAnswered && q.isCorrect).length} Soal
          </div>
        </div>
        <div className="flex">
          <div className="text-left" style={{ width: "95px" }}>Jawaban Salah </div>
          <div className="pr-1">:</div>
          <div className="text-right" style={{ width: "60px" }}>
            {innerModel.questionEvals.filter(q => q.isAnswered && !q.isCorrect).length} Soal
          </div>
        </div>
      </div>
    </div>
  )
}

const ExamQuestionBox = ({
  questionDetail,
  kodeUjianPeserta,
  innerModel,
  setInnerModel
}) => {


  return (
    <div
      className="w-full px-5 py-2 rounded-lg bg-white "
    >
      { innerModel.isFetchingQuestionDetail ?
        (
          <div
            className="flex items-center justify-center text-green-600"
            style={{ minHeight: "200px" }}
          >
            <RiLoader5Fill className={"h-10 w-10 animate-spin"} />
          </div>
        )
        :
        (
          <>
            <div className="flex pt-3">
              <div className="text-left" style={{ width: "60px" }}>Soal No</div>
              <div className="px-2">:</div>
              <div>{innerModel.selectedQuestionNumber}</div>
            </div>
            <div className="flex pb-3 border-b border-gray-300">
              <div className="text-left" style={{ width: "60px" }}>Status </div>
              <div className="px-2">:</div>
              <div>
                {questionDetail && questionDetail.jawabanPeserta ?
                  questionDetail.jawabanPeserta.kodePilihanJawaban === questionDetail.kunciJawaban.kodeJawaban ?
                    "Jawaban Benar"
                    :
                    "Jawaban Salah"
                  :
                  questionDetail && !questionDetail.jawabanPeserta ?
                    "Tidak dijawab"
                    :
                    "-"
                }
              </div>
            </div>
            <div className="text-left py-3" dangerouslySetInnerHTML={{ __html: questionDetail ? questionDetail.soal.redaksiSoal : '' }} />
            <div className="py-5 flex flex-col space-y-2">
              {questionDetail && questionDetail.pilihanJawaban.map(c => (
                <div
                  className={
                    "w-full py-3 px-5 flex space-x-2 rounded-md border  " +
                    (c.kodeJawaban === questionDetail.kunciJawaban.kodeJawaban ?
                      " border-green-500 bg-green-500 text-white "
                      :
                      questionDetail.jawabanPeserta && c.kodeJawaban === questionDetail.jawabanPeserta.kodePilihanJawaban ?
                        " border-red-500 bg-red-500 text-white "
                        :
                        ""
                    )
                  }
                >
                  <div className="items-start">
                    {c.label} .
                  </div>
                  <div className="text-left" dangerouslySetInnerHTML={{ __html: c.jawaban }} />
                </div>
              ))}
            </div>
          </>
        )
      }
    </div>
  )
}

const UjianDetailPage = () => {
  const { idUjian } = useParams()

  const [innerModel, setInnerModel] = useState(initModel)
  const [ujianPeserta, setUjianPeserta] = useState(null)

  const { screenSize } = useSelector(state => state.global)

  useEffect(() => {
    onGetUjianPesertaDetail()

    getAnsweredQuestionEvaluation(idUjian)
      .then(res => {
        if (res.success) {
          setInnerModel( latestM =>
            setQuestionEvals(
              latestM,
              res.payload.questionsEvaluation
            )
          )
        }
        else {
          if (res.statusCode === 200) {

          }
          else if (res.statusCode === 401) {

          }
          else {

          }
        }

      })
      .catch(error => { })
      .finally(() => { })

  }, [])


  useEffect(() => {
    if (innerModel.selectedQuestionCode) {
      doFetchQuestionDetail(idUjian, innerModel.selectedQuestionCode)
    }
  }, [innerModel.selectedQuestionCode])

  function onGetUjianPesertaDetail() {
    getUjianDetail(idUjian)
      .then(res => {
        if (res.success) {
          setUjianPeserta(res.payload)
        }
        else {
          if (res.statusCode === 200) {

          }
          else if (res.statusCode === 401) {

          }
          else {

          }
        }
      })
      .catch(error => {})
      .finally(() => {})
  }

  function doFetchQuestionDetail(kodeUjianPeserta, kodeSoal) {
    setInnerModel(setIsFetchingQuestionDetail(innerModel, true))

    getExamQuestionEvaluationDetail(kodeUjianPeserta, kodeSoal)
      .then(res => {
        if (res.success) {

          setInnerModel(latestM =>
            latestM.selectedQuestionCode === kodeSoal ?
              setSelectedQuestionDetail(
                setIsFetchingQuestionDetail(latestM, false),
                { ...res.payload, tempSelectedChoiceCode: null }
              )
              :
              latestM
          )
        }
        else {
          if (res.statusCode === 200) {

          }
          else if (res.statusCode === 401) {

          }
          else {

          }
        }
      })
      .catch(error => { })
      .finally(() => { })
  }

  return (
    <div className="w-full flex space-x-3">
      <div
        className="relative"
      >
        <div className="space-y-3 sticky top-3">
          {ujianPeserta && (
            <ExamDetailBox
              ujianPeserta={ujianPeserta}
              innerModel={innerModel}
              setInnerModel={setInnerModel}
            />
          )}
          <ExamNumberBox
            innerModel={innerModel}
            setInnerModel={setInnerModel}
          />
        </div>
      </div>
      <div className="w-full">
        <Latex>
          <ExamQuestionBox
            questionDetail={innerModel.selectedQuestionDetail}
            kodeUjianPeserta={idUjian}
            innerModel={innerModel}
            setInnerModel={setInnerModel}
          />
        </Latex>
      </div>

    </div>
  )

}

export default UjianDetailPage