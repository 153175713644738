import { useEffect, useState } from "react"
import { RiArrowLeftLine, RiLoader4Fill, RiLoader4Line } from "react-icons/ri"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useParams } from "react-router-dom"
import { handleResponse200 } from "../../../utils/HttpUtils"
import { notifyError } from "../../../utils/Notification"
import { createData, getDetail, getKabupaten, getKecamatan, getProvinsi, updateData } from "../Apis/Api"
import { format } from "date-fns"
import idLocale from 'date-fns/locale/id'
import Select from "react-select"

export default function FormPage() {
  const history = useHistory()

  const { screenSize } = useSelector(state => state.global)

  const [model, setModel] = useState({
    form: {
      idOrangtua: '',
      namaOrangtua: '',
      email: '',
      hp: '',
      password: '',
      createdAt: null,
      anaks: [],
      transactions: [],
      fullAddress: ''
    },
    isProcess: false
  })

  const { mode, id } = useParams()

  const [provinsiOpts, setProvinsiOpts] = useState({
    selectedOpt: null,
    data: [],
    isLoad: false
  })

  const [kabupatenOpts, setKabupatenOpts] = useState({
    selectedOpt: null,
    data: [],
    isLoad: false
  })

  const [kecamatanOpts, setKecamatanOpts] = useState({
    selectedOpt: null,
    data: [],
    isLoad: false
  })

  useEffect(() => {
    if (!mode) return;

    setModel({...model, isProcess: true})
    getDataDetail(id)

  }, [])

  function getDataDetail(idData) {
    getDetail(idData)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setModel(latestM => ({ 
              ...latestM, 
              form: { 
                idOrangtua: payload.idOrangtua,
                namaOrangtua: payload.nama,
                email: payload.email,
                hp: payload.hp,
                createdAt: payload.createdAt,
                anaks: payload.anak,
                transactions: payload.transaction,
                fullAddress: payload.fullAddress
              }
            }))

            if (payload.provinsi) {
              setProvinsiOpts({ ...provinsiOpts, selectedOpt: { label: payload.provinsi.label, value: payload.provinsi.id } })
            }

            if (payload.kabupaten) {
              setKabupatenOpts({ ...kabupatenOpts, selectedOpt: { label: payload.kabupaten.label, value: payload.kabupaten.id } })
            }

            if (payload.kecamatan) {
              setKecamatanOpts({ ...kecamatanOpts, selectedOpt: { label: payload.kecamatan.label, value: payload.kecamatan.id } })
            }
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            history.push("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))

  }

  function saveData() {
    setModel({ ...model, isProcess: true })

    const payload = {
      idOrangtua: model.form.idOrangtua,
      namaOrangtua: model.form.namaOrangtua,
      email: model.form.email,
      hp: model.form.hp,
      password: model.form.password,
      idProvinsi: provinsiOpts.selectedOpt.value,
      idKabupaten: kabupatenOpts.selectedOpt.value,
      idKecamatan: kecamatanOpts.selectedOpt.value,
      fullAddress: ''
    }

    if (mode) {
      updateData(payload)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
              history.push("/home/orangtua")
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))

    }
    else {
      createData(payload)
        .then(res => {
          handleResponse200({
            httpResponse: res,
            onSuccess: payload => {
              history.push("/home/orangtua")
            },
            onRecovFailure: errors => errors.forEach(err => {
              notifyError(err)
            }),
            onUnAuth: error => {
              notifyError('Forbidden. Please Login')
              history.push("/login")
            },
            onTechnicalError: errors => errors.forEach(err => {
              notifyError(err)
            })
          })
        })
        .finally(() => setModel(latestM => ({ ...latestM, isProcess: false })))
    }
  }

  function onSelectProvinsiOpts() {
      setProvinsiOpts({ ...provinsiOpts, isLoad: true })

      getProvinsi()
          .then(response => {
              if (response.success) {
              const data = response.payload.map(d => ({ label: d.label, value: d.id }))
              setProvinsiOpts({ ...provinsiOpts, data: data, isLoad: false })
              }
              else {
              setProvinsiOpts({ ...provinsiOpts, isLoad: false })
              }
          })
          .catch(error => {
              setProvinsiOpts({ ...provinsiOpts, isLoad: false })
              if (error.response != undefined && error.response != null) {
              if (error.response.status == 401) {
                  notifyError("Forbidden")
              }
              else {
                  notifyError("Server Error")
              }
              }
          })
  }

  function onSelectKabupatenOpts() {
      if (!provinsiOpts.selectedOpt) return;
      setKabupatenOpts({ ...kabupatenOpts, isLoad: true })

      getKabupaten(provinsiOpts.selectedOpt.value)
          .then(response => {
              if (response.success) {
              const data = response.payload.map(d => ({ label: d.label, value: d.id }))
              setKabupatenOpts({ ...kabupatenOpts, data: data, isLoad: false })
              }
              else {
              setKabupatenOpts({ ...kabupatenOpts, isLoad: false })
              }
          })
          .catch(error => {
              setKabupatenOpts({ ...kabupatenOpts, isLoad: false })
              if (error.response != undefined && error.response != null) {
              if (error.response.status == 401) {
                  notifyError("Forbidden")
              }
              else {
                  notifyError("Server Error")
              }
              }
          })
  }

  function onSelectKecamatanOpts() {
      if (!kabupatenOpts.selectedOpt) return;
      setKecamatanOpts({ ...kecamatanOpts, isLoad: true })

      getKecamatan(kabupatenOpts.selectedOpt.value)
          .then(response => {
              if (response.success) {
              const data = response.payload.map(d => ({ label: d.label, value: d.id }))
              setKecamatanOpts({ ...kecamatanOpts, data: data, isLoad: false })
              }
              else {
              setKecamatanOpts({ ...kecamatanOpts, isLoad: false })
              }
          })
          .catch(error => {
              setKecamatanOpts({ ...kecamatanOpts, isLoad: false })
              if (error.response != undefined && error.response != null) {
              if (error.response.status == 401) {
                  notifyError("Forbidden")
              }
              else {
                  notifyError("Server Error")
              }
              }
          })
  }

  const AnakBox = ({
    anak
  }) => {
    return (
      <div
        className="p-3 border rounded-lg "
        style={{
          fontSize: '14px'
        }}
      >
        <div
          className="flex space-x-2"
        >
          <div className="w-16">Nama</div>
          <div>:</div>
          <div>{anak.nama}</div>
        </div>
        <div
          className="flex space-x-2"
        >
          <div className="w-16">Kelas</div>
          <div>:</div>
          <div>{anak.kelas.label}</div>
        </div>
        <div
          className="flex space-x-2"
        >
          <div className="w-16">Program</div>
          <div>:</div>
          <div>{anak.program.label}</div>
        </div>
        <div
          className="flex space-x-2"
        >
          <div className="w-16">Sekolah</div>
          <div>:</div>
          <div>{anak.sekolah ? anak.sekolah.label : anak.sekolahLainnya}</div>
        </div>
        <div
          className="flex space-x-2"
        >
          <div className="w-16">JK</div>
          <div>:</div>
          <div>{anak.jenisKelamin}</div>
        </div>
      </div>
    )
  }

  const Transaction = ({
    transaction
  }) => {
    return (
      <div
        className="p-3 border rounded-lg space-y-1"
        style={{
          fontSize: '14px'
        }}
      >
        <div
          className="flex space-x-2"
        >
          <div className="w-24">ID Transaksi</div>
          <div>:</div>
          <div>{transaction.idTransaction}</div>
        </div>
        <div
          className="flex space-x-2"
        >
          <div className="w-24">Paket Bimbel</div>
          <div>:</div>
          <div>{transaction.paketBimbel.label}</div>
        </div>
        <div
          className="flex space-x-2"
        >
          <div className="w-24">Anak</div>
          <div>:</div>
          <div>{transaction.anak.label}</div>
        </div>
        <div
          className="flex space-x-2"
        >
          <div className="w-24">Tgl dibuat</div>
          <div>:</div>
          <div>{format(new Date(transaction.createdAt), "EEEE, dd MMMM yyyy HH:mm", { locale: idLocale })}</div>
        </div>
        <div
          className="flex space-x-2"
        >
          <div className="w-24">Deadline</div>
          <div>:</div>
          <div>{format(new Date(transaction.expiredAt), "EEEE, dd MMMM yyyy HH:mm", { locale: idLocale })}</div>
        </div>
        <div
          className="flex space-x-2"
        >
          <div className="w-24">Channel</div>
          <div>:</div>
          <div>{transaction.paymentChannel}</div>
        </div>
        <div
          className="flex space-x-2"
        >
          <div className="w-24">Kode bayar</div>
          <div>:</div>
          <div>{transaction.paymentCode}</div>
        </div>
        <div
          className="flex space-x-2"
        >
          <div className="w-24">Total Bayar</div>
          <div>:</div>
          <div>{transaction.finalPrice}</div>
        </div>
        <div
          className="flex space-x-2"
        >
          <div className="w-24">Status</div>
          <div>:</div>
          <div className="rounded-md bg-blue-500 p-1 text-white">{transaction.paymentStatus}</div>
        </div>
      </div>
    )
  }


  return (
    <div
      className="bg-white w-full shadow-xl rounded-md divide-y divide-gray-300 relative"
    >
      { model.isProcess && (
        <>
          <div
            className="absolute bg-black z-10 w-full h-full top-0 left-0 opacity-70"
          >
          </div>
          <div
            className="absolute z-20 top-0 left-0 w-full h-full flex items-center justify-center text-blue-500 "
          >
            <RiLoader4Fill className="h-12 w-12 animate-spin"/>
          </div>
        </>
      )}
      
      <div
        className="flex items-center justify-between p-2 pt-3"
      >
        <div>
          <button
            onClick={() => history.push("/home/orangtua")}
          >
            <RiArrowLeftLine className="h-6 w-6"/>
          </button>
        </div>
        <div
          className="font-bold uppercase"
          style={{
            fontSize: "15px"
          }}
        >
            {/* {
              mode ? 
                mode == 'edit' ? "update data" : "lihat data"
              :
                "Tambah data"
            } */}
        </div>
      </div>
      <div
        className="pt-4 pb-6 px-4 text-left space-y-3 "
      >
        { mode && (
          <div
            className="space-y-2"
          >
            <div
              className="font-bold"
            >
              ID Orangtua
            </div>
            <div>
              <input
                className="w-full p-2 rounded-sm border border-gray-400"
                value={model.form.idOrangtua}
                readOnly
              />
            </div>
          </div>  
        )}
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
          >
            Nama Orangtua
          </div>
          <div>
            <input
              className="w-full p-2 rounded-sm border border-gray-400"
              value={model.form.namaOrangtua}
              onChange={e => setModel({ ...model, form: { ...model.form, namaOrangtua: e.target.value } })}
              readOnly={mode === 'view'}
            />
          </div>
        </div>
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
          >
            Email
          </div>
          <div>
            <input
              className="w-full p-2 rounded-sm border border-gray-400"
              value={model.form.email}
              onChange={e => setModel({ ...model, form: { ...model.form, email: e.target.value } })}
              readOnly={mode !== undefined}
            />
          </div>
        </div>
        <div
          className="space-y-2"
        >
          <div
            className="font-bold"
          >
            HP
          </div>
          <div>
            <input
              type={'number'}
              className="w-full p-2 rounded-sm border border-gray-400"
              value={model.form.hp}
              onChange={e => setModel({ ...model, form: { ...model.form, hp: e.target.value } })}
              readOnly={mode === 'view'}
            />
          </div>
        </div>
        {mode === 'view' && (
          <>
            <div
              className="space-y-2"
            >
              <div
                className="font-bold"
              >
                Terdaftar pada tanggal
              </div>
              <div>
                <input
                  className="w-full p-2 rounded-sm border border-gray-400"
                  value={model.form.createdAt ? format(new Date(model.form.createdAt), "EEEE, dd MMMM yyyy HH:mm", { locale: idLocale }) : '-'}
                  readOnly
                />
              </div>
            </div>
            <div
              className="space-y-2"
            >
              <div
                className="font-bold"
              >
                Data Anak
              </div>
              <div className="space-y-2">
                { model.form.anaks.map(a => (
                  <AnakBox
                    key={a.idAnak}
                    anak={a}
                  />
                ))}
              </div>
            </div>
            <div
              className="space-y-2"
            >
              <div
                className="font-bold"
              >
                Data Transaksi
              </div>
              <div className="grid grid-cols-2 gap-3">
                { model.form.transactions.map(t => (
                  <Transaction
                    key={t.idTransaction}
                    transaction={t}
                  />
                ))}
              </div>
            </div>
          </>
        )}
        { !mode && (
          <div
            className="space-y-2"
          >
            <div
              className="font-bold"
            >
              Password
            </div>
            <div>
              <input
                type={"password"}
                className="w-full p-2 rounded-sm border border-gray-400"
                value={model.form.password}
                onChange={e => setModel({ ...model, form: { ...model.form, password: e.target.value } })}
              />
            </div>
          </div>
        )}

        <div className='space-y-2'>
            <div
                className="font-bold" 
                style={{ 
                    fontSize: '13px',
                    fontFamily: 'Nunito'
                }} 
            >
                Provinsi
            </div>
            <div
                className={
                    'w-full py-2 px-2 rounded-md outline-none font-bold flex items-center space-x-2 '
                }
                style={{ fontSize: '15px', fontFamily: 'Nunito' }}
            >
                <Select
                    placeholder="Pilih provinsi"
                    value={provinsiOpts.selectedOpt}
                    options={provinsiOpts.data}
                    isLoading={provinsiOpts.isLoad}
                    menuPlacement="auto"
                    className="w-96 border border-gray-200 rounded-md"
                    onFocus={onSelectProvinsiOpts}
                    onChange={(value) => {
                        setProvinsiOpts({ ...provinsiOpts, selectedOpt: value })
                        setKabupatenOpts({ ...kabupatenOpts, selectedOpt: null })
                        setKecamatanOpts({ ...kecamatanOpts, selectedOpt: null })
                    }}
                />
            </div>
        </div>

        <div className='space-y-2'>
            <div
                className="font-bold" 
                style={{ 
                    fontSize: '13px',
                    fontFamily: 'Nunito'
                }} 
            >
                Kota/Kabupaten
            </div>
            <div
                className={
                    'w-full py-2 px-2 rounded-md outline-none font-bold flex items-center space-x-2 '
                }
                style={{ fontSize: '15px', fontFamily: 'Nunito' }}
            >
                <Select
                    placeholder="Pilih Kabupaten"
                    value={kabupatenOpts.selectedOpt}
                    options={kabupatenOpts.data}
                    isLoading={kabupatenOpts.isLoad}
                    menuPlacement="auto"
                    className="w-96 border border-gray-200 rounded-md"
                    onFocus={onSelectKabupatenOpts}
                    onChange={(value) => {
                        setKabupatenOpts({ ...kabupatenOpts, selectedOpt: value })
                        setKecamatanOpts({ ...kecamatanOpts, selectedOpt: null })
                    }}
                />   
            </div>
        </div>

        <div className='space-y-2'>
            <div
                className="font-bold" 
                style={{ 
                    fontSize: '13px',
                    fontFamily: 'Nunito'
                }} 
            >
                Kecamatan
            </div>
            <div
                className={
                    'w-full py-2 px-2  rounded-md outline-none font-bold flex items-center space-x-2 '
                }
                style={{ fontSize: '15px', fontFamily: 'Nunito' }}
            >
                <Select
                    placeholder="Pilih Kecamatan"
                    value={kecamatanOpts.selectedOpt}
                    options={kecamatanOpts.data}
                    isLoading={kecamatanOpts.isLoad}
                    menuPlacement="auto"
                    className="w-96 border border-gray-200 rounded-md"
                    onFocus={onSelectKecamatanOpts}
                    onChange={(value) => {
                        setKecamatanOpts({ ...kecamatanOpts, selectedOpt: value })
                    }}
                />
            </div>
        </div>
        { mode && (
          <div className='space-y-2'>
              <div
                  className="font-bold" 
                  style={{ 
                      fontSize: '13px',
                      fontFamily: 'Nunito'
                  }} 
              >
                  Alamat Lengkap
              </div>
              <div
                  className={
                      'w-full py-2 px-2  rounded-md outline-none font-bold flex items-center space-x-2 '
                  }
                  style={{ fontSize: '15px', fontFamily: 'Nunito' }}
              >
                  <textarea 
                    className="p-2 border border-gray-200 rounded-md w-full"
                    value={model.form.fullAddress}
                    rows={5}
                    readOnly
                  />
              </div>
          </div>
        )}

        
      </div>
      { (!mode || mode != 'view') && (
        <div
          className="pt-4 pb-2 px-2 flex items-center space-x-2"
        >
          <button
            className="px-5 py-2 bg-blue-600 text-white font-bold rounded-md"
            onClick={saveData}
          >
            Simpan
          </button>
        </div>
      )}

    </div>
  )
}