import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useHistory } from "react-router-dom";
import { setIsHomeProcess } from "../../../app/GlobalSlice";
import { handleResponse200 } from "../../../utils/HttpUtils";
import { notifyError } from "../../../utils/Notification";
import { closeDeleteDialog } from "../../Member/Models/Model";
import { deleteData, getData, getDataSummary } from "../Apis/Api";
import { 
  initModel, 
  setData, 
  setDataSummary, 
  setDateParam, 
  setFinishRefetchData, 
  setFinishRefetchDataSummary, 
  setIsLoadData, 
  setStartRefetchData,
  setStartRefetchDataSummary
} from "../Models/Model";

import DataSummary from "./DataSummary"
import DataTable from "./DataTable"
import DeleteDataDialog from "./DeleteDataDialog";

const SkemaCicilanPage = () => {
  const dispatch = useDispatch()
  const navigate = useHistory()

  const [rootModel, setRootModel] = useState(initModel)

  useEffect(() => {
    onGetDataSummary(rootModel, setRootModel)
  }, [])

  useEffect(() => {
    onGetData(rootModel, setRootModel)
  }, [rootModel.searchHeader.searchText, rootModel.dataProperties])

  useEffect(() => {
    if (rootModel.refetchData) {
      onGetData(rootModel, setRootModel)
    }
  }, [rootModel.refetchData])

  useEffect(() => {
    if (rootModel.refetchDataSummary) {
      onGetDataSummary(rootModel, setRootModel)
    }
  }, [rootModel.refetchDataSummary])

  function onDelete(idSkemaCicilan) {
    dispatch(setIsHomeProcess(true))

    deleteData(idSkemaCicilan)
      .then(res => {
        handleResponse200({
          httpResponse: res,
          onSuccess: payload => {
            setRootModel(
              setStartRefetchData(
                setStartRefetchDataSummary(
                  closeDeleteDialog(rootModel)
                )
              )
            )
          },
          onRecovFailure: errors => errors.forEach(err => {
            notifyError(err)
          }),
          onUnAuth: error => {
            notifyError('Forbidden. Please Login')
            // navigate("/login")
          },
          onTechnicalError: errors => errors.forEach(err => {
            notifyError(err)
          })
        })
      })
      .catch(err => {
        notifyError("Critical Error. Please Contact Developer")
      })
      .finally(() => dispatch(setIsHomeProcess(false)))

  }

  return (
    <div
      className="w-full space-y-3"
    >
      <DeleteDataDialog
        isShow={rootModel.deleteDialog.isOpen}
        selectedData={rootModel.deleteDialog.selectedData}
        onClose={() => setRootModel(closeDeleteDialog(rootModel))}
        onDelete={onDelete}
      />
      <DataSummary
        rootModel={rootModel}
        setRootModel={setRootModel}
        totalData={rootModel.dataSummary.totalData}
        totalDataAktif={rootModel.dataSummary.totalDataAktif}
        latestInputDateTime={rootModel.dataSummary.latestInputDateTime}
        onOpenAddForm={() => {
          navigate.push("/home/skema-cicilan/form")
        }}
      />
      <DataTable
        isLoadData={rootModel.isLoadData}
        rootModel={rootModel}
        setRootModel={setRootModel}
        datas={rootModel.dataList}
      />
    </div>
  )

}

export default SkemaCicilanPage;

function onGetData(rootModel, setRootModel) {
  const payload = {
    search: rootModel.searchHeader.searchText,
    offset: rootModel.dataProperties.offset,
    limit: rootModel.dataProperties.limit,
    orderBy: rootModel.dataProperties.ord
  }

  setRootModel(setIsLoadData(rootModel, true))

  getData(payload)
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM => setData(latestM, payload))
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          // navigate("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => {
      setRootModel(latestM => setIsLoadData(setFinishRefetchData(latestM), false))
    })

}

function onGetDataSummary(rootModel, setRootModel) {
  getDataSummary()
    .then(res => {
      handleResponse200({
        httpResponse: res,
        onSuccess: payload => {
          setRootModel(latestM => setDataSummary(latestM, payload.totalData, payload.totalDataAktif, payload.latestInput))
        },
        onRecovFailure: errors => errors.forEach(err => {
          notifyError(err)
        }),
        onUnAuth: error => {
          notifyError('Forbidden. Please Login')
          // navigate("/login")
        },
        onTechnicalError: errors => errors.forEach(err => {
          notifyError(err)
        })
      })
    })
    .catch(error => { })
    .finally(() => {
      setRootModel(latestM => setFinishRefetchDataSummary(latestM))
    })
}